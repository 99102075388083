import {useApiMutation} from './api';
import {ClayExport} from '../../shared/api/definitions';
import {useToast} from '@chakra-ui/react';

export const useClayExportMutation = () => {
  const toast = useToast();

  return useApiMutation('/clayExport', ClayExport, {
    onSuccess: () => {
      toast({
        title: 'Your Clay export is processing',
        description: "We'll email you when your Clay export has completed.",
        status: 'success',
        duration: 5_000,
        isClosable: true,
        position: 'bottom-left',
      });
    },
  });
};
