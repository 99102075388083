import React from 'react';
import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Tag,
  TagProps,
  Text,
} from '@chakra-ui/react';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import {useSimilarityState} from '../ModelBuilder/Similarity.state';
import {useScoringModelSignalStore} from '../ModelBuilder/SignalAssignmentStore';
import {entries} from '../../../shared/util';
import {Lookalikes} from './Lookalikes';
import {Signals} from './Signals';
import {useIsKeyplayAdmin} from '../../../hooks/api/user';
import {Overview} from './Overview';
import {useCustomer} from '../../../hooks/api/metadata';
import {hasToggle} from '../../../shared/customer';
import {ServiceableMarket} from './ServiceableMarket';
import {useSamEditorStore} from '../../SamEditor/useSamEditorStore';
import {ModelTest} from './ModelTest';

export function useMarketBuilderTabs() {
  const customer = useCustomer();
  const isAdmin = useIsKeyplayAdmin();

  const {hasChanges: hasLookalikeChanges, localState: lookalikesState} =
    useSimilarityState();
  const {clientBuckets, hasBucketChanges, hasWeightChanges} =
    useScoringModelSignalStore();
  const {hasChanges: hasSamChanges} = useSamEditorStore();

  const tabConfig = [
    {
      label: (
        <TabLabelWrapper>
          <Text>Overview</Text>
        </TabLabelWrapper>
      ),
      path: 'overview',
      element: <Overview />,
      minWidth: 0,
    },
    {
      label: (
        <TabLabelWrapper
          badgeProps={{right: '-8px'}}
          hasChanges={hasSamChanges()}
        >
          <Text>Serviceable Market</Text>
        </TabLabelWrapper>
      ),
      path: 'define',
      element: <ServiceableMarket />,
      minWidth: 0,
    },
    {
      label: (
        <TabLabelWrapper hasChanges={hasLookalikeChanges()}>
          <Text>Lookalikes</Text>
          <MarketHeaderTag>{lookalikesState.length}</MarketHeaderTag>
        </TabLabelWrapper>
      ),
      path: 'lookalikes',
      element: <Lookalikes />,
      minWidth: 140,
    },
    {
      label: (
        <TabLabelWrapper hasChanges={hasBucketChanges() || hasWeightChanges()}>
          <Text>Signals</Text>
          <MarketHeaderTag>{entries(clientBuckets).length}</MarketHeaderTag>
        </TabLabelWrapper>
      ),
      path: 'signals',
      element: <Signals />,
      minWidth: 110,
    },
    ...(isAdmin || hasToggle(customer, 'modelTester')
      ? [
          {
            label: (
              <TabLabelWrapper>
                <Text>Test</Text>
              </TabLabelWrapper>
            ),
            path: 'test',
            element: <ModelTest />,
            minWidth: 0,
          },
        ]
      : []),
  ] as const;

  return tabConfig;
}

export const MarketBuilderTabs = () => {
  const location = useLocation();
  const tabs = useMarketBuilderTabs();
  const tabIndex = tabs.findIndex((tab) =>
    location.pathname.endsWith(tab.path)
  );

  return (
    <Tabs index={tabIndex} variant="unstyled">
      <TabList color="kgray.300" gap="2">
        {tabs.map(({path, label, minWidth}) => (
          <Tab
            as={Link}
            to={path}
            key={path}
            px="2"
            whiteSpace="nowrap"
            minWidth={`${minWidth}px`}
            _focus={{boxShadow: 'unset'}}
            _hover={{color: 'kgray.400'}}
            _selected={{color: 'kgray.400'}}
          >
            {label}
          </Tab>
        ))}
      </TabList>
      <TabIndicator height="2px" bg="blue.500" borderRadius="1px" />
    </Tabs>
  );
};

const TabLabelWrapper = ({
  children,
  badgeProps,
  hasChanges,
  ...props
}: React.PropsWithChildren<
  FlexProps & {badgeProps?: BoxProps; hasChanges?: boolean}
>) => {
  return (
    <Flex alignItems="center" gap="2" position="relative" {...props}>
      {children}
      {hasChanges && <UnsavedBadge {...badgeProps} />}
    </Flex>
  );
};

const MarketHeaderTag = ({children, ...props}: TagProps) => {
  return (
    <Tag
      borderRadius="full"
      sx={{
        '[aria-selected="true"] &': {
          backgroundColor: 'kblue.400',
          color: 'white',
        },
      }}
      {...props}
    >
      {children}
    </Tag>
  );
};

const UnsavedBadge = (props: BoxProps) => {
  return (
    <Box
      bgColor="kred.300"
      borderRadius="full"
      border="1px solid"
      borderColor="kgray.50"
      boxSize="10px"
      position="absolute"
      right="-3px"
      top="-3px"
      {...props}
    />
  );
};
