import React from 'react';
import {useAuth0, User} from '@auth0/auth0-react';
import {Box, Button, Center, Flex, Link} from '@chakra-ui/react';
import {ApiAudience} from '../../shared/auth';
import {Customer} from '../../shared/customer';
import {WithId} from '../../shared/util';

export const CustomerListWithLogin = ({
  customers,
  onTokenUpdated,
}: {
  customers: WithId<Customer>[];
  onTokenUpdated: (token: string) => void;
}) => {
  const {getAccessTokenWithPopup} = useAuth0();

  const handleLogin = async (customer: WithId<Customer>) => {
    // Fetch a new token for the selected customer and redirect to the accounts page.
    const token = await getAccessTokenWithPopup({
      audience: ApiAudience,
      customerId: customer._id,
    });
    onTokenUpdated(token);
  };

  return (
    <Flex direction="column" gap={2}>
      {customers.map((customer) => {
        return (
          <Button
            alignContent="start"
            key={customer._id.toString()}
            onClick={() => handleLogin(customer)}
            variant="outline"
          >
            {customer.name}
          </Button>
        );
      })}
    </Flex>
  );
};

export const CustomerSelector = ({
  customers,
  logout,
  onTokenUpdated,
  user,
}: {
  customers: WithId<Customer>[];
  logout: Function;
  onTokenUpdated: (token: string) => void;
  user: User;
}) => {
  return (
    <Center bgColor="kgray.400" minH="100vh" py={10}>
      <Flex
        bgColor="kgray.100"
        px={20}
        py={10}
        borderRadius={25}
        direction="column"
        gap={4}
      >
        <Box as="img" h="50px" src="/Keyplay_Primary_Logo.svg" />
        <Box>Welcome {user.email}</Box>
        <CustomerListWithLogin
          customers={customers}
          onTokenUpdated={onTokenUpdated}
        />
        <Link
          alignSelf="end"
          color="kblue.400"
          onClick={() => logout()}
          variant="outline"
        >
          Logout
        </Link>
      </Flex>
    </Center>
  );
};
