import {User} from '@auth0/auth0-react';
import {getCustomClaims} from '../shared/auth';

export function isKeyplayAdmin(user?: User) {
  if (!user) {
    return false;
  }

  return getCustomClaims(user).isKeyplayAdmin;
}
