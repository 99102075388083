import {z} from 'zod';
import {zodTypeguard} from './api/helpers';

const DomainImportRowSchema = z.object({
  Domain: z.string(),
});
export const isDomainImportRow = zodTypeguard(DomainImportRowSchema);
export type DomainImportRow = z.infer<typeof DomainImportRowSchema>;

const CrmImportRowSchema = DomainImportRowSchema.extend({
  'CRM ID': z.string(),
});

export const isCrmImportRow = zodTypeguard(CrmImportRowSchema);
export type CrmImportRow = z.infer<typeof CrmImportRowSchema>;

const RecommendedAccountsImportBaseSchema = DomainImportRowSchema.extend({
  'LinkedIn URL': z.string(),
});

// At least Domain or 'LinkedIn URL' must be present (both are OK too).
const RecommendedAccountsImportRowSchema = z.union([
  RecommendedAccountsImportBaseSchema,
  RecommendedAccountsImportBaseSchema.extend({
    Domain: z.string().optional(),
  }),
  RecommendedAccountsImportBaseSchema.extend({
    'LinkedIn URL': z.string().optional(),
  }),
]);

export const isRecommendedAccountsImportRow = zodTypeguard(
  RecommendedAccountsImportRowSchema
);
export type RecommendedAccountsImportRow = z.infer<
  typeof RecommendedAccountsImportRowSchema
>;
