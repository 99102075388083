import {useQueryClient} from '@tanstack/react-query';
import {
  PostFieldDefinition,
  DeleteFieldDefinition,
} from '../../shared/api/definitions';
import {useApiMutation} from './api';
import {MetadataQueryKey} from '../../context/MetadataContext';

export const useCreateFieldDefinition = () => {
  const queryClient = useQueryClient();
  return useApiMutation('/customer/fieldDefinition', PostFieldDefinition, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([MetadataQueryKey]);
    },
  });
};

export const useDeleteCustomField = () => {
  const queryClient = useQueryClient();
  return useApiMutation(
    '/customer/deleteFieldDefinition',
    DeleteFieldDefinition,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([MetadataQueryKey]);
      },
    }
  );
};
