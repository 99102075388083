import {ObjectId} from 'bson';
import SuperJSON from 'superjson';

// Needs to be invoked on both the client and server to ensure correct
// serialization/deserialization of custom types
export const registerSuperJsonTypes = () => {
  SuperJSON.registerCustom<ObjectId, string>(
    {
      isApplicable: (v): v is ObjectId => v instanceof ObjectId,
      serialize: (v) => v.toString(),
      deserialize: (v) => new ObjectId(v),
    },
    'ObjectId'
  );
};
