import FileSaver from 'file-saver';
import {useMutation} from '@tanstack/react-query';
import {ExportRequest, ExportResponse} from '../../shared/api/api';
import {useToast} from '@chakra-ui/react';
import {useKeyplayApi} from '../../context/ApiContext';

type Options = {
  onSuccess?: (result: ExportResponse, request: ExportRequest) => void;
};

export const useExportMutation = (options?: Options) => {
  const makeApiCall = useKeyplayApi();
  const toast = useToast();

  return useMutation<ExportResponse, unknown, ExportRequest>({
    mutationFn: (exportRequest) => {
      return makeApiCall(
        '/export',
        {
          method: 'POST',
          data: exportRequest,
        },
        {toastOnError: true}
      );
    },
    onSuccess: (result, request) => {
      options?.onSuccess && options.onSuccess(result, request);

      if (request.mode === 'async') {
        toast({
          title: 'Your export is processing',
          description: 'Check your email for a link to download the CSV file.',
          status: 'success',
          duration: 5_000,
          isClosable: true,
          position: 'bottom-left',
        });
      } else {
        if (result.exportUrl) {
          FileSaver.saveAs(result.exportUrl);
        } else {
          toast({
            title: 'Error generating export CSV',
            status: 'error',
            duration: 5_000,
            isClosable: true,
            position: 'bottom-left',
          });
        }
      }
    },
  });
};
