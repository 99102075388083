import {create} from 'zustand';
import {FieldDefinition} from '../../shared/enrichment';
import {ScoringCategory} from '../../shared/signals';

type Step = 'selectField' | 'defineSignal' | 'done';

export type Operator = 'greaterThan' | 'lessThan' | 'between';

interface CreateSignalModalState {
  step: Step;
  setStep: (step: Step) => void;

  selectedField: FieldDefinition | null;
  setSelectedField: (field: FieldDefinition | null) => void;

  signalCategory: ScoringCategory | null;
  setSignalCategory: (category: ScoringCategory | null) => void;

  operator: Operator;
  setOperator: (operator: Operator) => void;

  lowerBound: number | null;
  setLowerBound: (lowerBound: number | null) => void;

  upperBound: number | null;
  setUpperBound: (upperBound: number | null) => void;

  getLabel: () => string | null;
  reset: () => void;
}

export const useCreateSignalModalStore = create<CreateSignalModalState>(
  (set, get) => ({
    step: 'selectField',
    setStep: (step) => set({step}),

    selectedField: null,
    setSelectedField: (field) => set({selectedField: field}),

    signalCategory: null,
    setSignalCategory: (category) => set({signalCategory: category}),

    operator: 'between',
    setOperator: (operator) => set({operator}),

    lowerBound: null,
    setLowerBound: (lowerBound) => set({lowerBound}),

    upperBound: null,
    setUpperBound: (upperBound) => set({upperBound}),

    getLabel: () => {
      const {selectedField, operator, lowerBound, upperBound} = get();
      if (!selectedField) {
        return null;
      }

      return operator === 'between' &&
        lowerBound !== null &&
        upperBound !== null
        ? `${selectedField?.label}: ${lowerBound}-${upperBound}`
        : operator === 'greaterThan' && lowerBound !== null
          ? `${selectedField?.label}: ${lowerBound}+`
          : operator === 'lessThan' && upperBound !== null
            ? `${selectedField.label}: ${upperBound} or less`
            : null;
    },
    reset: () =>
      set({
        step: 'selectField',
        selectedField: null,
        signalCategory: null,
        operator: 'between',
        lowerBound: null,
        upperBound: null,
      }),
  })
);
