import React, {useCallback} from 'react';
import {
  Box,
  ButtonProps,
  Code,
  useToast,
  Tooltip,
  Text,
  Flex,
  Button,
} from '@chakra-ui/react';
import {useDropzone} from 'react-dropzone';
import {validateFile} from '../../../lib/validateFile';
import {z} from 'zod';
import {zodTypeguard} from '../../../shared/api/helpers';
import {useValidateDomains} from '../../../hooks/api/validateDomains';
import {Parser} from '@json2csv/plainjs';
import FileSaver from 'file-saver';
import {MaxValidateDomainRows} from '../../../shared/api/definitions';

const ValidateDomainRowSchema = z
  .object({
    Domain: z.string(),
  })
  .catchall(z.unknown());
const isValidateDomainRow = zodTypeguard(ValidateDomainRowSchema);

export const ValidateDomainsButton = ({children, ...rest}: ButtonProps) => {
  const toast = useToast();
  const validateDomains = useValidateDomains();

  const showErrorToast = useCallback(() => {
    toast({
      title: 'Invalid file format',
      description: `Please ensure your CSV file has a "Domain" column and is less than ${MaxValidateDomainRows} rows.`,
      status: 'error',
    });
  }, [toast]);

  const {open} = useDropzone({
    accept: {'text/csv': ['.csv']},
    onDropAccepted: (files) => {
      const file = files[0];
      if (!file) {
        return;
      }

      validateFile({
        maxRows: MaxValidateDomainRows,
        onFileValidate: (errors, data) => {
          if (errors.size) {
            showErrorToast();
            return;
          }

          const rows = data.map(({Domain, ...rest}) => ({
            domain: Domain,
            ...rest,
          }));

          validateDomains.mutate(rows, {
            onSuccess: (data) => {
              const csv = new Parser().parse(
                data.map(({domain, finalDomain, status, ...rest}) => ({
                  'Input Domain': domain,
                  Status: status,
                  'Final Domain': finalDomain,
                  ...rest,
                }))
              );
              const blob = new Blob([csv], {type: 'text/csv'});
              FileSaver.saveAs(blob, `${file.name}-validated.csv`);
            },
          });
        },
        rowSchemaTypeguard: isValidateDomainRow,
        selectedFile: file,
      });
    },
    onDropRejected: showErrorToast,
    maxSize: 100_000, // in bytes
    multiple: false,
  });

  return (
    <>
      <Tooltip
        isDisabled={validateDomains.isLoading}
        hasArrow
        label={
          <Flex direction="column" gap="4" my="2">
            <Text>
              The CSV should include a Domain column, plus any other columns you
              want (these will be included in the output).
            </Text>
            <Code>
              <Box>Domain</Box>
              <Box>example1.com</Box>
              <Box>example2.com</Box>
            </Code>
          </Flex>
        }
        placement="top"
      >
        <Button
          isLoading={validateDomains.isLoading}
          colorScheme="kbuttonblue"
          fontSize="sm"
          fontWeight="normal"
          onClick={(event) => {
            event.currentTarget.blur(); // hide tooltip
            open();
          }}
          variant="outline"
          {...rest}
        >
          {children}
        </Button>
      </Tooltip>
    </>
  );
};
