import {StoreApi, createStore, useStore} from 'zustand';
import {ScoringModelWithLookalikeLogos} from '../../../shared/api/responses';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';
import _ from 'lodash';
import {getLimit} from '../../../shared/customer';
import {useMutation} from '@tanstack/react-query';
import {useMarketFromContext} from '../MarketProvider';
import {MetadataResponse} from '../../../shared/api/api';
import {useIsFreeOrListBuilderCustomer} from '../../../hooks/api/metadata';
import {usePublishMarket} from '../../../hooks/api/markets';
import {useEffect} from 'react';
import {AccountWithLogo} from '../../../shared/scoredAccounts';

interface SimilarityState {
  // initialization and server state
  hasInitialized: boolean;
  initialize: (serverResponse: ScoringModelWithLookalikeLogos) => void;
  serverState: AccountWithLogo[];

  // client state
  localState: AccountWithLogo[];
  addLookalike: (lookalike: AccountWithLogo) => void;
  addLookalikes: (lookalikes: AccountWithLogo[]) => void;
  removeLookalike: (lookalike: AccountWithLogo) => void;

  hasChanges: () => boolean;
  isWithinLimits: (customer: MetadataResponse['customer']) => boolean;
  getLimits: (customer: MetadataResponse['customer']) => {
    minLookalikes: number;
    maxLookalikes: number;
  };
  resetClientChanges: () => void;
}

const createSimilarityStore = (isFreeOrListBuilder: boolean) =>
  createStore<SimilarityState>()(
    devtools<SimilarityState>((set, get) => {
      return {
        hasInitialized: false,
        initialize: (scoringModel: ScoringModelWithLookalikeLogos) => {
          // if server has new data, blow away the store. this is probably better than
          // showing or letting people edit state that's stale
          if (
            !get().hasInitialized ||
            !_.isEqual(get().serverState, scoringModel.lookalikesWithLogos)
          ) {
            const serverLookalikes = scoringModel.lookalikesWithLogos ?? [];
            set(
              produce<SimilarityState>((state) => {
                state.serverState = serverLookalikes;
                state.localState = serverLookalikes;
                state.hasInitialized = true;
              })
            );
          }
        },
        localState: [],
        serverState: [],
        addLookalike: (lookalike) =>
          set(
            produce<SimilarityState>((state) => {
              state.localState.push(lookalike);
            })
          ),
        addLookalikes: (lookalikes) =>
          set(
            produce<SimilarityState>((state) => {
              lookalikes.forEach((lookalike) => {
                // Only add lookalikes that aren't already in the local state
                if (
                  !state.localState.some(({_id}) => _id.equals(lookalike._id))
                ) {
                  state.localState.push(lookalike);
                }
              });
            })
          ),
        removeLookalike: (lookalike) =>
          set(
            produce<SimilarityState>((state) => {
              state.localState = state.localState.filter(
                (account) => account._id !== lookalike._id
              );
            })
          ),
        hasChanges: () => {
          if (!get().hasInitialized) {
            return false;
          }

          return !_.isEqual(get().localState, get().serverState);
        },
        getLimits: (customer) => {
          return {
            minLookalikes: isFreeOrListBuilder ? 2 : 0,
            maxLookalikes: getLimit(customer, 'lookalikes'),
          };
        },
        isWithinLimits: (customer) => {
          const numLookalikes = get().localState.length;
          const {minLookalikes, maxLookalikes} = get().getLimits(customer);
          if (numLookalikes < minLookalikes || numLookalikes > maxLookalikes) {
            return false;
          }

          return !customer.onboarded;
        },
        resetClientChanges: () =>
          set(
            produce<SimilarityState>((state) => {
              state.localState = state.serverState;
            })
          ),
      };
    })
  );

const similarityStores: Record<string, StoreApi<SimilarityState>> = {};
export const useSimilarityState = () => {
  const {id: marketId, scoringModel} = useMarketFromContext();
  const isFreeOrListBuilder = useIsFreeOrListBuilderCustomer();

  let similarityStore = similarityStores[marketId.toString()];
  if (!similarityStore) {
    similarityStore = createSimilarityStore(isFreeOrListBuilder);
    similarityStores[marketId.toString()] = similarityStore;
  }
  const store = useStore(similarityStore);
  const {initialize} = store;

  const publishMarket = usePublishMarket();
  // Proxy mutation that automatically passes in the lookalike local state
  // to the saveLookalikes mutation.
  const publishChanges = useMutation({
    mutationFn: async () => {
      if (!store.hasInitialized) {
        throw new Error('Cannot publish changes before initializing');
      }

      return publishMarket.mutateAsync({
        marketId,
        scoringModel: {lookalikes: store.localState.map(({_id}) => _id)},
      });
    },
  });

  useEffect(() => {
    initialize(scoringModel);
  }, [initialize, scoringModel]);

  return {
    ...store,
    publishChanges,
  };
};
