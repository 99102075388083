import React from 'react';
import {Box, Button, Divider, Flex, Text} from '@chakra-ui/react';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {TwoColumnGrid} from './helpers';
import {AccountTypeahead} from '../../AccountTypeahead';
import {ScrollableFlex, WrapperFlex} from '../../utils/scrolling';
import {AccountList} from './AccountList';

export const AiFieldBuilderSelectAccountsStep = () => {
  const {nextStep, prevStep, selectedAccounts, addAccount, removeAccount} =
    useAiFieldBuilderStore();

  return (
    <TwoColumnGrid>
      <Flex borderRight="1px solid" borderColor="kgray.200" direction="column">
        <Box position="relative" h="100%">
          <WrapperFlex>
            <ScrollableFlex p="0">
              <Flex direction="column" flex="1" gap="2" p="6">
                <Text fontSize="xl">Choose accounts to preview</Text>
                <Text fontSize="sm" color="kgray.300">
                  Choose up to 10 accounts.
                </Text>
                <Flex direction="column" gap="2">
                  <AccountTypeahead
                    disabledTooltip="Max preview accounts reached"
                    isDisabled={selectedAccounts.length >= 10}
                    onSelect={(account) => addAccount(account)}
                    placeholder="Search for an account..."
                    selected={selectedAccounts.map((account) => account._id)}
                  />
                  <AccountList
                    onRemoveAccount={removeAccount}
                    selectedAccounts={selectedAccounts}
                  />
                </Flex>
              </Flex>
            </ScrollableFlex>
          </WrapperFlex>
        </Box>

        <Divider />

        <Flex justifyContent="space-between" w="100%" px={10} py={4}>
          <Button variant="ghost" onClick={prevStep}>
            Back
          </Button>

          <Button
            colorScheme="kbuttonblue"
            isDisabled={!selectedAccounts.length}
            onClick={nextStep}
          >
            Continue
          </Button>
        </Flex>
      </Flex>

      <Flex p="6">
        <Text fontSize="xl">Preview</Text>
      </Flex>
    </TwoColumnGrid>
  );
};
