import React, {useState} from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Image,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import {useUsers} from '../../hooks/api/users';
import {User, UserStateLabels} from '../../shared/api/api';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {getLimit} from '../../shared/customer';
import {useCustomer} from '../../hooks/api/metadata';
import {InviteUserModal} from './InviteUserModal';
import {UserDetailsDrawer} from './UserDetailsDrawer';

export const Users = () => {
  const customer = useCustomer();
  const users = useUsers();
  const inviteUsersModal = useDisclosure();

  const [user, setUser] = useState<User | null>(null);
  const userDetailsDrawer = useDisclosure();

  if (users.isLoading) {
    return <Spinner />;
  }

  if (users.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        Something unexpected happened.
      </Alert>
    );
  }

  const userLimitReached =
    users.data.users.length >= getLimit(customer, 'users');

  return (
    <Flex direction="column" gap="4">
      <Flex alignItems="baseline" justifyContent="space-between">
        <Box>
          <Text fontSize="xl" fontWeight="500">
            Users{' '}
            <Box as="span" color="kgray.300" fontSize="md">
              ({users.data.users.length} / {getLimit(customer, 'users')})
            </Box>
          </Text>
        </Box>
        <Tooltip
          hasArrow
          isDisabled={!userLimitReached}
          label="User limit reached. Upgrade your plan to invite more users."
          placement="start"
        >
          <Button
            colorScheme="kbuttonblue"
            fontWeight="normal"
            isDisabled={userLimitReached}
            onClick={inviteUsersModal.onOpen}
          >
            Invite User
          </Button>
        </Tooltip>
      </Flex>
      <InviteUserModal
        isOpen={inviteUsersModal.isOpen}
        onClose={inviteUsersModal.onClose}
      />
      <UserList
        onClickUser={(user) => {
          setUser(user);
          userDetailsDrawer.onOpen();
        }}
        users={users.data.users}
      />
      {user && (
        <UserDetailsDrawer
          isOpen={userDetailsDrawer.isOpen}
          onClose={userDetailsDrawer.onClose}
          user={user}
        />
      )}
    </Flex>
  );
};

const columnHelper = createColumnHelper<User>();

interface UserListProps {
  onClickUser: (user: User) => void;
  users: User[];
}

const UserList = ({onClickUser, users}: UserListProps) => {
  const columns = [
    columnHelper.accessor(
      (acc) => ({
        name: acc.name,
        picture: acc.picture,
      }),
      {
        header: 'User',
        cell: (info) => {
          const user = info.getValue();
          return (
            <Flex alignItems="center" gap="2">
              <Box h="25px" w="25px">
                <Image borderRadius="full" src={user.picture} />
              </Box>
              <Box fontWeight="500">{user.name}</Box>
            </Flex>
          );
        },
      }
    ),
    columnHelper.accessor('email', {
      header: 'Email',
      cell: (info) => {
        const email = info.getValue();
        return <Box color="kgray.300">{email}</Box>;
      },
    }),
    columnHelper.accessor('state', {
      header: 'Status',
      cell: (info) => {
        const state = info.getValue();
        return <Box color="kgray.300">{UserStateLabels[state]}</Box>;
      },
    }),
  ];

  const table = useReactTable({
    columns,
    data: users,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableContainer border="1px" borderColor="kgray.200" borderRadius="md">
      <Table variant="simple">
        <Thead bg="kgray.100">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} role="group">
              {row.getVisibleCells().map((cell) => (
                <Td
                  cursor="pointer"
                  key={cell.id}
                  onClick={() => onClickUser(row.original)}
                  _groupHover={{bg: 'kgray.100'}}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
