import React from 'react';
import {Button, Tooltip} from '@chakra-ui/react';
import {
  useCanSaveMarketBuilder,
  useHasMarketBuilderChanges,
  useSaveMarketBuilder,
} from './tabState';

export const SaveButton = () => {
  const hasChanges = useHasMarketBuilderChanges();
  const {disableSave, errorMessage} = useCanSaveMarketBuilder();
  const publishChanges = useSaveMarketBuilder({triggerRefresh: false});

  return (
    <Tooltip label={errorMessage} placement="bottom-start">
      <Button
        isLoading={publishChanges.isLoading}
        isDisabled={!hasChanges || disableSave}
        colorScheme="kbuttonblue"
        variant="light"
        flexShrink="0"
        onClick={() => publishChanges.mutate()}
      >
        Save
      </Button>
    </Tooltip>
  );
};
