export const Roles = [
  'marketingOrGrowth',
  'revOps',
  'salesLeader',
  'aeOrSdr',
  'founderAndOrCEO',
] as const;

export type Role = (typeof Roles)[number];

export const RoleLabels: Record<Role, string> = {
  marketingOrGrowth: 'Marketing or Growth',
  revOps: 'Revenue Operations',
  salesLeader: 'Sales Leader',
  aeOrSdr: 'AE or SDR',
  founderAndOrCEO: 'Founder and/or CEO',
};
