import {useQueryClient} from '@tanstack/react-query';
import {createApiQuery, useApiMutation} from '../../hooks/api/api';
import {CancelTask, GetTasks} from '../../shared/api/definitions';

const taskKeys = {
  all: ['tasks'],
  list: (search?: string) => [...taskKeys.all, {search}],
} as const;

export const useGetTasks = createApiQuery(
  '/debug/tasks',
  GetTasks,
  ({partialCustomerName}) => taskKeys.list(partialCustomerName)
);

export const useCancelTask = () => {
  const queryClient = useQueryClient();

  return useApiMutation('/debug/cancelTask', CancelTask, {
    onSuccess: async () => {
      // Refetch list of tasks
      await queryClient.invalidateQueries(taskKeys.all);
    },
  });
};
