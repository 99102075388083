import React from 'react';
import StatusBar from '../StatusBar';

export const RescoringStatusBar = () => {
  return (
    <StatusBar bgColor="kgray.400">
      Data refresh currently in progress. Some account actions are disabled
      until the refresh is complete.
    </StatusBar>
  );
};
