import {extendTheme} from '@chakra-ui/react';
import {ScoringCategory} from '../shared/signals';
import {radioTheme} from './theme/radio';
import {buttonTheme} from './theme/button';
import {tooltipTheme} from './theme/tooltip';

const kblue = {
  50: '#EDF2FD',
  100: '#CED9ED',
  200: '#7493CF',
  300: '#4874C0',
  400: '#295CCB',
  500: '#2351B2',
};

const kgray = {
  50: '#FFFFFF',
  100: '#F8F8FB',
  200: '#E8E8EB',
  300: '#76767E',
  400: '#28282C',
  500: '#0A0A0A',
};

const kgreen = {
  50: '#EEFFF8',
  100: '#DAF6EA',
  200: '#97DABE',
  300: '#6CBB9A',
  400: '#0F9E63',
  500: '#026C40',
};

// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  components: {
    Button: buttonTheme,
    Radio: radioTheme,
    Tooltip: tooltipTheme,
  },
  fonts: {
    body: 'DM Sans, system-ui',
    heading: 'DM Sans, system-ui',
    mono: 'DM Mono, system-ui',
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        backgroundColor: 'white',
        color: '#0A0A0A',
        lineHeight: 1.3,
        WebkitFontSmoothing: 'auto',
        MozOsxFontSmoothing: 'auto',
      },
    },
  },
  // for now I just created a different set of constants for keyplay colors.
  // chakra colors go from 50-900, and keyplay from 50-500.
  // won't run into this until we see chakra components using their colors by
  // default
  // 400 is "base"
  colors: {
    // red is also brand color, maybe
    kred: {
      50: '#FEF1F5',
      100: '#F2D2DC',
      200: '#DF7C99',
      300: '#D8547A',
      400: '#D23559',
      500: '#B82E4E',
    },
    kgreen,
    kblue,
    kpurple: {
      50: '#F3EDFD',
      100: '#DCD0F1',
      200: '#9E7ADD',
      300: '#804ED3',
      400: '#7329E8',
      500: '#6524CB',
    },
    korange: {
      50: '#FCEEE2',
      100: '#F6E1CF',
      200: '#ECAB7C',
      300: '#E99054',
      400: '#E67A35',
      500: '#C96B2E',
    },
    kyellow: {
      50: '#FFFAE6',
      100: '#F9F2D3',
      200: '#F6E9AA',
      300: '#F3DF86',
      400: '#EDCC4B',
      500: '#CFB141',
    },
    kgray,

    // hacked together to make buttons work
    kbuttonblue: {
      ...kblue, // needed for other variants
      500: kblue[400], // background
      600: kblue[500], // hover
      700: kblue[500], // active
    },
    kbuttonsecondary: {
      500: kgray[100],
      600: kgray[200],
      700: '#C8C8C8',
    },
    kbuttongreen: {
      500: kgreen[100],
      600: kgreen[200],
      700: kgreen[300],
    },
  },
});

/* We use consistent colors for tag categories across the site */
export const ScoringCategoryColors: {[c in ScoringCategory]: string} = {
  profile: 'kblue', // blue
  org: 'kgreen',
  relevance: 'kpurple',
  stackFit: 'korange',
} as const;
