import React, {useState, useEffect, ChangeEvent} from 'react';

import {Input} from '@chakra-ui/react';

export const BonusCodeLength = 16;

export const BonusCodeInput = ({
  onCodeChange,
}: {
  onCodeChange?: (code: string) => void;
}) => {
  const [code, setCode] = useState<string>('');

  useEffect(() => {
    onCodeChange?.(code.replace(/[-]/gi, ''));
  }, [code, onCodeChange]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const endsWithHyphen = event.target.value.endsWith('-');

    let val =
      event.target.value
        .toUpperCase() // uppercase
        .replace(/[^A-Z0-9]/gi, '') // only allow alphanumeric
        .match(/.{1,4}/g) // insert hyphen every 4 characters
        ?.join('-') ?? '';

    // let user enter next block hyphen if they want
    if (
      endsWithHyphen &&
      val.length > 0 &&
      val.replace(/[^A-Z0-9]/gi, '').length % 4 === 0
    ) {
      val += '-';
    }

    setCode(val);
  };

  return (
    <Input
      value={code}
      onChange={(event) => handleChange(event)}
      placeholder="paste your secret code here"
      maxLength={19}
      width="300px"
    />
  );
};
