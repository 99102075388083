import React from 'react';
import {IntegrationSection} from './components';
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Link,
  Radio,
  RadioGroup,
  RadioProps,
  Stack,
  Switch,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {IntegrationType} from '../../shared/integrations';
import {useIntegrationDetailsState} from './IntegrationDetails.state';
import {AllIntegrations} from './AllIntegrations';
import {useCustomer} from '../../hooks/api/metadata';
import {useFeatures} from '../../hooks/api/metadata';
import {
  OneWaySyncSetting,
  SelectiveTwoWaySyncSetting,
  SyncSettings,
  TwoWaySyncSetting,
} from '../../shared/integrations';
import {Bold, Underline} from '../text';
import {getSupportedSyncTypes} from '../../shared/customer';

export const Settings = ({
  integrationName,
}: {
  integrationName: IntegrationType;
}) => {
  const customer = useCustomer();
  const features = useFeatures();

  const integrationInfo = AllIntegrations[integrationName];
  const {
    autoSync,
    setAutoSync,
    syncSettings,
    setSyncSettings,
    publishChanges,
    serverState,
  } = useIntegrationDetailsState(integrationName);

  const supportedSyncModes = getSupportedSyncTypes({
    plan: customer.plan,
    features: features,
  });

  const updateSyncSettings = (settings: SyncSettings) => {
    setSyncSettings(settings);
    publishChanges.mutate();
  };

  const hasSyncSettings = !!serverState?.config?.syncSettings?.type;

  return (
    <IntegrationSection>
      <Box fontSize={'lg'} fontWeight={500} mb={6}>
        Sync Settings
      </Box>
      <HStack>
        <Tooltip label="Please select a sync type" isDisabled={hasSyncSettings}>
          <Box px={4} py={2}>
            <Switch
              size="md"
              isDisabled={!hasSyncSettings}
              isChecked={autoSync}
              onChange={(event) => {
                setAutoSync(event.target.checked);
                publishChanges.mutate();
              }}
            />
          </Box>
        </Tooltip>
        <Box>
          Automatically run {integrationInfo.label} CRM sync every week.
        </Box>
      </HStack>
      <Divider my={4} />
      <RadioGroup px={6} value={syncSettings?.type} isDisabled={autoSync}>
        <Stack gap={3}>
          <OneWaySyncRadio
            supported={supportedSyncModes.includes('oneWay')}
            syncSettings={syncSettings?.type === 'oneWay' && syncSettings}
            setSyncSettings={updateSyncSettings}
          />
          <TwoWaySyncRadio
            supported={supportedSyncModes.includes('twoWay')}
            syncSettings={syncSettings?.type === 'twoWay' && syncSettings}
            setSyncSettings={updateSyncSettings}
          />
          <TwoWaySelectiveSyncRadio
            supported={supportedSyncModes.includes('selectiveTwoWay')}
            syncSettings={
              syncSettings?.type === 'selectiveTwoWay' && syncSettings
            }
            setSyncSettings={updateSyncSettings}
          />
        </Stack>
      </RadioGroup>
    </IntegrationSection>
  );
};

const BaseRadio = ({
  children,
  ...props
}: React.PropsWithChildren<RadioProps>) => {
  return (
    <Radio alignItems="start" size="lg" spacing={8} {...props}>
      <Box fontSize="md">{children}</Box>
    </Radio>
  );
};

type SyncRadioProps<T extends SyncSettings> = {
  supported: boolean;
  syncSettings: T | false;
  setSyncSettings: (settings: T) => void;
};

const OneWaySyncRadio = ({
  supported,
  setSyncSettings,
}: SyncRadioProps<OneWaySyncSetting>) => {
  return (
    <BaseRadio
      isDisabled={!supported}
      value="oneWay"
      onChange={() => setSyncSettings({type: 'oneWay'})}
    >
      <Bold>1-way sync:</Bold> Automatically create newly saved Keyplay accounts
      in your CRM.
    </BaseRadio>
  );
};

const TwoWaySyncRadio = ({
  supported,
  syncSettings,
  setSyncSettings,
}: SyncRadioProps<TwoWaySyncSetting>) => {
  return (
    <BaseRadio
      isDisabled={!supported}
      value="twoWay"
      onChange={() =>
        setSyncSettings({
          type: 'twoWay',
          pushSavedAccounts: true,
        })
      }
    >
      <Flex gap={2}>
        {!supported && <UpgradeButton />}
        <Box>
          <Bold>Full 2-way sync:</Bold> Create newly saved accounts{' '}
          <Underline>and</Underline> automatically enrich <Bold>all</Bold>{' '}
          existing CRM accounts.
        </Box>
      </Flex>

      {syncSettings && (
        <Checkbox
          mt={2}
          isChecked={syncSettings.pushSavedAccounts}
          onChange={(event) =>
            setSyncSettings({
              type: 'twoWay',
              pushSavedAccounts: event.target.checked,
            })
          }
        >
          Automatically create records in your CRM for newly Saved accounts.
        </Checkbox>
      )}
    </BaseRadio>
  );
};

const TwoWaySelectiveSyncRadio = ({
  supported,
  syncSettings,
  setSyncSettings,
}: SyncRadioProps<SelectiveTwoWaySyncSetting>) => {
  return (
    <BaseRadio
      isDisabled={!supported}
      value="selectiveTwoWay"
      onChange={() => {
        setSyncSettings({
          type: 'selectiveTwoWay',
          pushSavedAccounts: true,
        });
      }}
    >
      <Flex gap={2}>
        {!supported && <UpgradeButton />}
        <Box>
          <Bold>Selective 2-way sync:</Bold> Create newly saved accounts{' '}
          <Underline>and</Underline> enrich <Bold>a subset of</Bold> existing
          CRM accounts.
        </Box>
      </Flex>

      {syncSettings && (
        <Flex direction="column" gap={2} mt={2}>
          <Flex alignItems="center" mx={1} gap={3}>
            <Text fontFamily="serif" fontSize="xl" fontWeight="bold">
              i
            </Text>
            <Box color="kgray.300">
              Upload your selective sync list at My Accounts &gt; CRM &gt;
              Import.
            </Box>
          </Flex>
          <Checkbox
            isChecked={syncSettings.pushSavedAccounts}
            onChange={(event) =>
              setSyncSettings({
                type: 'selectiveTwoWay',
                pushSavedAccounts: event.target.checked,
              })
            }
          >
            Automatically create records in your CRM for newly Saved accounts.
          </Checkbox>
        </Flex>
      )}
    </BaseRadio>
  );
};

const UpgradeButton = ({...props}: ButtonProps) => {
  return (
    <Button
      // Link props
      as={Link}
      href="https://keyplay.io/pricing"
      target="_blank"
      // Button props
      colorScheme="kgray"
      flexShrink={0}
      fontWeight="normal"
      h="fit-content"
      py={1}
      size="sm"
      {...props}
    >
      Upgrade
    </Button>
  );
};
