import React, {Fragment} from 'react';
import {
  useAccountQueryActionContext,
  useAccountActions,
} from '../../hooks/api/actions';
import {
  AccountAction,
  AccountActionQueryLimit,
  MetadataResponse,
} from '../../shared/api/api';
import {
  Box,
  Button,
  ButtonProps,
  Stack,
  Tooltip,
  TooltipProps,
  forwardRef,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {useIsMyAccountsView, useUserControls} from './AccountGrid.controlstate';
import {useIsCrmSyncing} from '../../hooks/api/integrations';
import {
  useCustomer,
  useIsFreeOrListBuilderCustomer,
  useIsFreemiumCustomer,
} from '../../hooks/api/metadata';
import {
  TaskRemove,
  Redo,
  CheckmarkOutline,
  Close,
  ArrowsVertical,
  TagEdit,
  AddFilled,
} from '@carbon/icons-react';
import _ from 'lodash';
import {useActions, TierOverridePopout} from './AccountGrid.actions';
import {AccountTagsEditorModal} from '../AccountTagsEditor';
import {ScoredAccount} from '../../shared/scoredAccounts';
import {useScoringInfo} from '../../hooks/api/scoringInfo';
import {getLimit, getSavesQuota} from '../../shared/customer';
import {useScoringRun} from '../../context/ScoringRunContext';
import {useShouldAutoSelectRows} from './useShouldAutoSelectRows';
import {CreditsPurchaseModal} from '../App/CreditUsage';

interface ActionButtonsProps {
  fetchedAccounts: ScoredAccount[];
  numSelected: number;
}

export const ActionButtons = ({numSelected}: ActionButtonsProps) => {
  const toast = useToast();
  const isMyAccountsView = useIsMyAccountsView();
  const tagAccountsModal = useDisclosure();

  const customer = useCustomer();
  const isFreemiumCustomer = useIsFreemiumCustomer();
  const isFreeOrListBuilderCustomer = useIsFreeOrListBuilderCustomer();

  const isCrmSyncing = useIsCrmSyncing();
  const {resetRowSelection} = useUserControls();
  const {data: scoringInfo} = useScoringInfo();
  const scoringRun = useScoringRun();
  const actionContext = useAccountQueryActionContext();
  const accountActions = useAccountActions(actionContext);

  const updateAccounts = ({action}: {action: AccountAction}) => {
    if (!actionContext) {
      return;
    }

    accountActions.mutate(
      {action},
      {
        onSuccess: () => {
          if (action.name === 'override') {
            const descriptionPieces = [
              action.override === 'none' && 'Removed overrides from ',
              `${numSelected} account${numSelected !== 1 ? 's' : ''}`,
              action.override !== 'none' && ` moved to Tier ${action.override}`,
              '.',
            ].filter((x) => x);

            toast({
              title: 'Override Tier successful',
              description: descriptionPieces.join(''),
              status: 'success',
              duration: 7_000,
              isClosable: true,
              position: 'bottom-left',
            });
          }

          resetRowSelection();
        },
      }
    );
  };

  const handleSaveAndTag = () => {
    if (!isMyAccountsView && isFreeOrListBuilderCustomer) {
      accountActions.mutate(
        {action: {name: 'tag'}},
        {
          onSuccess: () => {
            resetRowSelection();
          },
        }
      );
    } else {
      tagAccountsModal.onOpen();
    }
  };

  const disabledActionTooltip = _.compact([
    // List of reasons why actions might be disabled. The first one "wins"
    // when determining what message to show to the user.
    isCrmSyncing ? 'Actions are disabled during CRM sync' : null,
    scoringInfo?.isRescoring
      ? 'Actions are disabled during data refresh'
      : null,
    numSelected > AccountActionQueryLimit
      ? `Actions cannot be performed on more than ${AccountActionQueryLimit} accounts`
      : null,
  ]).at(0);

  const isFreemiumDiscover = isFreemiumCustomer && !isMyAccountsView;

  const autoSelectTopRows = useShouldAutoSelectRows();
  const {showQuotaTooltip, tagTooltipLabel, tagTooltipIsOpen} =
    tagButtonTooltipConfig({
      customer,
      isMyAccountsView,
      disabledActionTooltip,
      numSelected,
      activeAccounts: scoringRun.numActive,
      autoSelectTopRows,
    });

  const actionsEnabled =
    !disabledActionTooltip && !accountActions.isLoading && numSelected;
  const actionsToShow = useActions();
  const creditsModal = useDisclosure();

  const allActionButtons = {
    exclude: (
      <ActionButtonWithTooltip
        key="exclude"
        isDisabled={!actionsEnabled}
        leftIcon={<TaskRemove />}
        onClick={() => updateAccounts({action: {name: 'exclude'}})}
        tooltipProps={{
          label: disabledActionTooltip,
        }}
      >
        Exclude
      </ActionButtonWithTooltip>
    ),
    reactivate: (
      <ActionButtonWithTooltip
        key="reactivate"
        isDisabled={!actionsEnabled}
        leftIcon={<Redo />}
        onClick={() => updateAccounts({action: {name: 'reactivate'}})}
        tooltipProps={{
          label: disabledActionTooltip,
        }}
      >
        Reactivate
      </ActionButtonWithTooltip>
    ),
    approve: (
      <ActionButtonWithTooltip
        key="save"
        isDisabled={!actionsEnabled}
        leftIcon={<CheckmarkOutline />}
        onClick={() => updateAccounts({action: {name: 'approve'}})}
        tooltipProps={{
          label: disabledActionTooltip,
        }}
      >
        Save
      </ActionButtonWithTooltip>
    ),
    disqualify: (
      <ActionButtonWithTooltip
        key="disqualify"
        isDisabled={!actionsEnabled}
        leftIcon={<Close />}
        onClick={() => updateAccounts({action: {name: 'disqualify'}})}
        tooltipProps={{
          label: disabledActionTooltip,
        }}
      >
        Disqualify
      </ActionButtonWithTooltip>
    ),
    override: (
      <TierOverridePopout
        key="override"
        onClick={(override) =>
          updateAccounts({action: {name: 'override', override}})
        }
        bulkNumberSelected={numSelected}
      >
        <ActionButtonWithTooltip
          isDisabled={!actionsEnabled}
          leftIcon={<ArrowsVertical />}
          tooltipProps={{
            label: disabledActionTooltip,
          }}
        >
          Override Tier
        </ActionButtonWithTooltip>
      </TierOverridePopout>
    ),
    tag: (
      <Fragment key="tag">
        <ActionButtonWithTooltip
          variant={isFreemiumDiscover ? 'glow' : 'ghost'}
          colorScheme={isFreemiumDiscover ? 'kbuttonblue' : undefined}
          isDisabled={!actionsEnabled || showQuotaTooltip}
          isLoading={accountActions.isLoading}
          leftIcon={<TagEdit />}
          onClick={handleSaveAndTag}
          tooltipProps={{
            label: tagTooltipLabel,
            isOpen: tagTooltipIsOpen,
          }}
        >
          {isMyAccountsView
            ? 'Tag'
            : isFreeOrListBuilderCustomer
              ? 'Save'
              : 'Save & Tag'}
        </ActionButtonWithTooltip>
        <AccountTagsEditorModal
          bulkNumberSelected={numSelected}
          actionContext={actionContext}
          isOpen={tagAccountsModal.isOpen}
          onClose={tagAccountsModal.onClose}
          onTagsUpdated={() => {
            resetRowSelection();
          }}
          selectedTags={[]}
          tagOnly={isMyAccountsView}
        />
      </Fragment>
    ),
    addCredits: (
      <Fragment key="addCredits">
        <Button
          color="kred.300"
          fontSize="14"
          fontWeight="500"
          leftIcon={<AddFilled />}
          isDisabled={creditsModal.isOpen}
          onClick={creditsModal.onOpen}
          variant="ghost"
        >
          Add Credits
        </Button>
        <CreditsPurchaseModal
          isOpen={creditsModal.isOpen}
          onClose={creditsModal.onClose}
        />
      </Fragment>
    ),
  };

  const actionButtons = _.pick(allActionButtons, actionsToShow);
  return <>{Object.values(actionButtons)}</>;
};

type ActionButtonProps = ButtonProps & {
  tooltipProps: Omit<TooltipProps, 'children'>;
};

const ActionButtonWithTooltip = forwardRef<ActionButtonProps, 'button'>(
  ({children, tooltipProps, ...buttonProps}, ref) => {
    return (
      <Tooltip
        hasArrow
        borderRadius="md"
        p="2"
        placement="top"
        {...tooltipProps}
      >
        <Button
          color="kgray.400"
          fontSize="14"
          fontWeight="500"
          ref={ref}
          variant="ghost"
          {...buttonProps}
        >
          {children}
        </Button>
      </Tooltip>
    );
  }
);

function tagButtonTooltipConfig({
  customer,
  isMyAccountsView,
  activeAccounts,
  numSelected,
  disabledActionTooltip,
  autoSelectTopRows,
}: {
  customer: MetadataResponse['customer'];
  isMyAccountsView: boolean;
  activeAccounts: number;
  numSelected: number;
  disabledActionTooltip: string | undefined;
  autoSelectTopRows: boolean;
}) {
  const isFreemiumCustomer = customer.plan.type === 'free';
  const isListBuilderCustomer = customer.plan.type === 'listBuilder';

  const activeAccountsLimit = getLimit(customer, 'activeAccounts');
  const {remaining: remainingSaves, limit: saveLimit} = getSavesQuota(customer);

  const showSaveQuotaTooltip =
    !isMyAccountsView && numSelected > remainingSaves;
  const showActiveAccountsQuotaTooltip =
    !isMyAccountsView && numSelected + activeAccounts > activeAccountsLimit;

  let tagTooltipLabel, tagTooltipIsOpen;
  if (disabledActionTooltip) {
    tagTooltipLabel = disabledActionTooltip;
  } else if (autoSelectTopRows) {
    tagTooltipLabel = 'Try saving your first 3 accounts!';
    tagTooltipIsOpen = true;
  } else if (showSaveQuotaTooltip) {
    const cta = isFreemiumCustomer
      ? `Upgrade to a paid plan to save more than ${saveLimit} accounts.`
      : isListBuilderCustomer
        ? 'Select fewer accounts or purchase credits to save.'
        : 'Please contact us for support.';
    tagTooltipLabel = (
      <Stack>
        <Box>
          Unable to save the selected number of accounts because it would exceed
          your remaining save credits.
        </Box>
        <Box>{cta}</Box>
      </Stack>
    );
  } else if (showActiveAccountsQuotaTooltip) {
    const cta =
      isFreemiumCustomer || isListBuilderCustomer
        ? `Select fewer accounts or upgrade to a paid plan to save more than ${activeAccountsLimit} total accounts.`
        : 'Please contact us for support.';
    tagTooltipLabel = (
      <Stack>
        <Box>
          Unable to save the selected number of accounts because it would exceed
          your account limit.
        </Box>
        <Box>{cta}</Box>
      </Stack>
    );
  }

  return {
    showQuotaTooltip: showSaveQuotaTooltip || showActiveAccountsQuotaTooltip,
    tagTooltipLabel,
    tagTooltipIsOpen,
  };
}
