import React from 'react';
import {Flex, FlexProps} from '@chakra-ui/react';
import {useSimilarityState} from './Market/ModelBuilder/Similarity.state';
import {useCustomer} from '../hooks/api/metadata';
import _ from 'lodash';
import {AccountTypeahead} from './AccountTypeahead';
import {AccountList} from './Settings/AiFieldBuilder/AccountList';

export const LookalikeTypeahead = (props: FlexProps) => {
  const similarityState = useSimilarityState();

  const customer = useCustomer();
  const {maxLookalikes} = similarityState.getLimits(customer);

  const reversedListOfAccounts = [...similarityState.localState].reverse();
  const typeaheadDisabled = reversedListOfAccounts.length >= maxLookalikes;

  return (
    <Flex direction="column" gap="2" {...props}>
      <AccountTypeahead
        disabledTooltip="Lookalike limit reached"
        isDisabled={typeaheadDisabled}
        onSelect={(account) => similarityState.addLookalike(account)}
        placeholder="Enter a company domain (e.g. nike.com)"
        selected={similarityState.localState.map((account) => account._id)}
      />
      <AccountList
        onRemoveAccount={similarityState.removeLookalike}
        selectedAccounts={reversedListOfAccounts}
      />
    </Flex>
  );
};
