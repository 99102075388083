import React, {useCallback} from 'react';
import {
  Box,
  ButtonProps,
  Code,
  useToast,
  Tooltip,
  Flex,
  Button,
  Text,
} from '@chakra-ui/react';
import {useDropzone} from 'react-dropzone';
import {useBulkFindLookalikesMutation} from '../../../hooks/api/lookalikes';
import {useCustomer} from '../../../hooks/api/metadata';
import {validateFile} from '../../../lib/validateFile';
import {isDomainImportRow} from '../../../shared/import';
import {useSimilarityState} from './Similarity.state';

export const LookalikesImportButton = ({children, ...rest}: ButtonProps) => {
  const customer = useCustomer();
  const toast = useToast();
  const {getLimits, localState, addLookalikes} = useSimilarityState();
  const bulkFindLookalikes = useBulkFindLookalikesMutation();

  const {maxLookalikes} = getLimits(customer);
  const remainingLookalikes = maxLookalikes - localState.length;

  const showErrorToast = useCallback(() => {
    toast({
      title: 'Invalid lookalike list',
      description: `Please ensure your CSV file has a Domain column and is less than ${remainingLookalikes} rows.`,
      status: 'error',
    });
  }, [toast, remainingLookalikes]);

  const {open} = useDropzone({
    accept: {'text/csv': ['.csv']},
    onDropAccepted: (files) => {
      const file = files[0];
      if (!file) {
        return;
      }

      validateFile({
        // Only allow up to the remaining lookalikes to be added.
        maxRows: remainingLookalikes,
        onFileValidate: (errors, data) => {
          if (errors.size) {
            showErrorToast();
            return;
          }

          const domains = data.map(({Domain}) => Domain);
          bulkFindLookalikes.mutate(domains, {
            onSuccess: addLookalikes,
          });
        },
        rowSchemaTypeguard: isDomainImportRow,
        selectedFile: file,
      });
    },
    onDropRejected: showErrorToast,
    maxSize: 10_000, // in bytes
    multiple: false,
  });

  return (
    <Tooltip
      hasArrow
      isDisabled={bulkFindLookalikes.isLoading}
      label={
        <Flex direction="column" gap="4" my="2">
          <Text>
            Recommended for larger lists. The CSV should include a single column
            of domain names.
          </Text>
          <Code>
            <Box>Domain</Box>
            <Box>example1.com</Box>
            <Box>example2.com</Box>
          </Code>
        </Flex>
      }
      placement="top"
    >
      <Button
        colorScheme="kbuttonblue"
        fontSize="sm"
        fontWeight="normal"
        isDisabled={bulkFindLookalikes.isLoading || remainingLookalikes <= 0}
        onClick={open}
        variant="outline"
        {...rest}
      >
        {children}
      </Button>
    </Tooltip>
  );
};
