import React from 'react';
import {Center, Flex, Text} from '@chakra-ui/react';
import {LookalikeTypeahead} from '../../LookalikeTypeahead';
import {ScrollableFlex, WrapperFlex} from '../../utils/scrolling';
import {LookalikesImportButton} from '../ModelBuilder/LookalikesImportButton';
import {useIsBulkFindLookalikesLoading} from '../../../hooks/api/lookalikes';

export const Lookalikes = () => {
  const isLoadingLookalikes = useIsBulkFindLookalikesLoading();

  return (
    <WrapperFlex>
      <ScrollableFlex p={0}>
        <Center>
          <Flex
            bgColor="kgray.50"
            borderColor="kgray.200"
            borderWidth="1px"
            borderRadius="lg"
            direction="column"
            gap="4"
            my="6"
            px="6"
            py="8"
            minWidth="600px"
            width="50%"
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="2xl">
                Which top accounts do you want to replicate?
              </Text>
              <LookalikesImportButton>Upload a CSV</LookalikesImportButton>
            </Flex>
            {isLoadingLookalikes ? (
              <Center flexDirection="column" my="20">
                <Text>Uploading your lookalikes...</Text>
              </Center>
            ) : (
              <LookalikeTypeahead />
            )}
          </Flex>
        </Center>
      </ScrollableFlex>
    </WrapperFlex>
  );
};
