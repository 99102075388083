import React from 'react';
import {Text, useToast} from '@chakra-ui/react';
import Select, {OptionProps, StylesConfig, components} from 'react-select';
import {useScoringModeState} from './ScoringMode.state';
import {ScoringMode} from '../../../shared/market';

interface ScoringModeOption {
  value: ScoringMode;
  label: string;
  description: string;
}

const ScoringOption = (props: OptionProps) => {
  const {label, description} = props.data as ScoringModeOption;
  return (
    <components.Option {...props}>
      <Text fontSize="14">{label}</Text>
      <Text fontSize="12" mt="2" fontWeight="thin" color="kgray.300">
        {description}
      </Text>
    </components.Option>
  );
};

const scoringModeSelectStyles: StylesConfig = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
  control: (base) => ({
    ...base,
    border: '1px solid #EBE8EA',
    borderRadius: '10px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#EDF2FD' : '',
    color: 'inherit',
    ':hover': {
      backgroundColor: '#EDF2FD',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
};

const successToastId = 'successToast';
export const ScoringModeSelector = () => {
  const {setScoringMode, clientState, publishChanges} = useScoringModeState();
  const toast = useToast();

  const options = [
    {
      value: {type: 'highest_value'},
      label: 'Take the highest score',
      description:
        'Overall score will be the higher of similarity and signal scores.',
    },
    {
      value: {type: 'similarity_plus_bonus_points'},
      label: 'Similarity score + bonus points for signals',
      description: '20 bonus points are added for strong signal scores.',
    },
    {
      value: {type: 'signal_score_plus_bonus_points'},
      label: 'Signal score + bonus points for similarity',
      description: '20 bonus points are added for strong lookalike matches.',
    },
    {
      value: {type: 'only_similarity'},
      label: 'Similarity score only',
      description:
        'You’ll see signal scores, but they are excluded from overall score.',
    },
    {
      value: {type: 'only_signal_score'},
      label: 'Signal score only',
      description:
        'You’ll see similarity scores, but they are excluded from overall score.',
    },
  ] satisfies ScoringModeOption[];

  return (
    <Select
      isLoading={publishChanges.isLoading}
      value={options.find((o) => o.value.type === clientState?.type)}
      options={options}
      components={{Option: ScoringOption}}
      styles={scoringModeSelectStyles}
      isSearchable={false}
      onChange={(option) => {
        setScoringMode((option as ScoringModeOption).value);
        publishChanges.mutate(undefined, {
          onSuccess: () => {
            if (toast.isActive(successToastId)) {
              return;
            }
            toast({
              id: successToastId,
              title: 'Changes saved',
              status: 'success',
              duration: 2_000,
            });
          },
        });
      }}
    />
  );
};
