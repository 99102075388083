import React from 'react';
import {
  Box,
  BoxProps,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

interface SignalWeightInputProps extends BoxProps {
  weight: number;
  onWeightChange: (val: number) => void;
  min: number;
  max: number;
}

export const SignalWeightInput = ({
  weight,
  onWeightChange,
  min,
  max,
  ...rest
}: SignalWeightInputProps) => {
  return (
    <Box {...rest}>
      <NumberInput
        onChange={(_, valAsNumber) =>
          !isNaN(valAsNumber) ? onWeightChange(valAsNumber) : null
        }
        value={weight}
        precision={0}
        step={1}
        parse={(val) => val.replace(/x/, '')}
        format={(val) => val + 'x'}
        min={min}
        max={max}
      >
        <NumberInputField
          sx={{
            borderColor: '#EBE8EA',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '10px',
          }}
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Box>
  );
};
