import React, {useMemo} from 'react';
import {createColumnHelper} from '@tanstack/react-table';
import _ from 'lodash';
import {
  ScoringSignal,
  ScoringCategory,
  ScoringCategorySignalLabels,
  ScoringSignalResolver,
} from '../../shared/signals';
import {
  NameRenderer,
  SignalsRenderer,
  TagsRenderer,
  FitRendererWithPopover,
} from './AccountGrid.components';
import {Checkmark} from '@carbon/icons-react';
import {Box, Flex, SimpleGrid, Tooltip} from '@chakra-ui/react';
import {ScoredAccount} from '../../shared/scoredAccounts';
import {CountryList} from '../../shared/countries';
import {CategoriesAndAudienceRenderer} from './CategoriesAndAudienceRenderer';
import {ChakraInformation} from '../Icons/Carbon';
import {useScoringSignalResolver} from '../../hooks/scoringSignal';
import {ObjectId} from 'bson';
import {useAccountView} from './AccountGrid';

const columnHelper = createColumnHelper<ScoredAccount>();

// column accessor that gets signals within a specific category for an account
const signalsByCategoryAccessor = (
  category: ScoringCategory,
  scoringSignalResolver: ScoringSignalResolver
) => {
  return (account: ScoredAccount): ScoringSignal[] =>
    account.matchedSignals.filter(
      (signal) => scoringSignalResolver(signal)?.category === category
    );
};

export const useColumnDefinitions = (
  onOpenDetails?: (accountId: ObjectId) => void
) => {
  const accountView = useAccountView();
  const scoringSignalResolver = useScoringSignalResolver();

  return useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        // We render the markup ourselves in the table view (since we need non-table state)
        header: () => null,
        cell: () => null,
        meta: {
          width: 40,
          doesntOpenDetails: true,
        },
      }),
      columnHelper.accessor(
        (acc): [string, string, string, boolean] => [
          acc.fields.name || acc.accountDomain,
          acc.accountDomain,
          acc.accountLinkedIn,
          acc.inCrm,
        ],
        {
          id: 'account',
          cell: (info) => (
            <NameRenderer
              name={info.getValue()[0]}
              domain={info.getValue()[1]}
              liUrl={info.getValue()[2]}
              inCrm={info.getValue()[3]}
              showCrmStatus={accountView !== 'recommended'}
            />
          ),
          header: 'Account',
          meta: {
            width: 200,
          },
        }
      ),
      columnHelper.accessor(
        (acc) => [
          acc.tier,
          acc.mutatedState.tierOverride,
          acc.mutatedState.displayTier,
        ],
        {
          id: 'Tier',
          cell: (info) => {
            const [originalTier, tierOverride, tierToDisplay] = info.getValue();
            if (tierOverride !== 'none') {
              // TODO: tooltips are just going to further slow scrolling down.
              // I might need to create a custom implementation here at some point
              return (
                <Tooltip label={`Overridden from tier ${originalTier}`}>
                  <span>{tierToDisplay}*</span>
                </Tooltip>
              );
            }
            return tierToDisplay;
          },
          header: () => {
            const tierTooltip = (
              <SimpleGrid columns={2}>
                <Box fontWeight="bold" textAlign="center">
                  Tier
                </Box>
                <Box fontWeight="bold" textAlign="center">
                  Overall Fit
                </Box>
                <Box textAlign="center">A</Box>
                <Box textAlign="center">70+</Box>
                <Box textAlign="center">B</Box>
                <Box textAlign="center">50+</Box>
                <Box textAlign="center">C</Box>
                <Box textAlign="center">30+</Box>
                <Box textAlign="center">D</Box>
                <Box textAlign="center">&lt;30</Box>
              </SimpleGrid>
            );
            return (
              <Tooltip hasArrow label={tierTooltip} placement="top">
                Tier
              </Tooltip>
            );
          },
          meta: {
            width: 45,
          },
        }
      ),
      columnHelper.accessor(
        (acc) => ({
          overallFit: acc.overallFit,
          signalScore: acc.signalScore,
          mostSimilarTo: acc.mostSimilarTo,
        }),
        {
          id: 'overallFit',
          cell: (info) => {
            const {overallFit, signalScore, mostSimilarTo} = info.getValue();
            return (
              <FitRendererWithPopover
                value={overallFit}
                signalScore={signalScore}
                mostSimilarTo={mostSimilarTo}
              />
            );
          },
          header: 'Overall Fit',
          meta: {
            width: 97,
          },
        }
      ),
      ...(accountView === 'saved'
        ? [
            columnHelper.accessor((acc) => acc.mutatedState.saveDate, {
              id: 'savedAt',
              cell: (info) => info.getValue()?.toLocaleDateString() ?? null,
              header: 'Saved On',
              meta: {
                width: 100,
              },
            }),
          ]
        : []),

      columnHelper.accessor(
        (acc) => ({
          audience: acc.audience ?? [],
          primary: acc.primaryCategory ?? [],
          secondary: acc.secondaryCategory ?? [],
        }),
        {
          id: 'category',
          cell: (info) => (
            <CategoriesAndAudienceRenderer
              audiences={info.getValue().audience}
              primaryCategories={info.getValue().primary}
              secondaryCategories={info.getValue().secondary}
              onViewDetails={() => onOpenDetails?.(info.row.original.accountId)}
            />
          ),
          header: () => (
            <Flex alignItems="baseline" justifyContent="space-between">
              <Box>Categories</Box>
              <Flex
                color="kgray.300"
                fontWeight="normal"
                gap="1"
                justifyContent="end"
                textTransform="none"
                whiteSpace="nowrap"
              >
                Hover <ChakraInformation /> for details
              </Flex>
            </Flex>
          ),
          meta: {
            width: 250,
          },
        }
      ),
      ...(accountView !== 'recommended'
        ? [
            columnHelper.accessor('mutatedState.tags', {
              cell: (info) => <TagsRenderer tagIds={info.getValue()} />,
              header: 'Tags',
              meta: {
                width: 200,
              },
            }),
          ]
        : []),
      columnHelper.accessor(
        signalsByCategoryAccessor('org', scoringSignalResolver),
        {
          cell: (info) => <SignalsRenderer signals={info.getValue()} />,
          header: ScoringCategorySignalLabels.org,
          meta: {
            width: 400,
          },
        }
      ),
      columnHelper.accessor(
        signalsByCategoryAccessor('relevance', scoringSignalResolver),
        {
          cell: (info) => <SignalsRenderer signals={info.getValue()} />,
          header: ScoringCategorySignalLabels.relevance,
          meta: {
            width: 400,
          },
        }
      ),
      columnHelper.accessor(
        signalsByCategoryAccessor('profile', scoringSignalResolver),
        {
          cell: (info) => <SignalsRenderer signals={info.getValue()} />,
          header: ScoringCategorySignalLabels.profile,
          meta: {
            width: 300,
          },
        }
      ),
      columnHelper.accessor(
        signalsByCategoryAccessor('stackFit', scoringSignalResolver),
        {
          cell: (info) => <SignalsRenderer signals={info.getValue()} />,
          header: ScoringCategorySignalLabels.stackFit,
          meta: {
            width: 300,
          },
        }
      ),
      ...(accountView !== 'recommended'
        ? [
            columnHelper.accessor((acc) => acc.inCrm, {
              id: 'inCRM',
              cell: (info) => (info.getValue() ? <Checkmark /> : null),
              header: 'CRM?',
              meta: {
                width: 55,
              },
            }),
            columnHelper.accessor((acc) => acc.recommended, {
              id: 'inSAM',
              cell: (info) => (info.getValue() ? <Checkmark /> : null),
              header: 'SAM?',
              meta: {
                width: 55,
              },
            }),
          ]
        : []),
      columnHelper.accessor((acc) => acc.fields.employeeCount, {
        id: 'employees',
        cell: (info) => info.getValue()?.toLocaleString(),
        header: 'Employees',
        meta: {
          width: 100,
        },
      }),
      columnHelper.accessor(
        (acc) =>
          CountryList[acc.fields.hqCountry as keyof typeof CountryList] ||
          acc.fields.hqCountry,
        {
          id: 'hq',
          // cell: (info) => info.getValue(),
          header: 'HQ Country',
          meta: {
            width: 100,
          },
        }
      ),

      columnHelper.accessor(
        (acc) =>
          (
            _.uniq(acc.fields.locationCountries).map(
              (code) => CountryList[code as keyof typeof CountryList]
            ) || _.uniq(acc.fields.locationCountries)
          ).join(', '),
        {
          id: 'locations',
          // cell: (info) => info.getValue(),
          header: 'Locations',
          meta: {
            width: 150,
          },
        }
      ),
      columnHelper.accessor((acc) => acc.fields.tagline, {
        id: 'tagline',
        header: 'Tagline',
        meta: {
          width: 280,
        },
      }),
      columnHelper.accessor((acc) => acc.fields.followerCount, {
        id: 'followers',
        cell: (info) => info.getValue()?.toLocaleString(),
        header: 'Followers',
        meta: {
          width: 200,
        },
      }),
      columnHelper.accessor((acc) => acc.fields.trafficRank, {
        id: 'trafficRank',
        cell: (info) => {
          // HACK: needed because trafficRank currently set to -1 if it's missing
          const value = info.getValue() || 0;
          return value > 0 ? value.toLocaleString() : '';
        },
        header: 'Traffic Rank',
        meta: {
          width: 140,
        },
      }),
    ],
    [accountView, onOpenDetails, scoringSignalResolver]
  );
};
