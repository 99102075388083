import React from 'react';
import _ from 'lodash';
import {
  chakra,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  forwardRef,
  Input,
  InputProps,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
  Checkbox,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';
import {ChevronDown, TrashCan} from '@carbon/icons-react';
import Select, {
  components,
  GroupProps,
  MultiValue,
  OptionProps,
  StylesConfig,
} from 'react-select';
import {BusinessAudience, BusinessAudienceLabels} from '../../shared/account';
import {entries, keys} from '../../shared/util';
import {SearchDropdownIndicator} from '../SearchDropdownIndicator';

// need this since chakra's onChange type is too general
export type FakeOnChangeType = (s: string | string[]) => void;

// Generic components

const pillStyles: BoxProps & ButtonProps = {
  bg: '#F8F8FB',
  borderRadius: 40,
  display: 'inline-block',
  mr: 4,
  fontSize: 22,
  px: 4,
  py: 2,
};

const Pill = ({children, ...props}: BoxProps) => {
  return (
    <Box {...pillStyles} {...props}>
      {children}
    </Box>
  );
};

const ChakraTrashCan = chakra(TrashCan);

const DeletablePill = ({children, onClick, ...props}: BoxProps) => {
  return (
    <Pill
      {...props}
      position="relative"
      role="group"
      cursor="pointer"
      onClick={onClick}
    >
      <Box _groupHover={{opacity: 0.1}} display="inline-block">
        {children}
      </Box>
      <ChakraTrashCan
        display="none"
        color="#000"
        position="absolute"
        left="50%"
        top="50%"
        transform="translateX(-50%)translateY(-50%)"
        zIndex={2}
        size={20}
        _groupHover={{
          display: 'block',
        }}
      />
    </Pill>
  );
};

interface PillTextFieldProps extends InputProps {
  placeholder?: string;
}
const PillTextField = ({placeholder, ...props}: PillTextFieldProps) => {
  return (
    <Input
      {...pillStyles}
      height="auto"
      border="none"
      width={48}
      placeholder={placeholder}
      {...props}
    />
  );
};

interface PillButtonProps extends ButtonProps {
  popover?: boolean;
  placeholder?: string;
  label?: string;
}

// Note: forwardRef usage needs to come from chakra, not react
const PillButton = forwardRef<PillButtonProps, 'button'>(
  ({popover = false, placeholder, value, ...props}, ref) => {
    const buttonProps: ButtonProps = {
      ...pillStyles,
      fontWeight: 400,
      role: 'group',
    };

    const text = value ? (
      <Box>{value}</Box>
    ) : (
      <Box color="kgray.300">{placeholder}</Box>
    );

    const contents = (
      <Flex display="inline-flex">
        {text}
        <Box
          width={6}
          borderRadius={100}
          display="flex"
          ml={2}
          transitionProperty="var(--chakra-transition-property-common);"
          transitionDuration="var(--chakra-transition-duration-normal);"
        >
          <ChevronDown size="24" />
        </Box>
      </Flex>
    );

    if (popover) {
      return (
        <Button {...buttonProps} {...props} ref={ref}>
          {contents}
        </Button>
      );
    }

    return (
      <MenuButton as={Button} {...buttonProps} {...props} ref={ref}>
        {contents}
      </MenuButton>
    );
  }
);

const selectStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    minWidth: 240,
    margin: 8,
  }),
  group: (provided) => ({
    ...provided,
    padding: 0,
  }),
  groupHeading: (provided) => ({
    ...provided,
    margin: 0,
  }),
  menu: () => ({boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)'}),
  menuList: (provided) => ({
    ...provided,
    paddingBlock: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#EDF2FD' : '',
    color: 'inherit',
    cursor: 'pointer',
    paddingInline: '8px',
    ':hover': {
      backgroundColor: '#EDF2FD',
    },
  }),
};

const CheckboxOption = (props: OptionProps) => {
  return (
    <components.Option {...props}>
      <Checkbox
        // Prevent the Checkbox from swallowing the click event and preventing the
        // default behavior of the Option component.
        inputProps={{onClick: (e) => e.stopPropagation()}}
        onClick={(e) => e.preventDefault()}
        isChecked={props.isSelected}
        px="2"
      />
      {props.children}
    </components.Option>
  );
};

const AccordionGroup = (props: GroupProps) => {
  // If the user has entered text into the search box, then we want to keep
  // the accordion open.
  const expandAccordion = props.selectProps.inputValue.length > 0;
  return (
    <Accordion
      allowToggle
      defaultIndex={0}
      index={expandAccordion ? 0 : undefined}
    >
      <AccordionItem border="0">
        <AccordionButton
          bgColor="white"
          borderBottom="1px"
          borderColor="kgray.200"
          position="sticky"
          top="0"
          zIndex="1"
          _hover={{bgColor: 'kgray.100'}}
        >
          <Box
            flex="1"
            fontSize="sm"
            fontWeight="500"
            textAlign="left"
            textTransform="uppercase"
          >
            {props.label}
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel p="0">
          <components.Group {...props} label={undefined} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

type OptionLabels<T extends string> = {[key in T]?: string};
interface PillSelectSentenceFragmentOptionGroup<T extends string> {
  selected: T[];
  setSelected: (state: T[]) => void;
  options: OptionLabels<T>;
}

export type GenericPillSelectSentenceFragmentOptionGroup<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends string = any,
> = PillSelectSentenceFragmentOptionGroup<T>;

type GroupLabelToOptionGroup = {
  [key in string]: GenericPillSelectSentenceFragmentOptionGroup;
};

export const SimplePillSelectSentenceFragment = <T extends string>({
  selected,
  setSelected,
  promptText,
  options,
}: {
  selected: T[];
  setSelected: (values: T[]) => void;
  promptText: string;
  options: {[key in T]: string};
}) => {
  return (
    <PillSelectSentenceFragment
      groupLabelToOptionGroup={{
        Locations: {
          selected: selected,
          setSelected: setSelected,
          options: options,
        } satisfies GenericPillSelectSentenceFragmentOptionGroup<T>,
      }}
      promptText={promptText}
    />
  );
};

export const PillSelectSentenceFragment = ({
  groupLabelToOptionGroup,
  promptText,
}: {
  groupLabelToOptionGroup: GroupLabelToOptionGroup;
  promptText: string;
}) => {
  const {isOpen, onOpen} = useDisclosure();

  const groupSelectOptions: {
    label: string;
    options: {label: string; value: string; type: string}[];
  }[] = [];
  const pills: JSX.Element[] = [];

  entries(groupLabelToOptionGroup).forEach(([groupLabel, optionGroup]) => {
    const selectOptions = Object.entries(optionGroup.options).map(
      ([value, label]) => ({
        label: label!,
        value: value,
        type: groupLabel,
      })
    );

    groupSelectOptions.push({
      label: groupLabel,
      options: selectOptions,
    });

    const categoryPills = optionGroup.selected.map((value) => (
      <DeletablePill
        onClick={() =>
          optionGroup.setSelected(_.without(optionGroup.selected, value))
        }
        key={value}
      >
        {optionGroup.options[value]}
      </DeletablePill>
    ));
    pills.push(...categoryPills);
  });

  return (
    <>
      <Box>
        <Popover placement="bottom-start" isLazy={true}>
          <>
            <PopoverTrigger>
              <PillButton
                popover={true}
                placeholder={promptText}
                onClick={onOpen}
              />
            </PopoverTrigger>

            <Portal>
              <PopoverContent fontSize={16} minW="sm" width="fit-content">
                <PopoverBody padding={0} display={isOpen ? 'block' : 'none'}>
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{
                      DropdownIndicator: SearchDropdownIndicator,
                      Group: AccordionGroup,
                      IndicatorSeparator: null,
                      Option: CheckboxOption,
                    }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    isMulti={true}
                    menuIsOpen={isOpen}
                    onChange={(values) => {
                      const castValues = values as MultiValue<{
                        value: string;
                        type: string;
                      }>;

                      const labelToValuesToSelect: Record<string, string[]> =
                        Object.fromEntries(
                          keys(groupLabelToOptionGroup).map((key) => [key, []])
                        );

                      castValues.forEach(({type, value}) =>
                        labelToValuesToSelect[type].push(value)
                      );

                      Object.entries(groupLabelToOptionGroup).forEach(
                        ([groupLabel, optionGroup]) =>
                          optionGroup.setSelected(
                            labelToValuesToSelect[groupLabel]
                          )
                      );
                    }}
                    options={
                      Object.values(groupSelectOptions).length === 1
                        ? Object.values(groupSelectOptions)[0].options
                        : groupSelectOptions
                    }
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    isOptionSelected={(option, selectValue) =>
                      selectValue.some((i) => i === option)
                    }
                    value={groupSelectOptions
                      .flatMap((group) => group.options)
                      .filter((option) =>
                        groupLabelToOptionGroup[option.type].selected.includes(
                          option.value
                        )
                      )}
                  />
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </>
        </Popover>
      </Box>
      {pills}
    </>
  );
};

// Specific selectors

export const AudienceSelector = ({
  audience,
  setAudience,
}: {
  audience?: BusinessAudience[];
  setAudience: (options: BusinessAudience[]) => void;
}) => {
  const options: {[audience in BusinessAudience]?: string} = {
    b2b: 'B2B',
    b2c: 'B2C',
  } as const;

  const menuItems = _.map(options, (label, key) => (
    <MenuItemOption value={key} key={key}>
      {label}
    </MenuItemOption>
  ));

  const optionText = audience
    ?.map((audience) => BusinessAudienceLabels[audience])
    .join(' or ');

  return (
    <>
      <Menu>
        <PillButton placeholder="B2B or B2C" value={optionText} />
        <MenuList color="#0A0A0A" fontSize={16}>
          <MenuOptionGroup
            onChange={(value) =>
              setAudience([value].flat() as BusinessAudience[])
            }
            type="checkbox"
            value={audience}
          >
            {menuItems}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </>
  );
};

export const EmployeeCountSelector = ({
  min,
  max,
  setMin,
  setMax,
}: {
  min?: number;
  max?: number;
  setMin: (count: number | undefined) => void;
  setMax: (count: number | undefined) => void;
}) => {
  return (
    <>
      <PillTextField
        placeholder="minimum"
        value={min ?? ''}
        onChange={(e) => {
          let min: number | undefined = parseInt(e.target.value);
          if (isNaN(min)) {
            min = undefined;
          }
          setMin(min);
        }}
      />
      <Box mr={4}>to</Box>
      <PillTextField
        placeholder="maximum"
        value={max ?? ''}
        onChange={(e) => {
          let max: number | undefined = parseInt(e.target.value);
          if (isNaN(max)) {
            max = undefined;
          }
          setMax(max);
        }}
      />
    </>
  );
};
