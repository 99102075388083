import {useMutation} from '@tanstack/react-query';
import {UploadURLResponse} from '../../shared/api/api';
import {useKeyplayApi} from '../../context/ApiContext';

interface SignedUrlMutationProps {
  selectedFile: File | null;
  onSuccess: (data: UploadURLResponse) => void;
  onError: (error: unknown) => void;
}

export const useSignedUrlMutation = ({
  selectedFile,
  onSuccess,
  onError,
}: SignedUrlMutationProps) => {
  const makeApiCall = useKeyplayApi();

  return useMutation<UploadURLResponse, unknown, void>({
    mutationFn: () => {
      if (!selectedFile) {
        throw new Error('No file selected');
      }

      return makeApiCall('/uploadUrl', {
        method: 'POST',
        data: {
          contentSize: selectedFile.size,
        },
      });
    },
    onSuccess,
    onError,
  });
};
