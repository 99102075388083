import React from 'react';
import {Heading, Text} from '@chakra-ui/react';
import {ExportModalSection} from './ExportModalSection';
import {UseMutationResult} from '@tanstack/react-query';
import {ExportResponse, ExportRequest} from '../../../shared/api/api';

interface MyAccountsExportProps {
  numberOfAccounts?: number;
  exportMutation: UseMutationResult<ExportResponse, unknown, ExportRequest>;
}

export const MyAccountsExport = ({
  exportMutation,
  numberOfAccounts,
}: MyAccountsExportProps) => {
  return (
    <>
      <ExportModalSection
        buttonText="Export CSV"
        type="filtered_accounts"
        exportMutation={exportMutation}
      >
        <Heading fontSize="lg" fontWeight="bold" mb={2}>
          Accounts in Current View
        </Heading>
        <Text>
          {Number(numberOfAccounts || 0).toLocaleString()} accounts matching
          your current filters, keyed by <Text as="u">domain</Text>.
        </Text>
      </ExportModalSection>
      <ExportModalSection
        buttonText="Export CSV"
        type="all_accounts"
        exportMutation={exportMutation}
      >
        <Heading fontSize="lg" fontWeight="bold" mb={2}>
          All Accounts
        </Heading>
        <Text>
          All accounts, keyed by <Text as="u">domain</Text>.
        </Text>
      </ExportModalSection>
      <ExportModalSection
        buttonText="Export CSV"
        type="crm_accounts"
        exportMutation={exportMutation}
      >
        <Heading fontSize="lg" fontWeight="bold" mb={2}>
          CRM Enrichment
        </Heading>
        <Text>
          All accounts, keyed by associated <Text as="u">CRM ID</Text>.
        </Text>
      </ExportModalSection>
    </>
  );
};
