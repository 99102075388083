import {z} from 'zod';
import {zodTypeguard} from './helpers';

const ErrorCodeSchema = z.enum([
  'api', // Corresponds to known errors and 400 status codes
  'token', // Used for when the request fails due to an auth failure
  'unknown', // Corresponds to uncaught errors
]);
export type ErrorCode = z.infer<typeof ErrorCodeSchema>;

const KeyplayErrorSchema = z.object({
  error: z.object({
    message: z.string(),
    code: ErrorCodeSchema,
  }),
});
export const isKeyplayError = zodTypeguard(KeyplayErrorSchema);
export type KeyplayError = z.infer<typeof KeyplayErrorSchema>;
