import {LogoLinkedin, Link as HttpLink, Close} from '@carbon/icons-react';
import {
  Box,
  BoxProps,
  CircularProgress,
  CircularProgressLabel,
  CircularProgressProps,
  Flex,
  HStack,
  Image,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
  VStack,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';
import {ScoringSignal} from '../../shared/signals';
import {SimpleSignal} from '../signals';
import {useMetadata} from '../../context/MetadataContext';
import {
  BusinessAudience,
  BusinessAudienceLabels,
  PrimaryBusinessCategory,
  SecondaryBusinessCategory,
  SecondaryBusinessCategoryLabels,
} from '../../shared/account';
import {PrimaryBusinessCategoryLabels} from '../../shared/account';
import {HubSpot} from '../Icons/HubSpot';
import {Salesforce} from '../Icons/Salesforce';
import {values} from 'lodash';
import {useIntegrations} from '../../hooks/api/integrations';
import {useScoringRun} from '../../context/ScoringRunContext';
import {ObjectId} from 'bson';
import {entries} from '../../shared/util';

/*
 * A collection of tiny components - mostly ones used by the cells of the grid
 * (e.g. rendering the linkedin link, or the signal chips)
 *
 * Note: there might be a slightly cleaner way of writing the memo declarations than
 * my _ method, you can switch to it if you find it
 */

// renders the name/url combination in the grid
export const _NameRenderer = ({
  name,
  domain,
  liUrl,
  inCrm,
  showCrmStatus,
}: {
  name: string;
  domain: string;
  liUrl: string;
  inCrm: boolean;
  showCrmStatus: boolean;
}) => {
  // 1. Only fetch integrations if we're showing the CRM status
  // 2. Don't fetch on mount, since we're rendering this for every row
  const {data} = useIntegrations({
    enabled: showCrmStatus,
    refetchOnMount: false,
  });
  const integrationType = values(data ?? {})
    .map((i) => i.type)
    .at(0);

  return (
    <>
      <HStack pb="1px" spacing={1} wrap="wrap">
        <Box me={1}>{(name || '').trim()}</Box>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={`https://${domain}`}
          _hover={{color: '#E61957'}}
          onClick={(e) => e.stopPropagation()}
        >
          <HttpLink />
        </Link>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={liUrl}
          _hover={{color: 'kblue.400'}}
          onClick={(e) => e.stopPropagation()}
        >
          <LogoLinkedin />
        </Link>
        {showCrmStatus && (
          <Tooltip label={inCrm ? 'Synced' : 'Not Synced'} placement="top">
            {integrationType === 'salesforce' ? (
              <Salesforce color={inCrm ? '#00a1e0' : 'kgray.300'} />
            ) : integrationType === 'hubspot' ? (
              <HubSpot color={inCrm ? '#ff7a59' : 'kgray.300'} />
            ) : (
              <></>
            )}
          </Tooltip>
        )}
      </HStack>

      <Box color="kgray.300" fontSize={12}>
        {domain}
      </Box>
    </>
  );
};
export const NameRenderer = React.memo(_NameRenderer);

interface FitRendererProps extends CircularProgressProps {
  value: number;
}

// renders the circular gauge for overall fit
const _FitRenderer = forwardRef(({value, ...props}: FitRendererProps, ref) => (
  <CircularProgress
    value={Math.max(0, value)}
    color="kgreen.400"
    ref={ref}
    {...props}
  >
    <CircularProgressLabel>{Math.ceil(value)}</CircularProgressLabel>
  </CircularProgress>
));
export const FitRenderer = React.memo(_FitRenderer);

type FitRendererWithPopoverProps = {
  mostSimilarTo?: {accountId: ObjectId; score: number};
  signalScore: number;
  value: number;
};

const _FitRendererWithPopover = ({
  mostSimilarTo,
  signalScore,
  value,
}: FitRendererWithPopoverProps) => {
  const scoringRun = useScoringRun();
  const similarityScore = mostSimilarTo?.score ?? 0;
  const sections = [
    {header: 'Signal Score', value: Math.ceil(signalScore)},
    {header: 'Similarity Score', value: Math.ceil(similarityScore)},
  ];

  const lookalike = mostSimilarTo
    ? scoringRun?.lookalikesWithLogos?.find((account) =>
        account._id.equals(mostSimilarTo.accountId)
      )
    : null;

  return (
    <Popover isLazy={true} placement="end" trigger="hover">
      <PopoverTrigger>
        <FitRenderer value={value} />
      </PopoverTrigger>
      <PopoverContent p={2}>
        <PopoverArrow />
        <PopoverHeader border={0} fontWeight={500}>
          Fit Score Details
        </PopoverHeader>
        <PopoverBody>
          <Flex direction={'column'} gap={4}>
            {sections.map((section, idx) => (
              <VStack align="start" key={idx} spacing={'0'}>
                <Box
                  color="kgray.300"
                  fontSize={'xs'}
                  textTransform="uppercase"
                >
                  {section.header}
                </Box>
                <Box fontSize={'sm'}>{section.value}</Box>
              </VStack>
            ))}
            {lookalike && (
              <VStack align={'start'}>
                <Box
                  color={'kgray.300'}
                  fontSize={'xs'}
                  textTransform={'uppercase'}
                >
                  Most Similar To
                </Box>
                <Flex direction="column" gap={1}>
                  <HStack>
                    <Image
                      src={lookalike.logoUrl}
                      h={'6'}
                      w={'6'}
                      fallbackSrc="/accountFallback.png"
                    />
                    <Box>{lookalike.name}</Box>
                  </HStack>
                </Flex>
              </VStack>
            )}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export const FitRendererWithPopover = React.memo(_FitRendererWithPopover);

// renders a list of signals as tags (with wrapping)
const _SignalsRenderer = ({signals}: {signals: ScoringSignal[]}) => {
  return (
    <Flex wrap="wrap" gap={2}>
      {signals.map((signal) => (
        <SimpleSignal signal={signal} key={signal} />
      ))}
    </Flex>
  );
};
export const SignalsRenderer = React.memo(_SignalsRenderer);

interface CategoryProps extends BoxProps {
  label: string;
}

const Category = ({label, ...props}: CategoryProps) => (
  <Box
    bg={'kred.50'}
    color="kred.400"
    alignItems="center"
    fontSize="12px"
    px={2}
    py={1}
    borderRadius="40px"
    whiteSpace="nowrap"
    {...props}
  >
    {label}
  </Box>
);

export const PrimaryCategoriesRenderer = ({
  categories,
}: {
  categories: PrimaryBusinessCategory[];
}) => {
  return (
    <CategoriesRenderer
      categories={categories.reduce(
        (acc, c) => ({...acc, [c]: PrimaryBusinessCategoryLabels[c]}),
        {}
      )}
    />
  );
};

export const SecondaryCategoriesRenderer = ({
  categories,
}: {
  categories: SecondaryBusinessCategory[];
}) => {
  return (
    <CategoriesRenderer
      categories={categories.reduce(
        (acc, c) => ({...acc, [c]: SecondaryBusinessCategoryLabels[c]}),
        {}
      )}
    />
  );
};

export const AudienceRenderer = ({
  audiences,
}: {
  audiences: BusinessAudience[];
}) => {
  return (
    <CategoriesRenderer
      categories={audiences.reduce(
        (acc, c) => ({...acc, [c]: BusinessAudienceLabels[c]}),
        {}
      )}
    />
  );
};

// Helper component for rendering categories and audiences.
const CategoriesRenderer = ({
  categories,
}: {
  categories: Record<string, string>;
}) => {
  return (
    <Flex wrap="wrap" gap={2}>
      {entries(categories).map(([category, label]) => (
        <Category label={label} key={category} />
      ))}
    </Flex>
  );
};

interface TagsRendererProps {
  deletable?: boolean;
  disabled?: boolean;
  onDeleteTag?: ({
    tagsToRemove,
    remainingTags,
  }: {
    tagsToRemove: ObjectId[];
    remainingTags: ObjectId[];
  }) => void;
  tagIds: ObjectId[];
}

export const TagsRenderer = ({
  deletable,
  disabled,
  onDeleteTag,
  tagIds,
}: TagsRendererProps) => {
  const metadata = useMetadata();
  const allTags = metadata.tags;
  const tags = allTags ? tagIds.map((tagId) => allTags[tagId.toString()]) : [];

  const handleOnTagDelete = (tagToRemoveId: ObjectId) => {
    const remainingTags = tagIds.filter((tag) => !tag.equals(tagToRemoveId));
    onDeleteTag?.({tagsToRemove: [tagToRemoveId], remainingTags});
  };

  return (
    <Flex wrap="wrap" gap={2}>
      {tags.map((tag) => {
        if (!tag) return <></>;

        return (
          <Flex
            alignItems="center"
            backgroundColor={'kgray.200'}
            borderRadius={40}
            color={disabled ? 'kgray.300' : 'kgray.400'}
            fontSize={12}
            key={tag._id.toString()}
            px={2}
            py={1}
            whiteSpace="nowrap"
          >
            <Box>{tag.name}</Box>
            {deletable && (
              <Box
                as={Close}
                color={'kgray.300'}
                cursor={deletable ? 'pointer' : ''}
                borderRadius={4}
                onClick={() => !disabled && handleOnTagDelete(tag._id)}
                _hover={{color: 'kgray.400'}}
              />
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};
