import {useIsFreeOrListBuilderCustomer} from '../../hooks/api/metadata';

type OnboardingStep = {
  name: string;
  route: string;
  sidebar?: {
    image: string;
    text: string;
  };
};

export const useOnboardingSteps = () => {
  const listBuilderOnboardingSteps: OnboardingStep[] = [
    {
      name: 'Get Started',
      route: '',
    },
    {
      name: 'SAM Set',
      route: 'filters',
      sidebar: {
        image: '/Keyplay_Find.svg',
        text: 'Your Serviceable Market definition will help you find accounts.',
      },
    },
    {
      name: 'Lookalikes Set',
      route: 'lookalikes',
      sidebar: {
        image: '/Keyplay_Score.svg',
        text: 'Your Lookalike list will help you score ICP accounts.',
      },
    },
    {
      name: 'Template Set',
      route: 'template',
    },
    {
      name: 'Role Set',
      route: 'role',
    },
  ];

  const paidOnboardingSteps: OnboardingStep[] = [
    {
      name: 'Get Started',
      route: '',
    },
    {
      name: 'SAM Set',
      route: 'filters',
      sidebar: {
        image: '/Keyplay_Find.svg',
        text: 'Your Serviceable Market definition will help you find accounts.',
      },
    },
    {
      name: 'Lookalikes Set',
      route: 'lookalikes',
      sidebar: {
        image: '/Keyplay_Score.svg',
        text: 'Your Lookalike list will help you score ICP accounts.',
      },
    },
    {
      name: 'Role Set',
      route: 'role',
    },
  ];

  return useIsFreeOrListBuilderCustomer()
    ? listBuilderOnboardingSteps
    : paidOnboardingSteps;
};
