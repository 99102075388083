import React from 'react';
import {Alert, AlertIcon, Box, Flex, Grid, Link, Toast} from '@chakra-ui/react';
import {useIsFreemiumCustomer} from '../../../hooks/api/metadata';
import {SignalsBank} from '../ModelBuilder/SignalsBank';
import {useScoringModelSignalStore} from '../ModelBuilder/SignalAssignmentStore';
import {ScrollableFlex, WrapperFlex} from '../../utils/scrolling';
import {SignalsBucket} from '../ModelBuilder/SignalsBucket';

export const Signals = () => {
  const isFreemiumCustomer = useIsFreemiumCustomer();
  const {usingOutOfDateServerModel} = useScoringModelSignalStore();

  return (
    <WrapperFlex>
      {isFreemiumCustomer && (
        <Box>
          <Alert status="warning">
            <AlertIcon />
            <Box>
              Looking for other signals? We can track any clues from websites,
              jobs posts, and more.
              <Link
                id="intercom-request-signal"
                color="kblue.400"
                href="mailto:hello@keyplay.io?subject=New Signal Request"
                onClick={(e) => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  if ((window as any).Intercom) {
                    // If Intercom has been loaded on the page, then we will
                    // launch the messenger widget when the user clicks on the
                    // link but not navigate to the link.
                    e.preventDefault();
                  }
                }}
                ms={2}
                target="_blank"
              >
                Request a signal
              </Link>
              .
            </Box>
          </Alert>
        </Box>
      )}
      <ScrollableFlex p="0">
        <Grid h="100%" textAlign="left" templateRows="1fr" position="relative">
          <SignalsScreen />
          {usingOutOfDateServerModel() && (
            <Box
              position="absolute"
              bottom="20px"
              zIndex={50}
              left="50%"
              transform="translateX(-50%)"
            >
              <Toast
                status="warning"
                description="There is a newer scoring model on the server. Refresh to see the latest model."
              />
            </Box>
          )}
        </Grid>
      </ScrollableFlex>
    </WrapperFlex>
  );
};

const SignalsScreen = () => {
  return (
    <Grid p={6} templateColumns="4fr 6fr" overflowY="hidden">
      <SignalsBank />
      <Flex direction="column" position="relative" pl="4" overflowY="auto">
        <SignalsBucket bucketName="positive" title="POSITIVE SIGNALS" />
        <SignalsBucket bucketName="negative" title="NEGATIVE SIGNALS" />
      </Flex>
    </Grid>
  );
};
