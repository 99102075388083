import {useAuth0} from '@auth0/auth0-react';
import {useMutation} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import * as Sentry from '@sentry/react';

/**
 * This will clear the logged-in state (if it exists) that we maintain in Auth0 for a user.
 * This is only relevent for users that belong to more than one customer.
 *
 * TODO: Can this be avoided for users that belong to only one customer?
 */
export function useLogout() {
  const makeApiCall = useKeyplayApi();
  const {logout} = useAuth0();

  return useMutation({
    mutationFn: () => makeApiCall('/customer/logout', {method: 'POST'}),
    onSettled: () => {
      Sentry.setUser(null);
      logout({returnTo: window.location.origin});
    },
  });
}
