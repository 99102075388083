import React from 'react';
import {Flex} from '@chakra-ui/react';
import {ChakraSearch} from './Icons/Carbon';

export const SearchDropdownIndicator = () => (
  <Flex
    align="center"
    color="kgray.300"
    height="24px"
    width="24px"
    ml="4"
    mr="0"
  >
    <ChakraSearch />
  </Flex>
);
