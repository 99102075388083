import React, {useState} from 'react';
import {
  Button,
  FormControl,
  HStack,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import {useClayExportMutation} from '../../../hooks/api/clayExport';
import {useAccountQueryParams} from '../AccountGrid.controlstate';
import {ClayExportRegex} from '../../../shared/exports';
import {pluralize} from '../../../lib/helpers';

interface ClayExportModalProps {
  isOpen: boolean;
  onClose: () => void;
  numberOfAccounts: number;
}

export const ClayExportModal = ({
  isOpen,
  onClose,
  numberOfAccounts,
}: ClayExportModalProps) => {
  const clayExportMutation = useClayExportMutation();
  const query = useAccountQueryParams();
  const [webhookUrl, setWebhookUrl] = useState('');

  const isValidWebhook = ClayExportRegex.test(webhookUrl);

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="600" p={4}>
        <ModalHeader textAlign="center" fontSize="xl">
          Export to Clay
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack mb={4} mt={2}>
            <Text mb={4}>
              Send {pluralize(numberOfAccounts, 'account', ' accounts')}{' '}
              matching your current filters to Clay.
            </Text>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                clayExportMutation.mutate(
                  {
                    webhookUrl,
                    query,
                  },
                  {
                    onSuccess: onClose,
                  }
                );
              }}
            >
              <HStack mb={6} spacing={0}>
                <FormControl>
                  <Input
                    placeholder="paste your webhook url here"
                    width="300px"
                    onChange={(event) =>
                      setWebhookUrl(event.target.value.trim())
                    }
                  />
                </FormControl>
                <Tooltip
                  label="Please enter a valid webhook url"
                  isDisabled={isValidWebhook}
                >
                  <Button
                    type="submit"
                    colorScheme="kbuttonblue"
                    isDisabled={!isValidWebhook}
                  >
                    Go
                  </Button>
                </Tooltip>
              </HStack>
            </form>
            <Text>Exports can take a few minutes.</Text>
            <Text lineHeight={1}>
              <Link
                color="kblue.500"
                isExternal={true}
                href="https://docs.clay.com/enrichments-and-providers/sources/import-data-from-webhook"
              >
                Click here
              </Link>{' '}
              for help finding your webhook url.
            </Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
