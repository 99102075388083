import React from 'react';
import {Button, ButtonProps, forwardRef} from '@chakra-ui/react';

export type HeaderButtonProps = Omit<
  ButtonProps,
  'color' | 'fontSize' | 'fontWeight'
>;
export const HeaderButton = forwardRef<HeaderButtonProps, 'button'>(
  (props: HeaderButtonProps, ref) => {
    const {children, ...rest} = props;
    return (
      <Button
        ref={ref}
        variant="ghost"
        color="kgray.400"
        fontSize="14"
        fontWeight="500"
        {...rest}
      >
        {children}
      </Button>
    );
  }
);
