import React from 'react';
import {Box, Flex, Grid, Switch, Text, Tooltip} from '@chakra-ui/react';
import {IntegrationType} from '../../shared/integrations';
import {
  EnrichmentFieldLabels,
  ManagedEnrichmentField,
  ManagedEnrichmentFieldConfig,
  ManagedEnrichmentFieldCrmLabels,
  ManagedEnrichmentFieldCrmNames,
} from '../../shared/integrations';
import {Integration} from './AllIntegrations';
import {
  GridCell,
  GridHeader,
  IntegrationSection,
  gridTemplateColumns,
} from './components';
import {ChevronRight} from '@carbon/icons-react';
import {useIntegrationDetailsState} from './IntegrationDetails.state';

export const ManagedEnrichmentFieldMapping = ({
  integrationName,
  integrationInfo,
  availableEnrichmentFields,
  enrichmentFields,
}: {
  integrationName: IntegrationType;
  integrationInfo: Integration;
  availableEnrichmentFields: ManagedEnrichmentField[];
  enrichmentFields: ManagedEnrichmentFieldConfig[];
}) => {
  const {addManagedField, deleteManagedField, publishChanges} =
    useIntegrationDetailsState(integrationName);

  // Use all standard fields as the source to drive the list of field mappings to display.
  // For each one, if there exists an existing mapping, use its configuration.
  const mergedFieldConfigs: ManagedEnrichmentFieldConfig[] =
    availableEnrichmentFields.map((field) => {
      const existingField = enrichmentFields.find(
        (enrichmentField) => enrichmentField.enrichmentField === field
      );

      return {
        crmFieldName: getCrmFieldName(field, integrationName),
        enrichmentField: field,
        enrichmentFieldType: 'managed',
        kind: 'keyplay',
        enabled: !!existingField?.enabled,
      };
    });

  const onUpdateEnrichmentField = (
    fieldConfig: ManagedEnrichmentFieldConfig,
    enabled: boolean
  ) => {
    if (enabled) {
      addManagedField({
        ...fieldConfig,
        enabled,
      });
      publishChanges.mutate();
    } else {
      deleteManagedField(fieldConfig.enrichmentField);
      publishChanges.mutate();
    }
  };

  return (
    <IntegrationSection>
      <Box fontSize={'lg'} fontWeight={500} mb={6}>
        Managed Enrichment Fields
      </Box>
      <Text mb={10}>
        When enabling a managed field, Keyplay will create the field
        automatically on your {integrationInfo.label} company record within a
        “Keyplay Enrichment” field group. Values are refreshed weekly.
      </Text>

      <Grid templateColumns={gridTemplateColumns} gap={2}>
        <GridHeader></GridHeader>
        <GridHeader>Keyplay Field</GridHeader>
        <GridHeader></GridHeader>
        <GridHeader>{integrationInfo.label} Field</GridHeader>

        {mergedFieldConfigs.map((fieldConfig) => (
          <ManagedEnrichmentFieldGridRow
            key={fieldConfig.enrichmentField}
            fieldConfig={fieldConfig}
            onUpdateEnrichmentField={onUpdateEnrichmentField}
          />
        ))}
      </Grid>
    </IntegrationSection>
  );
};

const CrmFieldCell = ({name, label}: {name: string; label: string}) => {
  return (
    <Flex direction="column">
      <Tooltip label={name} placement="top">
        <Box>{label}</Box>
      </Tooltip>
    </Flex>
  );
};

const getCrmFieldName = (
  field: ManagedEnrichmentField,
  integrationName: IntegrationType
): string => {
  const fieldValue = ManagedEnrichmentFieldCrmNames[field];

  if (integrationName === 'salesforce') {
    // Convert string format for Salesforce
    // Camel case (with first work capitalized) and add __c
    const words = fieldValue.split('_');
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join('') + '__c';
  }

  // Return original string for HubSpot
  return fieldValue;
};

const ManagedEnrichmentFieldGridRow = ({
  fieldConfig,
  onUpdateEnrichmentField,
}: {
  fieldConfig: ManagedEnrichmentFieldConfig;
  onUpdateEnrichmentField: (
    enrichmentField: ManagedEnrichmentFieldConfig,
    enabled: boolean
  ) => void;
}) => {
  return (
    <>
      <GridCell>
        <Switch
          isChecked={fieldConfig.enabled}
          onChange={(event) => {
            onUpdateEnrichmentField(fieldConfig, event.target.checked);
          }}
        />
      </GridCell>
      <GridCell hasBorder>
        {EnrichmentFieldLabels[fieldConfig.enrichmentField]}
      </GridCell>
      <GridCell>
        <ChevronRight />
      </GridCell>
      <GridCell hasBorder>
        <CrmFieldCell
          name={fieldConfig.crmFieldName}
          label={ManagedEnrichmentFieldCrmLabels[fieldConfig.enrichmentField]}
        />
      </GridCell>
    </>
  );
};
