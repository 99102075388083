import React from 'react';
import {Button, Divider, Flex, Text} from '@chakra-ui/react';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {TwoColumnGrid} from './helpers';

export const AiFieldBuilderPreviewResultsStep = () => {
  const {prevStep, nextStep} = useAiFieldBuilderStore();
  return (
    <TwoColumnGrid>
      <Flex borderRight="1px solid" borderColor="kgray.200" direction="column">
        <Flex flex="1" p="6">
          <Text fontSize="xl">Write your prompt</Text>
        </Flex>

        <Divider />

        <Flex justifyContent="space-between" w="100%" px={10} py={4} gap={10}>
          <Button onClick={prevStep}>Back</Button>

          <Button colorScheme="kbuttonblue" onClick={nextStep}>
            Continue
          </Button>
        </Flex>
      </Flex>

      <Flex p="6">
        <Text fontSize="xl">Preview</Text>
      </Flex>
    </TwoColumnGrid>
  );
};
