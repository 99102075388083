import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import {AccountScorePreview} from '../ModelBuilder/AccountScorePreview';
import {ChakraView} from '../../Icons/Carbon';
import {DrawerCloser} from '../../DrawerCloser';

export const AccountScorePreviewButton = () => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} p="2" variant="outline">
        <ChakraView boxSize="25px" />
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloser onClose={onClose} />
          <DrawerHeader fontWeight="normal" fontSize="2xl">
            Preview score for...
          </DrawerHeader>
          <DrawerBody>
            <AccountScorePreview />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
