import {Tooltip, defineStyleConfig} from '@chakra-ui/react';

export const tooltipTheme = defineStyleConfig({
  baseStyle: {
    borderRadius: 'md',
    padding: 2,
  },
});

// Workaround for https://github.com/chakra-ui/chakra-ui/issues/1424
Tooltip.defaultProps = {
  ...Tooltip.defaultProps,
  hasArrow: true,
};
