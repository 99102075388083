import React, {useState} from 'react';
import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Input,
  InputLeftElement,
  InputGroup,
} from '@chakra-ui/react';
import {useDrop} from 'react-dnd';
import {Search} from '@carbon/icons-react';
import {DraggableSignal, DragItemType} from '../../signals';
import {useScoringModelSignalStore} from './SignalAssignmentStore';
import _ from 'lodash';
import {
  ScoringSignal,
  ScoringCategory,
  ScoringCategoryLabels,
  ScoringSignalToCategory,
  ScoringCategories,
} from '../../../shared/signals';
import {useCustomer} from '../../../hooks/api/metadata';
import {useScoringSignalResolver} from '../../../hooks/scoringSignal';
import {captureMessage} from '@sentry/react';

export const SignalsBank = () => {
  // drag-and-drop logic: dragging tags back to the left removes them
  const {getEverySignalBucketSorted, assignBucket} =
    useScoringModelSignalStore();
  const signalAssignments = getEverySignalBucketSorted();
  const [{canDrop, isOver}, drop] = useDrop(
    () => ({
      // accepts all categories
      accept: ScoringCategories,
      drop: (item) => assignBucket(item.signal, 'unassigned'),
      // can only drop back tags that already have a bucket
      canDrop: (item: DragItemType) => {
        return signalAssignments[item.signal] !== 'unassigned';
      },
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
    }),
    [signalAssignments, assignBucket]
  );

  // override tab styling
  // TODO: if we repeat this pattern as a component, could add it as a variant={} to Chakra
  const tabProps = {
    borderRadius: '8px',
    _selected: {bg: 'kgray.200'},
  };

  // tab per category
  const tabs: React.ReactNode[] = [],
    tabContents: React.ReactNode[] = [];
  for (const category of ScoringCategories) {
    tabs.push(
      <Tab {...tabProps} key={`tab_${category}`}>
        {ScoringCategoryLabels[category]}
      </Tab>
    );
    tabContents.push(
      <TabPanel p={0} key={`contents_${category}`} height="100%">
        <BankContents categories={category} />
      </TabPanel>
    );
  }

  return (
    <Box overflowY="hidden" position="relative">
      <Box
        pl={3}
        pt={4}
        ref={drop}
        height="100%"
        bgColor="kgray.50"
        borderColor="kgray.200"
        borderWidth="1px"
        borderRadius="lg"
      >
        {/* overlays for removing a tag */}
        <Box
          position="absolute"
          top="0"
          right="0"
          left="0"
          bottom="0"
          zIndex="2"
          display={canDrop ? 'initial' : 'none'}
          bg="#000"
          borderRadius="lg"
          opacity={isOver ? '0.4' : '0.3'}
        />
        <Box
          position="absolute"
          left="50%"
          top="50%"
          transform="translateX(-50%) translateY(-50%)"
          bg="white"
          color="inherit"
          zIndex="3"
          fontSize="lg"
          fontWeight={500}
          padding={3}
          borderRadius="4px"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="kgray.100"
          display={canDrop ? 'initial' : 'none'}
          userSelect="none"
        >
          Remove Tag
        </Box>
        <Tabs variant="solid-rounded" height="100%">
          <Flex direction="column" height="100%">
            <TabList pl={1}>
              <Tab {...tabProps} key="tab_all">
                All
              </Tab>
              {tabs}
            </TabList>
            <TabPanels flex={1} pl={1} overflowY="hidden">
              <TabPanel p={0} key="contents_all" height="100%">
                <BankContents categories={ScoringCategories} />
              </TabPanel>
              {tabContents}
            </TabPanels>
          </Flex>
        </Tabs>
      </Box>
    </Box>
  );
};

// the search bar and actual list of tags within the LHS tab
// only shows unassigned tags
const BankContents = ({
  categories,
}: {
  categories: ScoringCategory | ScoringCategory[];
}) => {
  if (!Array.isArray(categories)) {
    categories = [categories];
  }

  const customer = useCustomer();
  const scoringSignalResolver = useScoringSignalResolver();

  const [searchText, setSearchText] = useState('');

  const {getEverySignalBucketSorted} = useScoringModelSignalStore();
  const signalAssignments = getEverySignalBucketSorted();

  const signalsToRender = categories
    .map((category) => {
      const globalSignals = _.keys(
        _.pickBy(ScoringSignalToCategory, (c) => c === category)
      );
      const customSignals =
        customer.signalDefinitions
          ?.filter((signalDefinition) => signalDefinition.category === category)
          .map((signalDefinition) => signalDefinition.id) ?? [];

      return customSignals.concat(globalSignals);
    })
    .flat();

  const draggables: Array<React.ReactNode> = [];
  for (const signal of signalsToRender) {
    const signalDetails = scoringSignalResolver(signal);
    if (!signalDetails) {
      captureMessage(`unknown signal ${signal} in bank`, 'error');
      continue;
    }

    if (signalAssignments[signal as ScoringSignal] === 'unassigned') {
      const isVisible = signalDetails.label
        .toLowerCase()
        .includes(searchText.toLowerCase());
      draggables.push(
        <DraggableSignal signal={signal} key={signal} hide={!isVisible} />
      );
    }
  }

  return (
    <Flex direction="column" height="100%">
      <InputGroup mt={4} pr={4}>
        <InputLeftElement pointerEvents="none">
          <Search color="#76767E" />
        </InputLeftElement>
        <Input
          bg={'kgray.100'}
          borderWidth={0}
          color="kgray.300"
          value={searchText}
          placeholder="Search Signals"
          fontWeight="thin"
          onChange={(e) => setSearchText(e.target.value)}
        />
      </InputGroup>
      <Flex
        pr={1}
        pb={4}
        mt={8}
        wrap="wrap"
        alignContent="flex-start"
        gap={2}
        overflowY="auto"
        flex={1}
      >
        {draggables}
      </Flex>
    </Flex>
  );
};
