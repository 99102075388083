import {ObjectId} from 'bson';
import {z} from 'zod';

const BaseModelTestGroupSchema = z.object({
  timestamp: z.date(),
  id: z.instanceof(ObjectId),
  label: z.string(),
  order: z.number(),
  status: z.string(),
  numUploadedRows: z.number(),
});

const PendingModelTestGroupSchema = BaseModelTestGroupSchema.extend({
  status: z.literal('pending'),
});
export type PendingModelTestGroup = z.infer<typeof PendingModelTestGroupSchema>;

const ProcessedModelTestGroupSchema = BaseModelTestGroupSchema.extend({
  numAccounts: z.number(),
  status: z.literal('processed'),
});

const ModelTestGroupSchema = z.union([
  PendingModelTestGroupSchema,
  ProcessedModelTestGroupSchema,
]);
export type ModelTestGroup = z.infer<typeof ModelTestGroupSchema>;

export const ModelTestDefinitionSchema = z.object({
  timestamp: z.date(),
  customerId: z.instanceof(ObjectId),
  marketId: z.instanceof(ObjectId),
  groups: z.array(ModelTestGroupSchema),
});
export type ModelTestDefinition = z.infer<typeof ModelTestDefinitionSchema>;

const ModelTestAccountSchema = z.object({
  modelTestId: z.instanceof(ObjectId),
  groupId: z.instanceof(ObjectId),
  accountId: z.instanceof(ObjectId),
});
export type ModelTestAccount = z.infer<typeof ModelTestAccountSchema>;
