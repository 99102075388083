import React from 'react';
import dotBg from '../ModelBuilder/assets/dotBg.png';
import {Navigate, Route, Routes} from 'react-router';
import {Flex} from '@chakra-ui/react';
import {useMarketBuilderTabs} from './MarketBuilderTabs';

export const MarketBuilderContent = () => {
  const tabs = useMarketBuilderTabs();

  return (
    <Flex
      bgColor="kgray.100"
      flex="1"
      position="relative"
      _before={{
        content: '""',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundImage: dotBg,
        backgroundRepeat: 'repeat',
        position: 'absolute',
        opacity: 0.3,
      }}
    >
      <Flex h="100%" justifyContent="center" w="100%" zIndex="1">
        <Routes>
          {tabs.map(({path, element}) => (
            <Route key={path} path={path} element={element} />
          ))}
          <Route
            path="*"
            element={<Navigate to={tabs[0].path} replace={true} />}
          />
        </Routes>
      </Flex>
    </Flex>
  );
};
