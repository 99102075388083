import {AbsoluteCenter, Flex, Image} from '@chakra-ui/react';
import React from 'react';
import styled from '@emotion/styled';

/*
 * I'm just directly using emotion here (the css library underneath chakra),
 * because these loading indicators are pure css (you can find more of these
 * online), and you can just substitute/replace this by copy/pasting
 */
const Loader = styled.span`
  position: absolute;
  top: 45%;

  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #d23559;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingScreen = () => {
  return (
    <>
      <Flex align="center" bgColor="kgray.400" h="50" px="4">
        <Image height="30px" src="/Keyplay_Primary_Logo_WhiteText.svg" />
      </Flex>
      <AbsoluteCenter axis="both">
        <Loader />
      </AbsoluteCenter>
    </>
  );
};
