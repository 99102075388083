import React from 'react';
import {Box, Button, Center, Divider, Flex, Text} from '@chakra-ui/react';
import {ScrollableFlex, WrapperFlex} from '../../utils/scrolling';
import {SamEditor} from '../../SamEditor/SamEditor';
import {useIsFreemiumCustomer} from '../../../hooks/api/metadata';
import {SamGuidanceMessage} from '../../SamEditor/SamGuidanceMessage';

export const ServiceableMarket = () => {
  const isFreemiumCustomer = useIsFreemiumCustomer();

  return (
    <WrapperFlex>
      <ScrollableFlex p={0}>
        <Center flexDirection="column" gap="4">
          <Flex
            bgColor="kgray.50"
            borderColor="kgray.200"
            borderWidth="1px"
            borderRadius="lg"
            direction="column"
            gap="6"
            my="6"
            px="6"
            py="8"
            minWidth="800px"
            width="50%"
          >
            <Text fontSize="2xl">Who do you sell to?</Text>
            <Divider />
            <SamEditor />
            <Flex direction="column" gap="4">
              <SamGuidanceMessage
                borderRadius={5}
                defaultTransparentBg={false}
                flexGrow="1"
              />
            </Flex>
          </Flex>
          {isFreemiumCustomer && (
            <Flex
              alignItems="center"
              direction="column"
              gap="4"
              textAlign="center"
              width="100%"
              maxW="lg"
            >
              <Box fontSize="lg" fontWeight="500">
                Looking for advanced filtering & custom signals?
              </Box>
              <Box mb="4">
                Explore our paid plans to filter by technologies used, hiring,
                growth, funding, relevance, and your own custom signals.
              </Box>
              <Button
                as="a"
                href="https://form.typeform.com/to/pdm76xLO?utm_content=freemium&typeform-source=keyplay_app"
                target="_blank"
              >
                Get a Demo
              </Button>
            </Flex>
          )}
        </Center>
      </ScrollableFlex>
    </WrapperFlex>
  );
};
