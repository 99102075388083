import {StylesConfig} from 'react-select';

export function defaultSelectStyles<
  Option,
  IsMulti extends boolean,
>(): StylesConfig<Option, IsMulti> {
  return {
    container: (base) => ({
      ...base,
      flexGrow: 1,
    }),
    control: (base) => ({
      ...base,
      border: '1px solid #EBE8EA',
      borderRadius: '0.375rem', // corresponds to Chakra medium
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#EDF2FD' : '',
      color: 'inherit',
      ':hover': {
        backgroundColor: '#EDF2FD',
      },
    }),
  };
}
