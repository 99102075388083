import React, {useState} from 'react';
import {
  Box,
  Button,
  PlacementWithLogical,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import _ from 'lodash';
import {AccountActionName} from '../../shared/api/api';
import {useIsMyAccountsView, useUserControls} from './AccountGrid.controlstate';
import {AccountTierOverride} from '../../shared/scoredAccounts';
import {
  useCustomer,
  useIsFreeOrListBuilderCustomer,
} from '../../hooks/api/metadata';
import {getSavesQuota} from '../../shared/customer';

export const useActions = (): (AccountActionName | 'addCredits')[] => {
  const customer = useCustomer();
  const isMyAccountsView = useIsMyAccountsView();
  const {remaining} = getSavesQuota(customer);
  const isFreeOrListBuilderCustomer = useIsFreeOrListBuilderCustomer();
  const {excluded, disqualified} = useUserControls();

  if (isFreeOrListBuilderCustomer) {
    if (isMyAccountsView) {
      return ['tag'];
    }

    return remaining <= 0 ? ['addCredits'] : ['tag'];
  }

  if (isMyAccountsView) {
    return excluded ? ['reactivate'] : ['tag', 'exclude', 'override'];
  } else {
    return disqualified ? ['tag'] : ['tag', 'disqualify'];
  }
};

interface TierOverridePopoutProps {
  placement?: PlacementWithLogical;
  bulkNumberSelected?: number | undefined;
  children: React.ReactNode;
  onClick: (override: AccountTierOverride) => void;
}

// Provide the trigger button as a child
export const TierOverridePopout = ({
  placement = 'bottom-start',
  bulkNumberSelected = undefined,
  children,
  onClick,
}: TierOverridePopoutProps) => {
  const [tier, setTier] = useState<AccountTierOverride | undefined>();
  return (
    <Popover placement={placement}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent fontSize={14} width="max-content">
        <PopoverBody p={4}>
          <Box
            fontFamily="mono"
            fontWeight={500}
            fontSize="12px"
            textTransform="uppercase"
            mb={2}
          >
            {bulkNumberSelected !== undefined
              ? `Override ${bulkNumberSelected} account${
                  bulkNumberSelected !== 1 ? 's' : ''
                } to:`
              : 'Override account to:'}
          </Box>
          <RadioGroup
            onChange={(v: string) => setTier(v as AccountTierOverride)}
            value={tier}
          >
            <Stack direction="column" spacing={4} my={6}>
              <Radio value="A">A</Radio>
              <Radio value="B">B</Radio>
              <Radio value="C">C</Radio>
              <Radio value="D">D</Radio>
              <Radio value="none">None</Radio>
            </Stack>
          </RadioGroup>
          <Button
            colorScheme="kbuttonblue"
            isDisabled={tier === undefined}
            onClick={() => tier && onClick(tier)}
            width="100%"
          >
            Apply
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
