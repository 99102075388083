import {MetadataResponse} from './api/api';

// The number of months that a List Builder plan lasts for before it gets reset to freemium.
export const ListBuilderPlanDurationMonths = 6;
export const ListBuilderTrialPlanDurationMonths = 1;

export const ListBuilderActiveAccountsLimit = 25_000;

export function canPurchaseCredits({
  customer,
  additionalCredits,
  activeAccounts,
}: {
  customer: MetadataResponse['customer'];
  additionalCredits: number;
  activeAccounts: number;
}) {
  if (customer.plan.type !== 'free' && customer.plan.type !== 'listBuilder') {
    return false;
  }

  return activeAccounts + additionalCredits <= ListBuilderActiveAccountsLimit;
}
