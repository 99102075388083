import React from 'react';
import {Icon, IconProps, forwardRef} from '@chakra-ui/react';

export const Salesforce = forwardRef(({...props}: IconProps, ref) => (
  <Icon
    ref={ref}
    version="1.1"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.62 14.492c2.068-2.142 4.95-3.497 8.126-3.497 4.235 0 7.905 2.364 9.875 5.86 1.7-.763 3.595-1.182 5.6-1.182 7.634 0 13.8 6.23 13.8 13.913S57.82 43.5 50.2 43.5a13.84 13.84 0 0 1-2.709-.271c-1.724 3.078-5.023 5.17-8.816 5.17-1.576 0-3.078-.37-4.408-1-1.748 4.112-5.836 7.018-10.59 7.018-4.95 0-9.185-3.127-10.8-7.535a10.85 10.85 0 0 1-2.192.222C4.777 47.095 0 42.27 0 36.285a10.82 10.82 0 0 1 5.344-9.357c-.665-1.502-1.034-3.177-1.034-4.95C4.285 15.157 9.875 9.59 16.72 9.59a12.41 12.41 0 0 1 9.899 4.9"
      fill="currentColor"
    />
  </Icon>
));
