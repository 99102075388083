import {defineStyle, defineStyleConfig} from '@chakra-ui/react';

const glow = defineStyle((props) => ({
  border: '1px solid',
  borderColor: `${props.colorScheme}.200`,
  color: 'inherit',
  fontWeight: 'normal',
  boxShadow: `0 0 5px var(--chakra-colors-${props.colorScheme}-200)`,

  _active: {
    bg: `${props.colorScheme}.100`,
  },
  _hover: {
    bg: `${props.colorScheme}.50`,
  },
  _disabled: {
    border: '1px solid transparent',
  },
}));

const light = defineStyle((props) => ({
  bgColor: `${props.colorScheme}.50`,
  color: 'inherit',
  fontWeight: 'normal',
  textColor: `${props.colorScheme}.400`,

  _active: {
    bg: `${props.colorScheme}.100`,
  },
  _hover: {
    bg: `${props.colorScheme}.100`,
    _disabled: {
      bg: `${props.colorScheme}.50`,
    },
  },
  _disabled: {
    bg: `${props.colorScheme}.50`,
  },
}));

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 'normal',
  },
  variants: {glow, light},
});
