import React from 'react';
import {Text, TextProps} from '@chakra-ui/react';

export const Bold = ({
  children,
  ...props
}: React.PropsWithChildren<TextProps>) => (
  <Text as="b" {...props}>
    {children}
  </Text>
);

export const Underline = ({
  children,
  ...props
}: React.PropsWithChildren<TextProps>) => (
  <Text as="u" {...props}>
    {children}
  </Text>
);
