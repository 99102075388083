import React, {useEffect} from 'react';
import {
  Box,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import {Link as ReactRouterLink} from 'react-router-dom';
import {useSavedAccountsToast} from './AccountsUpdatedToast';
import {
  SavedAccountsContext,
  useSavedAccountsStore,
} from './useSavedAccountsStore';
import {assertNever} from '../../shared/util';

let previousSavedAccountsContext: SavedAccountsContext | null = null;

export const SavedAccountsModalOrToast = () => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {savedAccountsContext} = useSavedAccountsStore();
  const savedAccountsToast = useSavedAccountsToast();

  useEffect(() => {
    // Don't show a notification if the saved accounts context hasn't changed
    if (
      !savedAccountsContext ||
      previousSavedAccountsContext === savedAccountsContext
    ) {
      return;
    }
    previousSavedAccountsContext = savedAccountsContext;

    switch (savedAccountsContext.notificationType) {
      case 'modal':
        onOpen();
        break;
      case 'toast':
        savedAccountsToast(savedAccountsContext);
        break;
      default:
        assertNever(savedAccountsContext.notificationType);
    }
  }, [onOpen, savedAccountsContext, savedAccountsToast]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="normal" textAlign="center">
          Accounts Saved 🎉
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex alignItems="center" direction="column" gap="4" mb="4">
            <Link
              as={ReactRouterLink}
              color="kblue.300"
              fontWeight="bold"
              to="/accounts/saved"
            >
              View & export in My Accounts 👉
            </Link>
            <Box>Or continue saving accounts in Discover</Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
