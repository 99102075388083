import {AccountFilter} from './api';
import {ZodType} from 'zod';

// Helper that gets filter for all accounts that belong in certain
// pre-canned views.
export type AccountView = 'all' | 'saved' | 'crm' | 'imported' | 'recommended';

export function getFiltersForView(view: AccountView): AccountFilter {
  switch (view) {
    case 'all':
      return {
        active: true,
      };
    case 'saved':
      return {
        active: true,
        saved: true,
      };
    case 'crm':
      return {
        active: true,
        inCRM: true,
      };
    case 'imported':
      return {
        active: true,
        imported: true,
      };
    case 'recommended':
      return {
        active: false,
      };

    default:
      throw new Error(`unexpected view: ${view}`);
  }
}

export function isZodType<T>(zodType: ZodType<T>, obj: unknown): obj is T {
  return zodType.safeParse(obj).success;
}

export function zodTypeguard<T>(zodType: ZodType<T>) {
  return (obj: unknown): obj is T => isZodType(zodType, obj);
}
