import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {ScoringInfoResponse} from '../../shared/api/responses';
import {useCustomer} from './metadata';

export const ScoringInfoQueryKey = 'scoringInfo';

export function useScoringInfo() {
  const makeApiCall = useKeyplayApi();
  const customer = useCustomer();

  return useQuery<ScoringInfoResponse>(
    [ScoringInfoQueryKey],
    () => makeApiCall('/scoredAccounts/scoringInfo'),
    {
      refetchInterval: (data) => {
        // No need to refetch if we aren't scoring
        if (!data?.isRescoring) {
          return 0;
        }

        // For free customers, we expect scoring to happen very quickly so we can refetch often.
        return customer.plan.type === 'free' ? 1_000 : 12_000;
      },
    }
  );
}

export function useIsRescoring() {
  const {data} = useScoringInfo();
  return data?.isRescoring ?? false;
}

export function useRefreshAccounts() {
  const makeApiCall = useKeyplayApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      makeApiCall(
        '/refreshAccounts',
        {method: 'POST'},
        {
          toastOnError: true,
        }
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: [ScoringInfoQueryKey]});
    },
  });
}
