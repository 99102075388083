import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import {AllIntegrations} from './AllIntegrations';
import {useDeleteIntegration} from '../../hooks/api/integrations';
import {IntegrationType} from '../../shared/integrations';
import {useNavigate} from 'react-router';

export function DeleteIntegrationModal({
  integrationName,
  isOpen,
  onClose,
}: {
  integrationName: IntegrationType;
  isOpen: boolean;
  onClose: () => void;
}) {
  const deleteIntegration = useDeleteIntegration();
  const navigate = useNavigate();
  const toast = useToast();

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Delete {AllIntegrations[integrationName].label}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to delete this integration?
          <Alert status="warning" mt={4} alignItems="start">
            <AlertIcon />
            <AlertDescription>
              This will disable syncing and remove any enabled field mappings.
            </AlertDescription>
          </Alert>
        </ModalBody>
        <ModalFooter gap="4">
          <Button isLoading={deleteIntegration.isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            isLoading={deleteIntegration.isLoading}
            onClick={() => {
              deleteIntegration.mutate(integrationName, {
                onSuccess: () => {
                  toast({
                    title: 'The integration has been deleted.',
                    status: 'success',
                    isClosable: true,
                    duration: 2_000,
                  });
                  navigate('/settings/integrations');
                },
              });
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
