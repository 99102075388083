import {create} from 'zustand';
import {AiFieldDefinition} from '../../../shared/enrichment';
import {AccountWithLogo} from '../../../shared/scoredAccounts';

const AiFieldBuilderSteps = [
  'defineField',
  'selectAccounts',
  'previewResults',
] as const;
export type AiFieldBuilderStep = (typeof AiFieldBuilderSteps)[number];

interface AiFieldBuilderState {
  step: AiFieldBuilderStep;
  setStep: (step: AiFieldBuilderStep) => void;
  nextStep: () => void;
  prevStep: () => void;

  fieldDefinition: AiFieldDefinition | null;
  setFieldDefinition: (fieldDefinition: AiFieldDefinition | null) => void;

  selectedAccounts: AccountWithLogo[];
  addAccount: (account: AccountWithLogo) => void;
  removeAccount: (account: AccountWithLogo) => void;

  reset: () => void;
}

export const useAiFieldBuilderStore = create<AiFieldBuilderState>(
  (set, get) => ({
    step: 'defineField',
    setStep: (step) => set({step}),
    nextStep: () => {
      const currentIndex = AiFieldBuilderSteps.indexOf(get().step);
      if (currentIndex < AiFieldBuilderSteps.length - 1) {
        set({step: AiFieldBuilderSteps[currentIndex + 1]});
      }
    },
    prevStep: () => {
      const currentIndex = AiFieldBuilderSteps.indexOf(get().step);
      if (currentIndex > 0) {
        set({step: AiFieldBuilderSteps[currentIndex - 1]});
      }
    },

    fieldDefinition: null,
    setFieldDefinition: (fieldDefinition) => set({fieldDefinition}),

    selectedAccounts: [],
    addAccount: (account) =>
      set({selectedAccounts: [...get().selectedAccounts, account]}),
    removeAccount: (account) =>
      set({
        selectedAccounts: get().selectedAccounts.filter(
          (a) => !a._id.equals(account._id)
        ),
      }),

    reset: () =>
      set({step: 'defineField', fieldDefinition: null, selectedAccounts: []}),
  })
);
