import React from 'react';
import {
  Box,
  Flex,
  PopoverContent,
  PopoverArrow,
  Popover,
  PopoverHeader,
  PopoverTrigger,
  PopoverBody,
  Button,
} from '@chakra-ui/react';
import {
  BusinessAudience,
  PrimaryBusinessCategory,
  SecondaryBusinessCategory,
} from '../../shared/account';
import {
  PrimaryCategoriesRenderer,
  AudienceRenderer,
} from './AccountGrid.components';
import {CategoriesSection} from './AccountGrid.details';
import {ChakraInformation} from '../Icons/Carbon';

const _CategoriesAndAudienceRenderer = ({
  audiences,
  primaryCategories,
  secondaryCategories,
  onViewDetails,
}: {
  audiences: BusinessAudience[];
  primaryCategories: PrimaryBusinessCategory[];
  secondaryCategories: SecondaryBusinessCategory[];
  onViewDetails?: () => void;
}) => {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="baseline" wrap="wrap" gap={2}>
        <PrimaryCategoriesRenderer categories={primaryCategories} />
        <AudienceRenderer audiences={audiences} />
        {secondaryCategories.length > 0 ? (
          <Box color="kgray.300" fontSize="xs" px="2">
            +{secondaryCategories.length} more
          </Box>
        ) : (
          <></>
        )}
      </Flex>
      <Popover isLazy={true} placement="end" trigger="hover">
        <PopoverTrigger>
          <ChakraInformation
            alignSelf="start"
            color="kgray.300"
            flexShrink="0"
            mt="1.5"
          />
        </PopoverTrigger>
        <PopoverContent p={2}>
          <PopoverArrow />
          <PopoverHeader border={0} fontSize="md" fontWeight={500}>
            Categories
          </PopoverHeader>
          <PopoverBody onClick={(e) => e.stopPropagation()}>
            <CategoriesSection
              audiences={audiences}
              primaryCategories={primaryCategories}
              secondaryCategories={secondaryCategories}
            />
            <Button
              colorScheme="kbuttonsecondary"
              color="kgray.400"
              fontWeight="normal"
              onClick={onViewDetails}
              mt="4"
              w="100%"
            >
              View company details
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export const CategoriesAndAudienceRenderer = React.memo(
  _CategoriesAndAudienceRenderer
);
