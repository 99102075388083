import {Box} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';

interface TabbedPageProps {
  title: string;
  children: React.ReactNode;
}

const TabbedPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  /* slightly fragile, but fine for now */
  > .chakra-tabs > [role='tablist'] {
    padding-top: 16px;
    background-color: #f5f5f5;

    [role='tab'] {
      color: #76767e;
    }

    [role='tab'][aria-selected='true'] {
      color: #0a0a0a;
    }

    [role='tab']:first-of-type {
      margin-left: 150px;
    }
  }
`;

export const TabbedPage = ({title, children}: TabbedPageProps) => {
  return (
    <TabbedPageWrapper>
      {children}
      <Box fontSize="24px" fontWeight={500} position="absolute" ml={4} mt={4}>
        {title}
      </Box>
    </TabbedPageWrapper>
  );
};
