import React from 'react';
import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Divider,
  Flex,
  Image,
  Text,
  Button,
  Tooltip,
} from '@chakra-ui/react';
import {User, UserStateLabels} from '../../shared/api/api';

interface UserDetailsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  user: User;
}

export const UserDetailsDrawer = ({
  isOpen,
  onClose,
  user,
}: UserDetailsDrawerProps) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody as={Flex} flexDirection="column" p="0">
          <Flex alignItems="baseline" justifyContent="space-between" p="8">
            <Text fontSize="md" fontWeight="500">
              Manage User
            </Text>
            <DrawerCloseButton position="static" />
          </Flex>

          <Divider />

          <Flex alignItems="center" gap="4" m="8">
            <Box w="50px" h="50px">
              <Image borderRadius="full" src={user.picture} />
            </Box>
            <Flex direction="column" flex="1" gap="1">
              <Flex alignItems="center" justifyContent="space-between">
                <Box fontSize="lg" fontWeight="500">
                  {user.name}
                </Box>
                <UserState state={user.state} />
              </Flex>
              <Box color="kgray.300">{user.email}</Box>
            </Flex>
          </Flex>

          <Tooltip
            hasArrow
            isDisabled={false}
            label="Please contact us if you'd like to remove a user from your account."
            placement="top"
          >
            <Button colorScheme="red" isDisabled={true} m="8">
              Remove user
            </Button>
          </Tooltip>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const UserStateColors = {
  active: {
    bgColor: 'kgreen.100',
    textColor: 'kgreen.400',
  },
  invited: {
    bgColor: 'kblue.100',
    textColor: 'kblue.400',
  },
};

const UserState = ({state}: {state: User['state']}) => {
  return (
    <Box
      borderRadius="md"
      bg={UserStateColors[state].bgColor}
      color={UserStateColors[state].textColor}
      fontSize="xs"
      letterSpacing="1px"
      lineHeight="1"
      px="2"
      py="1"
      textTransform="uppercase"
    >
      {UserStateLabels[state]}
    </Box>
  );
};
