import {useDropzone} from 'react-dropzone';
import {Box, Flex} from '@chakra-ui/react';
import {DocumentImport} from '@carbon/icons-react';
import React from 'react';

interface FileChooserProps {
  selectedFile: File | null;
  onSelectedFile?: (file: File) => void;
}

export const FileChooser = ({
  selectedFile,
  onSelectedFile,
}: FileChooserProps) => {
  // Configure the drag-drop area to only accept a single CSV file with a maximum size of 10MB.
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
    maxSize: 10_000_000,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        onSelectedFile?.(acceptedFiles[0]);
      }
    },
  });

  return (
    <Flex
      direction="column"
      alignItems="center"
      backgroundColor={isDragActive ? 'kblue.100' : ''}
      borderWidth={1}
      borderRadius={2}
      borderStyle="dashed"
      borderColor={isDragActive ? 'kblue.200' : 'kgray.200'}
      color="kgray.300"
      cursor="pointer"
      padding="16"
      width="100%"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <DocumentImport height="50px" width="50px" style={{margin: '12px'}} />
      {selectedFile ? (
        <Box>{selectedFile.name}</Box>
      ) : (
        <Box>
          Drop a CSV here or{' '}
          <Box as="span" color="kblue.300" textDecoration="underline">
            Click to select a file
          </Box>
        </Box>
      )}
    </Flex>
  );
};
