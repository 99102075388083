import React from 'react';
import {Flex} from '@chakra-ui/react';
import {MarketBuilderHeader} from './MarketBuilderHeader';
import {MarketBuilderContent} from './MarketBuilderContent';

export const MarketBuilder = () => {
  return (
    <Flex direction="column" h="100%">
      <MarketBuilderHeader />
      <MarketBuilderContent />
    </Flex>
  );
};
