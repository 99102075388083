import {z} from 'zod';

export const CompanyTypeSchema = z.enum([
  'growingVcBacked',
  'b2cBrands',
  'b2bBusinesses',
  'cultureConscious',
  'securityAndPrivacy',
  'traditionalIndustries',
  'aiPropensity',
]);
export type CompanyType = z.infer<typeof CompanyTypeSchema>;

export const OnboardingTemplateRequestSchema = z.object({
  type: CompanyTypeSchema,
});
