import {useAuth0} from '@auth0/auth0-react';
import React from 'react';
import {useSearchParams} from 'react-router-dom';

export const LoginPage = () => {
  const {loginWithRedirect} = useAuth0();
  const [searchParams] = useSearchParams();

  loginWithRedirect({
    organization: searchParams.get('organization') || undefined,
    invitation: searchParams.get('invitation') || undefined,
    screen_hint: searchParams.get('type') === 'signup' ? 'signup' : undefined,
  });

  return <React.Fragment />;
};
