// NOTE: google search are case insensitive, so everything here is lowercase
// exclude: query cannot include any of these
// include: query must include at least one of these

import {zodTypeguard} from './api/helpers';
import {RoleSearchCountrySchema} from './countries';
import {zodEnum, keys} from './util';
import {z} from 'zod';

export const RoleDefinitionSchema = z.object({
  exclude: z.array(z.string()),
  include: z.array(z.string()),
  titles: z.array(z.string()),
  countries: z.array(RoleSearchCountrySchema),
});
export type RoleDefinition = z.infer<typeof RoleDefinitionSchema>;

// titles: query must include at least one of these
export const ContactRoles = {
  accountBasedMarketing: {
    exclude: [],
    include: [
      'abm',
      'account-based marketing',
      'account based marketing',
      'enterprise marketing',
    ],
    titles: [],
  },
  accountExecutive: {
    exclude: ['cro', 'chief', 'director', 'head of', 'vp', 'vice'],
    include: [
      'account director',
      'account executive',
      'account manager',
      'commercial sales',
      'corporate sales',
      'enterprise sales',
      'inside sales',
      'regional sales',
      'sales associate',
      'sales director',
      'sales executive',
      'sales rep',
      'sales representative',
    ],
    titles: [],
  },
  community: {
    exclude: ['intern', 'interim', 'consulting', 'consultant'],
    include: [
      'community',
      'dev rel',
      'developer advocacy',
      'developer advocate',
      'developer relations',
      'devrel',
    ],
    titles: [],
  },
  communityLeader: {
    exclude: ['intern', 'interim', 'consulting', 'consultant'],
    include: [
      'community',
      'dev rel',
      'developer advocacy',
      'developer advocate',
      'developer relations',
      'devrel',
    ],
    titles: ['chief', 'director', 'head of', 'vp', 'vice'],
  },
  customerSuccess: {
    exclude: ['intern', 'interim', 'consulting', 'consultant'],
    include: ['client success', 'csm', 'customer success'],
    titles: [],
  },
  customerSuccessLeader: {
    exclude: ['intern', 'interim', 'consulting', 'consultant'],
    include: ['client success', 'csm', 'customer success'],
    titles: ['chief', 'director', 'head of', 'vp', 'vice'],
  },
  demandGeneration: {
    exclude: [],
    include: [
      'demand gen',
      'demand generation',
      'demand marketing',
      'integrated marketing',
    ],
    titles: [],
  },
  digitalMarketing: {
    exclude: [],
    include: ['digital marketing', 'digital marketer'],
    titles: [],
  },
  fieldMarketing: {
    exclude: [],
    include: ['field', 'events', 'event'],
    titles: [],
  },
  founderCEO: {
    exclude: ['assistant', 'of the', 'to the', 'manager'],
    include: ['founder', 'co-founder', 'ceo', 'chief executive officer'],
    titles: [],
  },
  growthMarketing: {
    exclude: [
      'intern',
      'associate',
      'partner',
      'account',
      'sales',
      'consultant',
    ],
    include: ['growth marketing', 'growth engineer', 'growth'],
    titles: [],
  },
  hrLeader: {
    exclude: ['consultant'],
    include: ['hr', 'human resources', 'people', 'talent', 'dei', 'diversity'],
    titles: ['chief', 'svp', 'vp', 'director', 'head'],
  },
  marketingLeader: {
    exclude: [],
    include: ['marketing', 'demand gen', 'demand generation', 'growth'],
    titles: ['vp', 'svp', 'vice president', 'director', 'chief', 'head of'],
  },
  marketingOperations: {
    exclude: [],
    include: [
      'mops',
      'marketing ops',
      'marketing operations',
      'martech',
      'mar tech',
      'marketing tech',
    ],
    titles: [],
  },
  productMarketing: {
    exclude: ['intern', 'interim', 'consulting', 'consultant'],
    include: [
      'category marketing',
      'competitive intel',
      'competitive intelligence',
      'marketing strategy',
      'pmm',
      'portfolio marketing',
      'product marketing',
    ],
    titles: [],
  },
  recruitingLeader: {
    exclude: ['consultant'],
    include: ['recruiting', 'recruiter', 'talent acquisition'],
    titles: ['chief', 'svp', 'vp', 'director', 'head', 'lead', 'leader'],
  },
  recruitingOps: {
    exclude: ['intern', 'interim', 'consulting', 'consultant'],
    include: [
      'recruiting ops',
      'talent ops',
      'recruiting operations',
      'talent operations',
    ],
    titles: [],
  },
  salesEnablement: {
    exclude: ['consultant', 'consulting', 'interim', 'intern'],
    include: ['sales enablement', 'sales trainer', 'sales training'],
    titles: [],
  },
  salesLeader: {
    exclude: ['ops', 'operations'],
    include: ['account executive', 'revenue', 'sales'],
    titles: ['chief', 'cro', 'director', 'head of', 'vice', 'vp'],
  },
  salesOrRevOps: {
    exclude: ['consultant', 'consulting', 'interim', 'intern'],
    include: [
      'rev ops',
      'revenue operations',
      'revenue ops',
      'sales analyst',
      'sales operations',
      'sales ops',
      'sales strategy',
      'sales tech',
      'salesforce admin',
    ],
    titles: [],
  },
  salesOrRevOpsLeader: {
    exclude: ['consultant', 'consulting', 'interim', 'intern'],
    include: [
      'rev ops',
      'revenue operations',
      'revenue ops',
      'sales analyst',
      'sales operations',
      'sales ops',
      'sales strategy',
      'sales tech',
      'salesforce admin',
    ],
    titles: ['chief', 'director', 'head of', 'vp', 'vice'],
  },
  salesOrSolutionsEng: {
    exclude: ['consultant', 'consulting', 'interim', 'intern'],
    include: [
      'customer engineer',
      'pre sales consultant',
      'pre-sales consultant',
      'sales engineer',
      'solution engineer',
      'solutions engineer',
      'technical engineer',
    ],
    titles: [],
  },
  sdrOrBdr: {
    exclude: [
      'chief',
      'consultant',
      'consulting',
      'cro',
      'director',
      'head of',
      'intern',
      'vice',
      'vp',
    ],
    include: [
      'account development',
      'adr',
      'bdr',
      'business development',
      'lead development',
      'market development',
      'sales development',
      'sdr',
      'territory development',
    ],
    titles: [],
  },
  sdrOrBdrLeader: {
    exclude: [],
    include: [
      'account development',
      'adr',
      'bdr',
      'business development',
      'lead development',
      'market development',
      'sales development',
      'sdr',
      'territory development',
    ],
    titles: ['cro', 'chief', 'director', 'head of', 'vp', 'vice'],
  },
} as const;

export const ContactRole = zodEnum(keys(ContactRoles));
export const isContactRole = zodTypeguard(ContactRole);
export type ContactRole = z.infer<typeof ContactRole>;

export const ContactRoleLabels: Record<ContactRole, string> = {
  accountBasedMarketing: 'ABM',
  accountExecutive: 'Account Executive',
  community: 'Community',
  communityLeader: 'Community Leader',
  customerSuccess: 'Customer Success',
  customerSuccessLeader: 'Customer Success Leader',
  demandGeneration: 'Demand Gen',
  digitalMarketing: 'Digital Marketing',
  fieldMarketing: 'Field Marketing',
  founderCEO: 'Founder and/or CEO',
  growthMarketing: 'Growth Marketing',
  hrLeader: 'HR Leader',
  marketingLeader: 'Marketing Leader',
  marketingOperations: 'Marketing Ops',
  productMarketing: 'Product Marketing',
  recruitingLeader: 'Recruiting Leader',
  recruitingOps: 'Recruiting Ops',
  salesEnablement: 'Sales Enablement',
  salesLeader: 'Sales Leader',
  salesOrRevOps: 'RevOps',
  salesOrRevOpsLeader: 'RevOps Leader',
  salesOrSolutionsEng: 'Solution/Sales Eng',
  sdrOrBdr: 'SDR',
  sdrOrBdrLeader: 'SDR Leader',
} as const;

// The list of roles enabled for getting account contacts
export const ExportContactRoles = [
  'founderCEO',
  'hrLeader',
  'marketingLeader',
  'salesLeader',
  'salesOrRevOpsLeader',
] as ContactRole[];
