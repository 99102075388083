import React, {useContext} from 'react';
import {ScoringRunResponse} from '../shared/api/responses';

const ScoringRunContext = React.createContext<ScoringRunResponse | undefined>(
  undefined
);

export function ScoringRunProvider({
  scoringRun,
  children,
}: React.PropsWithChildren<{
  scoringRun: ScoringRunResponse;
}>) {
  return (
    <ScoringRunContext.Provider value={scoringRun}>
      {children}
    </ScoringRunContext.Provider>
  );
}

export function useScoringRun() {
  const scoringRun = useContext(ScoringRunContext);
  if (!scoringRun) {
    throw new Error('useScoringRun must be used within a ScoringRunProvider');
  }

  return scoringRun;
}
