import {useQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {AccountSignalsResponse} from '../../shared/api/responses';
import {ObjectId} from 'bson';

export function useAccountSignals(accountId?: ObjectId) {
  const keyplayApi = useKeyplayApi();

  return useQuery<AccountSignalsResponse>(
    ['accountSignals', accountId],
    () =>
      keyplayApi('/accountSignals', {
        params: {accountId},
        method: 'GET',
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    }
  );
}
