import React from 'react';
import {
  PopoverProps,
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  VStack,
  chakra,
} from '@chakra-ui/react';
import {Add} from '@carbon/icons-react';
import _ from 'lodash';

export const MoreFiltersPopover = ({children, ...rest}: PopoverProps) => {
  return (
    <Popover placement="bottom" {...rest}>
      {(props) => (
        <>
          <PopoverTrigger>
            <Button
              alignSelf="start"
              colorScheme="kbuttonblue"
              fontWeight="normal"
              leftIcon={
                <>
                  <Add />
                </>
              }
              variant="link"
            >
              Add more filters
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent maxWidth="max-content">
              <PopoverArrow />
              <PopoverBody p="4">
                <VStack gap="2">
                  {_.isFunction(children) ? children(props) : children}
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};

export const MoreFiltersButton = chakra(Button, {
  baseStyle: {
    fontWeight: 'normal',
    justifyContent: 'start',
    width: '100%',
  },
});
