import React from 'react';
import {
  UnorderedList,
  ListItem,
  Flex,
  Image,
  Text,
  Button,
} from '@chakra-ui/react';
import {TrashCan} from '@carbon/icons-react';
import {AccountWithLogo} from '../../../shared/scoredAccounts';

export const AccountList = ({
  onRemoveAccount,
  selectedAccounts,
}: {
  onRemoveAccount: (account: AccountWithLogo) => void;
  selectedAccounts: AccountWithLogo[];
}) => {
  return (
    <UnorderedList listStyleType="none" marginLeft={0} spacing={2}>
      {selectedAccounts.map((account) => (
        <ListItem
          key={account._id.toString()}
          border="1px solid"
          borderColor="kgray.200"
          borderRadius="10px"
          p="3"
        >
          <Flex justifyContent="space-between">
            <Flex alignItems="center" gap="4">
              <Image
                src={account.logoUrl}
                alt="icon"
                boxSize="32px"
                fallbackSrc="/accountFallback.png"
              />
              <Flex gap="2">
                <Text>{account.name}</Text>
                <Text fontWeight="thin" color="gray.500">
                  {account.domain}
                </Text>
              </Flex>
            </Flex>
            <Button
              colorScheme="blue"
              onClick={() => onRemoveAccount(account)}
              variant="ghost"
            >
              <TrashCan />
            </Button>
          </Flex>
        </ListItem>
      ))}
    </UnorderedList>
  );
};
