import {Tr, Td, Table, Box, Grid, Flex, Spacer, Tbody} from '@chakra-ui/react';
import React from 'react';
import {useColumnDefinitions} from './AccountGrid.columns';
import {useContentPaneHeight} from '../../hooks/api/metadata';

export const AccountGridSkeleton = () => {
  const columnDefinitions = useColumnDefinitions();
  const gridHeight = useContentPaneHeight();

  const rows = [];
  for (let i = 0; i < 30; i++) {
    rows.push(
      <Tr key={i}>
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          columnDefinitions.map((d: any, colIndex: number) => (
            <Td
              key={`${i}_${colIndex}`}
              width={`${d?.meta?.width}px`}
              maxWidth={`${d?.meta?.width}px`}
              height={i === 0 ? 31 : 81}
              p={0}
              borderRight={colIndex === 0 ? '' : '1px solid #E8E8EB'}
              borderTop="1px solid #E8E8EB"
            />
          ))
        }
      </Tr>
    );
  }
  return (
    <Grid
      templateRows="71px 1fr"
      maxWidth="100vw"
      maxHeight={gridHeight}
      overflow="hidden"
    >
      <Flex alignItems="center">
        {/* 50% between gray 100 and gray 200 */}
        <Box bg="#F0F0F3" width="100px" height="20px" ml={4} />
        <Spacer />
      </Flex>

      <Table sx={{tableLayout: 'fixed'}}>
        <Tbody>{rows}</Tbody>
      </Table>
    </Grid>
  );
};
