import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {TagResponse} from '../../shared/api/api';
import {MetadataQueryKey} from '../../context/MetadataContext';

export function useTags() {
  const makeApiCall = useKeyplayApi();
  return useQuery(['tags'], () => makeApiCall<TagResponse[]>('/tags'));
}

export function useCreateTagMutation() {
  const queryClient = useQueryClient();
  const makeApiCall = useKeyplayApi();
  return useMutation({
    mutationFn: (name: string) =>
      makeApiCall<TagResponse>(
        '/tags',
        {
          method: 'POST',
          data: {
            name,
          },
        },
        {
          toastOnError: true,
        }
      ),
    onSuccess: async () => {
      // Reload the list of tags.
      // TODO: Remove useTags hook.
      await Promise.allSettled([
        queryClient.invalidateQueries(['tags']),
        queryClient.invalidateQueries([MetadataQueryKey]),
      ]);
    },
  });
}
