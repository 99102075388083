import {StoreApi, createStore, useStore} from 'zustand';
import {devtools} from 'zustand/middleware';
import produce from 'immer';
import {IntegrationResponse} from '../../shared/api/api';
import {
  AdditionalEnrichmentFieldConfig,
  IntegrationConfig,
  isAdditionalEnrichmentFieldConfig,
  SyncSettings,
  ManagedEnrichmentFieldConfig,
  ManagedEnrichmentField,
  isManagedEnrichmentFieldConfig,
} from '../../shared/integrations';
import {IntegrationType} from '../../shared/integrations';
import {
  useIntegration,
  useUpdateIntegration,
} from '../../hooks/api/integrations';
import {useEffect} from 'react';
import _ from 'lodash';
import {useMutation} from '@tanstack/react-query';
import {useToast} from '@chakra-ui/react';

interface IntegrationDetailsState {
  // initialization and server state
  hasInitialized: boolean;
  updateServerState: (serverResponse: IntegrationResponse) => void;
  serverState: IntegrationResponse | null;

  // client state
  managedFields: ManagedEnrichmentFieldConfig[];
  addManagedField: (fieldConfig: ManagedEnrichmentFieldConfig) => void;
  deleteManagedField: (enrichmentField: ManagedEnrichmentField) => void;

  additionalFields: AdditionalEnrichmentFieldConfig[];
  addAdditionalField: (fieldConfig: AdditionalEnrichmentFieldConfig) => void;
  updateAdditionalField: (
    oldFieldConfig: AdditionalEnrichmentFieldConfig,
    newFieldConfig: AdditionalEnrichmentFieldConfig
  ) => void;
  deleteAdditionalField: (
    deletedFieldConfig: AdditionalEnrichmentFieldConfig
  ) => void;

  autoSync: boolean;
  setAutoSync: (autoSync: boolean) => void;
  syncSettings: SyncSettings | undefined;
  setSyncSettings: (syncSettings: SyncSettings) => void;

  getServerConfigFromLocalState: () => Partial<IntegrationConfig>;
  hasChanges: (args?: {includeDraftChanges?: boolean}) => boolean;
}

const createIntegrationDetailsStoreFactory = () =>
  createStore<IntegrationDetailsState>()(
    devtools<IntegrationDetailsState>((set, get) => {
      const getFilteredAdditionalFields = () =>
        get().additionalFields.filter((config) => config.crmFieldName);

      const getLocalStateFromServerState = (
        serverState: IntegrationResponse
      ) => {
        const config = serverState.config;
        const fieldMapping = config?.fieldMapping ?? [];

        return {
          managedFields: fieldMapping.filter(isManagedEnrichmentFieldConfig),
          additionalFields: fieldMapping.filter(
            isAdditionalEnrichmentFieldConfig
          ),
          autoSync: !!config?.autoSync,
          syncSettings: config?.syncSettings,
        };
      };

      const setLocalState = (serverState: IntegrationResponse) => {
        const {managedFields, additionalFields, autoSync, syncSettings} =
          getLocalStateFromServerState(serverState);

        set(
          produce<IntegrationDetailsState>((state) => {
            state.managedFields = managedFields;
            state.additionalFields = additionalFields;
            state.autoSync = autoSync;
            state.syncSettings = syncSettings;
            state.hasInitialized = true;
          })
        );
      };

      return {
        hasInitialized: false,
        updateServerState: (integrationResponse: IntegrationResponse) => {
          // don't blow away unsaved client changes with new server state
          if (
            !get().hasInitialized ||
            !get().hasChanges({includeDraftChanges: true})
          ) {
            setLocalState(integrationResponse);
          }

          // do this last to ensure hasChanges check runs against old server state
          set(
            produce<IntegrationDetailsState>((state) => {
              state.serverState = integrationResponse;
            })
          );
        },
        serverState: null,

        managedFields: [],
        addManagedField: (fieldConfig) =>
          set(
            produce<IntegrationDetailsState>((state) => {
              state.managedFields.push(fieldConfig);
            })
          ),
        deleteManagedField: (enrichmentField) =>
          set(
            produce<IntegrationDetailsState>((state) => {
              state.managedFields = state.managedFields.filter(
                (f) => f.enrichmentField !== enrichmentField
              );
            })
          ),

        additionalFields: [],
        addAdditionalField: (fieldConfig) =>
          set(
            produce<IntegrationDetailsState>((state) => {
              state.additionalFields.push(fieldConfig);
            })
          ),
        updateAdditionalField: (oldFieldConfig, newFieldConfig) =>
          set(
            produce<IntegrationDetailsState>((state) => {
              const index = get().additionalFields.findIndex((fieldConfig) =>
                _.isEqual(fieldConfig, oldFieldConfig)
              );

              if (index === -1) {
                throw new Error('Could not find field to update');
              }

              state.additionalFields[index] = newFieldConfig;
            })
          ),
        deleteAdditionalField: (deletedFieldConfig) =>
          set(
            produce<IntegrationDetailsState>((state) => {
              state.additionalFields = get().additionalFields.filter(
                (fieldConfig) => !_.isEqual(fieldConfig, deletedFieldConfig)
              );
            })
          ),

        autoSync: false,
        setAutoSync: (autoSync) =>
          set(
            produce<IntegrationDetailsState>((state) => {
              state.autoSync = autoSync;
            })
          ),
        syncSettings: undefined,
        setSyncSettings: (syncSettings) =>
          set(
            produce<IntegrationDetailsState>((state) => {
              state.syncSettings = syncSettings;
            })
          ),

        hasChanges: ({
          includeDraftChanges,
        }: {includeDraftChanges?: boolean} = {}) => {
          const serverState = get().serverState;
          if (!get().hasInitialized || !serverState) {
            return false;
          }

          const {managedFields, additionalFields, autoSync, syncSettings} =
            getLocalStateFromServerState(serverState);

          const additionalFieldsToCompareAgainst = includeDraftChanges
            ? get().additionalFields
            : getFilteredAdditionalFields();

          return (
            !_.isEqual(managedFields, get().managedFields) ||
            !_.isEqual(additionalFields, additionalFieldsToCompareAgainst) ||
            !_.isEqual(syncSettings, get().syncSettings) ||
            autoSync !== get().autoSync
          );
        },

        getServerConfigFromLocalState: () => {
          const fieldMapping = [
            ...get().managedFields,
            ...getFilteredAdditionalFields(),
          ];

          return {
            fieldMapping,
            autoSync: get().autoSync,
            syncSettings: get().syncSettings,
          };
        },
      };
    })
  );

let integrationDetailsStore: StoreApi<IntegrationDetailsState> | null = null;
export const useIntegrationDetailsState = (
  integrationName: IntegrationType
) => {
  // Only initialize the store once
  if (!integrationDetailsStore) {
    integrationDetailsStore = createIntegrationDetailsStoreFactory();
  }
  const store = useStore(integrationDetailsStore);

  const query = useIntegration(integrationName);
  const updateIntegrationMutation = useUpdateIntegration(integrationName);

  const toast = useToast();
  const successToastId = 'successToast';
  const publishChanges = useMutation({
    mutationFn: async () => {
      if (!store.hasInitialized) {
        throw new Error('Cannot publish changes before initializing');
      }

      if (!store.hasChanges()) {
        return;
      }

      return updateIntegrationMutation.mutateAsync(
        store.getServerConfigFromLocalState()
      );
    },
    onSuccess: () => {
      if (toast.isActive(successToastId)) {
        return;
      }
      toast({
        id: successToastId,
        title: 'Successfully updated integration',
        status: 'success',
        duration: 2_000,
      });
    },
  });

  const {data} = query;
  useEffect(() => {
    if (data) {
      store.updateServerState(data);
    }
    // don't add store as dependency because it causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    ...store,
    query,
    publishChanges,
  };
};
