import React, {useEffect, useState} from 'react';
import {Alert, Flex, Spinner} from '@chakra-ui/react';
import {useMutation} from '@tanstack/react-query';
import {User} from 'auth0';
import {useNavigate} from 'react-router';
import {useKeyplayApi} from '../../context/ApiContext';
import {Customer} from '../../shared/customer';
import {WithId} from '../../shared/util';
import {CustomerSelector} from './CustomerSelector';
import {useMembership} from '../../hooks/api/customer';

export const Welcome = ({
  logout,
  onTokenUpdated,
  user,
}: {
  logout: Function;
  onTokenUpdated: (token: string) => void;
  user: User;
}) => {
  const makeApiCall = useKeyplayApi();
  const navigate = useNavigate();
  const [customers, setCustomers] = useState<WithId<Customer>[] | undefined>();

  // Disable since we only want to trigger the fetch manually in the useEffect
  const membership = useMembership({enabled: false});

  const bootstrap = useMutation({
    mutationFn: () => makeApiCall('/customer/bootstrap', {method: 'post'}),
  });

  useEffect(() => {
    const run = async () => {
      const result = await membership.refetch();
      if (result.data?.length) {
        setCustomers(result.data);
        return;
      }

      // If the user is not associated with any customers, we need to create a new one for them.
      // When the bootstrap completes, do a full page reload so the access token is properly refreshed.
      bootstrap.mutate(undefined, {
        onSuccess: () => {
          navigate(0);
        },
      });
    };
    run();
    // Disable exhaustive-deps lint rule since we only want to run this once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (membership.isError) {
    return (
      <Alert status="error">
        Something went wrong. Please contact support@keyplay.io
      </Alert>
    );
  }

  if (!customers?.length) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  if (customers.length === 1) {
    // This shouldn't happen since the user should be redirected after the bootstrap completes.
    // If the access token is not refreshed in time, we could end up back here again.
    navigate(0);
    return <Spinner />;
  }

  return (
    <CustomerSelector
      customers={customers}
      logout={logout}
      onTokenUpdated={onTokenUpdated}
      user={user}
    />
  );
};
