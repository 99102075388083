import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';
import {
  AiFieldBuilderStep,
  useAiFieldBuilderStore,
} from './AiFieldBuilder.state';
import {WrapperFlex} from '../../utils/scrolling';
import {AiFieldBuilderDefineFieldStep} from './AiFieldBuilderDefineFieldStep';
import {AiFieldBuilderSelectAccountsStep} from './AiFieldBuilderSelectAccountsStep';
import {AiFieldBuilderPreviewResultsStep} from './AiFieldBuilderPreviewResultsStep';
import {AiFieldBuilderModalHeader} from './AiFieldBuilderModalHeader';

export const AiFieldBuilderModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {reset} = useAiFieldBuilderStore();
  return (
    <Modal
      isCentered={true}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={reset}
      size="full"
    >
      <ModalOverlay />
      <AiFieldBuilderModalContent />
    </Modal>
  );
};

const Steps = {
  defineField: <AiFieldBuilderDefineFieldStep />,
  selectAccounts: <AiFieldBuilderSelectAccountsStep />,
  previewResults: <AiFieldBuilderPreviewResultsStep />,
} satisfies Record<AiFieldBuilderStep, React.ReactNode>;

const AiFieldBuilderModalContent = () => {
  const {step} = useAiFieldBuilderStore();
  return (
    <ModalContent>
      <AiFieldBuilderModalHeader />
      <ModalBody as={Flex} flexDirection="column" p="0">
        <AiFieldBuilderTitleSection />
        <Box flex="1" position="relative">
          <WrapperFlex>{Steps[step]}</WrapperFlex>
        </Box>
      </ModalBody>
      <ModalCloseButton />
    </ModalContent>
  );
};

const AiFieldBuilderTitleSection = () => {
  const {fieldDefinition} = useAiFieldBuilderStore();
  return (
    <Flex
      borderBottom="1px solid"
      borderColor="kgray.200"
      justifyContent="space-between"
      p="6"
    >
      <Text fontSize="2xl" fontWeight="normal">
        {fieldDefinition
          ? `AI Field Builder - ${fieldDefinition.label}`
          : 'AI Field Builder'}
      </Text>
    </Flex>
  );
};
