import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App/App';
import * as Sentry from '@sentry/react';

import {Auth0Provider} from '@auth0/auth0-react';
import {ChakraProvider} from '@chakra-ui/react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {theme} from './components/theme';
import {LoginPage} from './components/App/Login/LoginPage';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ApiAudience, Auth0ClientId, LoginUrl} from './shared/auth';
import {Helmet} from 'react-helmet';
import {initAnalytics} from './analytics';
import OAuthCallback from './components/App/Login/OAuthCallback';

if (import.meta.env.PROD) {
  Sentry.init({
    // https://docs.sentry.io/product/sentry-basics/dsn-explainer/#dsn-utilization
    dsn: 'https://01011882792949d5918b697729a81b3f@o4504041248587776.ingest.sentry.io/4504041249832960',
    integrations: [Sentry.browserTracingIntegration()],
    environment: import.meta.env.MODE,
    release: GIT_COMMIT,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });

  initAnalytics();
}

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const loginDomain = new URL(LoginUrl).host;

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <DndProvider backend={HTML5Backend}>
            <Auth0Provider
              domain={loginDomain}
              clientId={Auth0ClientId}
              redirectUri={window.location.origin}
              audience={ApiAudience}
              // https://auth0.com/docs/secure/tokens/refresh-tokens/use-refresh-token-rotation
              useRefreshTokens={true}
            >
              <Helmet>
                <meta name="git-commit" content={GIT_COMMIT} />
              </Helmet>
              <Routes>
                <Route path="/oauth/*" element={<OAuthCallback />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="*" element={<App />} />
              </Routes>
            </Auth0Provider>
          </DndProvider>
        </ChakraProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
