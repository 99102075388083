import {useMutation} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {Role} from '../../shared/user';
import {useUser} from '../../context/UserContext';
import {isKeyplayAdmin} from '../../lib/user';
import {getCustomClaims} from '../../shared/auth';

export function useUpdateUser() {
  const makeApiCall = useKeyplayApi();
  return useMutation({
    mutationFn: ({role}: {role: Role}) =>
      makeApiCall('/customer/user', {
        data: {role},
        method: 'POST',
      }),
  });
}

export function useIsKeyplayAdmin() {
  const user = useUser();
  return isKeyplayAdmin(user.auth0);
}

export function useIsTemporaryMembership() {
  const user = useUser();
  return getCustomClaims(user.auth0).isTemporarayMembership;
}
