import {parse} from 'papaparse';

export type FileValidationError = 'missing_columns' | 'file_too_large';

export interface ValidateFileProps<T> {
  maxRows: number;
  onFileValidate: (errors: Set<FileValidationError>, data: T[]) => void;
  rowSchemaTypeguard: (val: unknown) => val is T;
  selectedFile: File;
}

export function validateFile<T>({
  maxRows,
  onFileValidate,
  rowSchemaTypeguard,
  selectedFile,
}: ValidateFileProps<T>) {
  const validationErrors: Set<FileValidationError> = new Set();
  const data: T[] = [];

  parse<T>(selectedFile, {
    header: true,
    // Parse up to one more than the max allowable import size.
    preview: maxRows + 1,
    skipEmptyLines: true,
    step: (results, parser) => {
      if (!rowSchemaTypeguard(results.data)) {
        validationErrors.add('missing_columns');
        parser.abort();
      }

      data.push(results.data);
      if (data.length > maxRows) {
        data.pop();
        validationErrors.add('file_too_large');
        parser.abort();
      }
    },
    complete: () => {
      onFileValidate(validationErrors, data);
    },
  });
}
