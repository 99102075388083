import {Box, Button, Flex, Link} from '@chakra-ui/react';
import {Email, WarningAlt} from '@carbon/icons-react';
import React from 'react';
import {FileValidationError} from '../../lib/validateFile';
import {entries} from '../../shared/util';

function errorMap(
  fileValidationErrors: Set<FileValidationError>,
  fn: (fileValidationError: FileValidationError) => React.ReactNode
) {
  return [...fileValidationErrors].reduce(
    (map, fileValidationError) => {
      map[fileValidationError] = fn(fileValidationError);
      return map;
    },
    {} as Record<FileValidationError, React.ReactNode>
  );
}

export const FileValidationErrors = ({
  fileValidationErrors,
  validationErrorFn,
  onChooseAnotherFile,
}: {
  fileValidationErrors: Set<FileValidationError>;
  validationErrorFn: (
    fileValidationError: FileValidationError
  ) => React.ReactNode;
  onChooseAnotherFile: () => void;
}) => {
  return (
    <Flex direction="column" width="100%">
      <_FileValidationErrors
        errors={errorMap(fileValidationErrors, validationErrorFn)}
      />
      <Flex direction="column" alignItems="center">
        <Button colorScheme="kbuttonblue" mt={8} onClick={onChooseAnotherFile}>
          Choose Another File
        </Button>
      </Flex>
    </Flex>
  );
};

const _FileValidationErrors = ({
  errors,
}: {
  errors: Record<FileValidationError, React.ReactNode>;
}) => {
  return (
    <Flex direction="column" width="100%">
      <Box alignSelf="center" mb={8}>
        Errors found. Please check your CSV and try again.
      </Box>
      <Flex direction="column" gap={4} ml={8}>
        {entries(errors).map(([fileValidationKey, fileValidationText]) => {
          return (
            <Flex direction="row" alignItems="center" key={fileValidationKey}>
              <WarningAlt style={{color: 'red', marginRight: '0.5rem'}} />
              <Box>{fileValidationText}</Box>
            </Flex>
          );
        })}
        <Flex direction="row" alignItems="center">
          <Email style={{color: 'blue', marginRight: '0.5rem'}} />
          <Box>
            Need help? Email{' '}
            <Link
              href="mailto:support@keyplay.io"
              target="_blank"
              color="kblue.400"
            >
              support@keyplay.io
            </Link>{' '}
            or slack your CSM.
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
