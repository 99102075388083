import React, {useCallback, useContext} from 'react';
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {useToast} from '@chakra-ui/react';
import {useNavigate} from 'react-router';
import {isKeyplayError} from '../shared/api/errors';
import superjson from 'superjson';
import {SuperJSONResult} from 'superjson/dist/types';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  // Handle 2xx responses
  (response: AxiosResponse<unknown>) => {
    const {headers, data} = response;
    // Attempt to deserialize with SuperJSON if the response is JSON
    if (data && headers['content-type']?.includes('application/json')) {
      try {
        response.data = superjson.deserialize(data as SuperJSONResult);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return response;
  },
  // Handle non-2xx responses
  (error) => {
    if (!axios.isAxiosError(error)) {
      return Promise.reject(error);
    }

    const {headers, data} = error.response || {};
    if (
      data &&
      headers?.['content-type']?.includes('application/json') &&
      error.response
    ) {
      try {
        error.response.data = superjson.deserialize(data as SuperJSONResult);
      } catch (_) {
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  (request) => {
    const {headers, data, params} = request;
    // For POST requests, serialize the data if it's JSON
    if (data && headers['Content-Type'] === 'application/json') {
      request.data = superjson.serialize(data);
    }
    // For GET requests, serialize the params into a single key-value pair that
    // the server knows how to access.
    if (params) {
      request.params = {_q: superjson.stringify(params)};
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Generic function, tell it what the shape of the api response is when calling
export type KeyplayApiProviderType = <Response, Request = unknown>(
  apiPath: string,
  options?: AxiosRequestConfig<Request>,
  otherOptions?: {
    toastOnError?: boolean;
  }
) => Promise<Response>;

const KeyplayApiContext = React.createContext<KeyplayApiProviderType | null>(
  null
);

export const KeyplayApiProvider = ({
  children,
  token,
}: React.PropsWithChildren<{token: string}>) => {
  const navigate = useNavigate();
  const toast = useToast();

  const keyplayApi = useCallback(
    async <Response, Request>(
      apiPath: string,
      axiosOptions: AxiosRequestConfig<Request> = {},
      otherOptions: Parameters<KeyplayApiProviderType>[2] = {}
    ): Promise<Response> => {
      if (!token) {
        throw new Error('no auth token yet!');
      }

      if (apiPath.startsWith('/')) {
        apiPath = apiPath.substring(1);
      }

      const apiDomain =
        import.meta.env.VITE_API_DOMAIN ??
        'https://api-xbvraz7rsa-uw.a.run.app';

      axiosOptions = {
        ...axiosOptions,
        url: `${apiDomain}/${apiPath}`,
        method: axiosOptions.method || 'get',
        headers: {
          'Content-Type': 'application/json',
          ...(axiosOptions.headers || {}),
          Authorization: 'Bearer ' + token,
        },
      };

      try {
        const response = await axiosInstance<
          Response,
          AxiosResponse<Response>,
          Request
        >(axiosOptions);
        return response.data;
      } catch (error) {
        if (!axios.isAxiosError(error)) {
          // this should never happen I beieve - all errors are AxiosErrors
          console.error(error);
          throw error;
        }

        const errorData = extractErrorData(error);
        // If we get an auth error, automatically redirect to the login page so the user can reauthenticate.
        if (errorData.code === 'token') {
          navigate('/login');
          throw error;
        }

        if (otherOptions?.toastOnError) {
          toast.closeAll();
          toast({
            title: 'Something went wrong.',
            description: errorData.message,
            status: 'error',
            duration: 7_000,
            isClosable: true,
            position: 'bottom-left',
          });
        }

        throw error;
      }
    },
    [navigate, token, toast]
  );

  return (
    <KeyplayApiContext.Provider value={keyplayApi}>
      {children}
    </KeyplayApiContext.Provider>
  );
};

function extractErrorData(error: AxiosError) {
  const response = error.response;
  if (response) {
    return isKeyplayError(response.data)
      ? response.data.error
      : {
          message: 'There was an error with your request.',
          code: 'unknown' as const,
        };
  }

  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request);
    return {
      message: 'Unable to communicate with the server.',
      code: 'network' as const,
    };
  }

  // Something happened in setting up the request that triggered an Error
  console.log('Error', error.message);
  return {
    message: 'Unable to make API request.',
    code: 'unknown' as const,
  };
}

export const useKeyplayApi = () => {
  const keyplayApi = useContext(KeyplayApiContext);
  if (!keyplayApi) {
    throw new Error('useKeyplayApi must be used within an ApiProvider');
  }

  return keyplayApi;
};
