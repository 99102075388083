import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {FileValidationError} from '../../../lib/validateFile';

type Step = 'chooseFile' | 'uploadFile' | 'errors' | 'done';
interface ModelTestGroupImportState {
  step: Step;
  domains: string[] | null;
  fileValidationErrors: Set<FileValidationError> | null;

  setState: ({
    fileValidationErrors,
    domains,
    step,
  }: {
    fileValidationErrors?: Set<FileValidationError> | null;
    domains?: string[] | null;
    step?: Step;
  }) => void;

  reset: () => void;
}

export const useModelTestGroupImportState = create<ModelTestGroupImportState>()(
  devtools<ModelTestGroupImportState>((set, get) => ({
    step: 'chooseFile',
    domains: null,
    fileValidationErrors: null,

    setState: ({fileValidationErrors, domains, step}) => {
      set({
        step,
        fileValidationErrors:
          get().fileValidationErrors ?? fileValidationErrors,
        domains: domains ?? get().domains,
      });
    },

    reset: () => {
      set({
        step: 'chooseFile',
        domains: null,
        fileValidationErrors: null,
      });
    },
  }))
);
