import React from 'react';
import {
  Box,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import {ScoringSignal} from '../../../shared/signals';
import {useScoringSignalResolver} from '../../../hooks/scoringSignal';
import {useCapability, useCustomer} from '../../../hooks/api/metadata';
import {useScoringModelSignalStore} from './SignalAssignmentStore';
import {getScoringModelMaxPoints} from '../../../shared/scoring';
import {SignalWeightInput} from './SignalWeightInput';
import _ from 'lodash';

interface SignalWeightPopoverProps extends PopoverProps {
  signal: ScoringSignal;
}

export const SignalWeightPopover = ({
  children,
  signal,
}: SignalWeightPopoverProps) => {
  const allowZeroWeight = useCapability('zeroWeightSignals');
  const {getSignalWeight, getSignalBucket, assignWeight, getServerFormat} =
    useScoringModelSignalStore();
  const label = useScoringSignalResolver()(signal)?.label;
  const {signalDefinitions} = useCustomer();

  const weight = getSignalWeight(signal);
  const maxPoints = getScoringModelMaxPoints({
    signalsWithWeights: getServerFormat(),
    signalDefinitions: signalDefinitions ?? [],
  });
  const scorePercentage = weight / maxPoints;

  return (
    <Popover placement="bottom-start">
      {(props) => (
        <>
          <PopoverTrigger>
            <Box>{_.isFunction(children) ? children(props) : children}</Box>
          </PopoverTrigger>
          <PopoverContent _focus={{boxShadow: 'none'}}>
            <PopoverCloseButton color="kgray.200" />
            <PopoverBody>
              <Box px={2} py={3}>
                <Text fontSize="md" fontWeight={400}>
                  Customize Signal Weight
                </Text>
                <Text
                  fontSize="smaller"
                  fontWeight="thin"
                  mt={2}
                  textColor="kgray.300"
                >
                  {label}
                </Text>
                <SignalWeightInput
                  mt={8}
                  min={
                    allowZeroWeight && getSignalBucket(signal) === 'positive'
                      ? 0
                      : 1
                  }
                  max={20}
                  onWeightChange={(val) => {
                    assignWeight(signal, val);
                  }}
                  weight={weight}
                />
                <Text
                  mt="4"
                  fontSize="smaller"
                  fontWeight="thin"
                  color="kgray.300"
                >
                  Up to{' '}
                  <strong>
                    {Math.min(100, Math.round(scorePercentage * 1000) / 10)}%
                  </strong>{' '}
                  of total score
                </Text>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
