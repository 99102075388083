import {useInfiniteQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {ScoredAccountsResponse} from '../../shared/api/responses';
import {useAccountQueryParams} from '../AccountGrid/AccountGrid.controlstate';
import React from 'react';

export function useScoredAccounts() {
  const queryParams = useAccountQueryParams();

  const makeApiCall = useKeyplayApi();
  const infiniteQuery = useInfiniteQuery<ScoredAccountsResponse>(
    ['scoredAccountsTables', JSON.stringify(queryParams)],
    async ({pageParam}) => {
      if (!pageParam) {
        return makeApiCall<ScoredAccountsResponse>('/scoredAccounts', {
          params: {
            query: {
              ...queryParams,
              limit: 80,
            },
          },
        });
      }
      return makeApiCall<ScoredAccountsResponse>('/scoredAccounts', {
        params: {
          cursor: pageParam,
        },
      });
    },
    {
      getNextPageParam: (_lastGroup) => _lastGroup.cursor,
      keepPreviousData: true,
    }
  );

  // we want to keep the array reference stable for consumers
  const fetchedAccounts = React.useMemo(
    () => infiniteQuery.data?.pages?.flatMap((resp) => resp.accounts) ?? [],
    [infiniteQuery.data]
  );

  const totalCount = infiniteQuery.data?.pages?.[0].totalLength;
  const scoringRun = infiniteQuery.data?.pages?.[0]?.scoringRun;

  return {
    fetchedAccounts,
    totalCount,
    scoringRun,
    ...infiniteQuery,
  };
}
