import {useCallback} from 'react';
import {
  ScoringSignal,
  ScoringSignalResolver,
  resolveScoringSignal,
} from '../shared/signals';
import {useCustomer} from './api/metadata';
import {captureMessage} from '@sentry/react';

export function useScoringSignalResolver(): ScoringSignalResolver {
  const customer = useCustomer();

  return useCallback(
    (signal: ScoringSignal) => {
      const resolvedSignal = resolveScoringSignal(
        signal,
        customer.signalDefinitions ?? []
      );
      if (!resolvedSignal) {
        captureMessage(`Signal ${signal} not found`, 'error');
        return null;
      }

      return resolvedSignal;
    },
    [customer]
  );
}
