import React from 'react';
import {Box, BoxProps} from '@chakra-ui/react';

export const PageTitle = ({children, ...props}: BoxProps) => {
  return (
    <Box fontSize="2xl" fontWeight={500} mt={5} mb={5} {...props}>
      {children}
    </Box>
  );
};
