import React from 'react';
import {Flex, GridItem, GridItemProps} from '@chakra-ui/react';
import {Container, ContainerProps} from '@chakra-ui/react';

export const IntegrationSection = ({children}: ContainerProps) => {
  return (
    <Container
      borderRadius={'lg'}
      borderWidth={1}
      borderColor={'kgray.200'}
      maxW={'4xl'}
      p={6}
    >
      {children}
    </Container>
  );
};

export const gridTemplateColumns = '70px 1fr min-content 1fr';

export const GridHeader = ({children, ...props}: GridItemProps) => {
  return (
    <GridItem
      as={Flex}
      fontSize={'sm'}
      fontWeight={600}
      p={0.5}
      textTransform={'uppercase'}
      {...props}
    >
      {children}
    </GridItem>
  );
};

interface GridCellProps extends GridItemProps {
  hasBorder?: boolean;
}
export const GridCell = ({children, hasBorder, ...props}: GridCellProps) => {
  const borderProps = hasBorder
    ? {
        border: '1px',
        borderColor: 'kgray.200',
        borderRadius: 'lg',
      }
    : {};
  return (
    <GridItem
      as={Flex}
      alignItems={'center'}
      px={4}
      py={2}
      {...borderProps}
      {...props}
    >
      {children}
    </GridItem>
  );
};
