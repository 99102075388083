import React, {useContext} from 'react';
import {MetadataResponse} from '../shared/api/api';
import {useKeyplayApi} from './ApiContext';
import {useQuery} from '@tanstack/react-query';

const MetadataContext = React.createContext<MetadataResponse | undefined>(
  undefined
);

export const MetadataQueryKey = 'metadata';

export function MetadataProvider({
  metadata,
  children,
}: React.PropsWithChildren<{
  metadata: MetadataResponse;
}>) {
  return (
    <MetadataContext.Provider value={metadata}>
      {children}
    </MetadataContext.Provider>
  );
}

export function useMetadataQuery() {
  const makeApiCall = useKeyplayApi();
  return useQuery([MetadataQueryKey], () =>
    makeApiCall<MetadataResponse>('/metadata')
  );
}

export function useMetadata() {
  const metadata = useContext(MetadataContext);
  if (!metadata) {
    throw new Error('useMetadata must be used within a MetadataProvider');
  }

  return metadata;
}
