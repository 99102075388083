import React, {useEffect} from 'react';
import {
  useDisclosure,
  Flex,
  Button,
  Box,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
  Checkbox,
  Switch,
  CheckboxGroup,
  Grid,
  GridItem,
  Text,
  Divider,
} from '@chakra-ui/react';
import {useKeyplayApi} from '../../context/ApiContext';
import {
  getFeatures,
  CreateCustomerRequest,
  EditCustomerRequest,
  FeatureName,
  FeatureNames,
  Customer,
  Plan,
  PlanToPlanLimits,
  getLimit,
  Toggle,
  Toggles,
  Limit,
  Limits,
  PlanType,
  PlanTypes,
  ListBuilderPlan,
} from '../../shared/customer';
import {WithId} from '../../shared/util';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import _ from 'lodash';
import {useForm, FieldErrors} from 'react-hook-form';
import {ObjectId} from 'bson';
import {ListBuilderPlanDurationMonths} from '../../shared/listBuilder';
import {addMonths} from 'date-fns';
import {CustomerQueryKeys} from './hooks/customer';

export const CreateOrEditCustomerButton = ({
  customer,
}: {
  customer?: WithId<Customer>;
}) => {
  const createOrEditCustomerModal = useDisclosure();

  return (
    <Flex>
      <Button
        colorScheme="kbuttonblue"
        onClick={createOrEditCustomerModal.onOpen}
      >
        {customer ? 'Edit Customer' : 'Create Customer'}
      </Button>
      <CreateOrEditCustomerModal
        isOpen={createOrEditCustomerModal.isOpen}
        onClose={createOrEditCustomerModal.onClose}
        customer={customer}
      />
    </Flex>
  );
};

type CreateOrEditCustomerForm = {
  customerName: string;
  plan: PlanType;
  saves?: number;
  savesPerMonthLimit?: number;
  recommendedAccountsLimit?: number;
  activeAccountsLimit?: number;
  features: FeatureName[];
  toggles: Toggle[];
  onboarded: boolean;
};

const CreateOrEditCustomerModal = ({
  isOpen,
  onClose,
  customer,
}: {
  isOpen: boolean;
  onClose: () => void;
  customer?: WithId<Customer>;
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const {handleSubmit, getValues, register, reset, setValue, watch} =
    useForm<CreateOrEditCustomerForm>();

  // Ensure the form is reset when the modal is opened
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    reset({
      customerName: customer?.name,
      plan: customer?.plan.type,
      saves: customer ? getLimit(customer, 'saves') : undefined,
      savesPerMonthLimit: customer
        ? getLimit(customer, 'savesPerMonth')
        : undefined,
      recommendedAccountsLimit: customer
        ? getLimit(customer, 'recommendedAccounts')
        : undefined,
      activeAccountsLimit: customer
        ? getLimit(customer, 'activeAccounts')
        : undefined,
      features: customer ? getFeatures(customer) : [],
      toggles: customer?.toggles ?? [],
      onboarded: customer?.onboarded ?? false,
    });
  }, [customer, isOpen, reset]);

  const planType = watch('plan');
  const overridableLimits = planType
    ? PlanToPlanLimits[planType].overridableLimits
    : [];
  const isListBuilder = planType === 'listBuilder';

  const getFormValues = (): CreateCustomerRequest => {
    const planType = getValues('plan');
    const plan: Plan =
      planType === 'listBuilder'
        ? {
            type: 'listBuilder',
            endDate:
              (customer?.plan as ListBuilderPlan)?.endDate ??
              addMonths(new Date(), ListBuilderPlanDurationMonths),
          }
        : {type: planType};

    const savesPerMonth = getValues('savesPerMonthLimit');
    return {
      name: getValues('customerName'),
      plan,
      onboarded: getValues('onboarded'),
      toggles: getValues('toggles'),
      limits: {
        ...(overridableLimits.includes('saves')
          ? {saves: {limit: Number(getValues('saves'))}}
          : {}),
        ...(overridableLimits.includes('savesPerMonth') && savesPerMonth
          ? {savesPerMonth: {limit: Number(savesPerMonth)}}
          : {}),
        ...(overridableLimits.includes('recommendedAccounts')
          ? {recommendedAccounts: Number(getValues('recommendedAccountsLimit'))}
          : {}),
        ...(overridableLimits.includes('activeAccounts')
          ? {activeAccounts: Number(getValues('activeAccountsLimit'))}
          : {}),
      },
      ...(overridableLimits.includes('features')
        ? {features: getValues('features')}
        : {}),
    };
  };

  const getPlanLimit = <L extends Limit>(
    plan: PlanType,
    limit: L
  ): Limits[L] | undefined => {
    const limitOrFn = PlanToPlanLimits[plan].limits[limit];
    return _.isFunction(limitOrFn)
      ? customer
        ? (limitOrFn(customer) as Limits[L])
        : undefined
      : (limitOrFn as Limits[L]);
  };

  const makeApiCall = useKeyplayApi();
  const createCustomer = useMutation({
    mutationFn: () => {
      return makeApiCall<void, CreateCustomerRequest>(
        '/debug/createCustomer',
        {
          method: 'POST',
          data: getFormValues(),
        },
        {toastOnError: true}
      );
    },
    onSuccess: async () => {
      toast({
        title: 'Customer created',
        description: 'Customer created successfully',
        status: 'success',
        isClosable: true,
      });
      await queryClient.invalidateQueries(CustomerQueryKeys.list());
      onClose();
    },
  });

  const editCustomer = useMutation({
    mutationFn: ({customerId}: {customerId: ObjectId}) => {
      return makeApiCall<void, EditCustomerRequest>(
        '/debug/editCustomer',
        {
          method: 'POST',
          data: {
            ...getFormValues(),
            id: customerId.toString(),
          },
        },
        {toastOnError: true}
      );
    },
    onSuccess: async (_, {customerId}) => {
      toast({
        title: 'Customer updated',
        description: 'Customer updated successfully',
        status: 'success',
        isClosable: true,
      });
      await queryClient.invalidateQueries(
        CustomerQueryKeys.details(customerId)
      );
      onClose();
    },
  });

  const onCreateOrEditCustomer = async () => {
    if (customer) {
      editCustomer.mutate({customerId: customer._id});
    } else {
      createCustomer.mutate();
    }
  };

  const onInvalidForm = (errors: FieldErrors<CreateOrEditCustomerForm>) => {
    toast({
      title: 'Something is missing',
      description: _.values(errors).map((error, i) => (
        <Box key={i}>{error?.message}</Box>
      )),
      status: 'error',
      isClosable: true,
    });
  };

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalHeader textAlign="center" fontSize="xl">
          {customer ? 'Edit Customer' : 'Create Customer'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDirection="column" gap={4}>
          <FormControl isRequired>
            <FormLabel>Customer Name</FormLabel>
            <Input
              autoComplete="off"
              type="text"
              {...register('customerName', {
                required: 'Customer name is required',
                validate: (value) => {
                  return value.trim() ? true : 'Customer name is blank';
                },
              })}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Plan</FormLabel>
            <Select
              {...register('plan', {
                required: 'Plan is required',
              })}
              defaultValue={customer?.plan.type}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const planType = e.target.value as PlanType;
                setValue(
                  'savesPerMonthLimit',
                  getPlanLimit(planType, 'savesPerMonth')
                );
                setValue(
                  'recommendedAccountsLimit',
                  getPlanLimit(planType, 'recommendedAccounts')
                );
                setValue(
                  'activeAccountsLimit',
                  getPlanLimit(planType, 'activeAccounts')
                );
                setValue('features', getPlanLimit(planType, 'features') ?? []);
                setValue('plan', planType);
              }}
            >
              <option selected hidden disabled value="">
                Select plan
              </option>
              {PlanTypes.map((plan) => (
                <option key={plan}>{plan}</option>
              ))}
            </Select>
          </FormControl>
          <FormControl display="flex">
            <FormLabel htmlFor="onboarded">Onboarded</FormLabel>
            <Switch
              id="onboarded"
              isChecked={watch('onboarded')}
              onChange={(e) => {
                setValue('onboarded', e.target.checked);
              }}
            />
          </FormControl>
          <Divider />
          <Text fontSize="lg" fontWeight="bold">
            Limits
          </Text>
          <Grid templateColumns="1fr 1fr" gap="4">
            <FormControl>
              <FormLabel>Saves</FormLabel>
              <Input
                autoComplete="off"
                type="number"
                {...register('saves', {
                  validate: (value) => {
                    return _.isInteger(Number(value)) || !value
                      ? true
                      : 'Saves Limit must be an integer';
                  },
                })}
                disabled={!overridableLimits.includes('saves')}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Saves Per Month</FormLabel>
              <Input
                autoComplete="off"
                type="number"
                {...register('savesPerMonthLimit', {
                  validate: (value) => {
                    return _.isInteger(Number(value)) || !value
                      ? true
                      : 'Saves Per Month Limit must be an integer';
                  },
                })}
                disabled={!overridableLimits.includes('savesPerMonth')}
              />
            </FormControl>
            <FormControl isRequired={!isListBuilder}>
              <FormLabel>Recommended Accounts</FormLabel>
              <Input
                autoComplete="off"
                placeholder={!customer && isListBuilder ? '-auto-' : undefined}
                type="number"
                {...register('recommendedAccountsLimit', {
                  required:
                    !isListBuilder && 'Recommended Accounts Limit is required',
                  validate: (value) => {
                    if (isListBuilder) {
                      return true;
                    }

                    return _.isInteger(Number(value))
                      ? true
                      : 'Recommended Accounts Limit must be an integer';
                  },
                })}
                disabled={!overridableLimits.includes('recommendedAccounts')}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Active Accounts</FormLabel>
              <Input
                autoComplete="off"
                type="number"
                {...register('activeAccountsLimit', {
                  required: 'Active Accounts Limit is required',
                  validate: (value) => {
                    return _.isInteger(Number(value))
                      ? true
                      : 'Active Accounts Limit must be an integer';
                  },
                })}
                disabled={!overridableLimits.includes('activeAccounts')}
              />
            </FormControl>
          </Grid>
          <Divider />
          <FormControl>
            <FormLabel>Features</FormLabel>
            <CheckboxGroup
              isDisabled={!overridableLimits.includes('features')}
              onChange={(values) =>
                setValue('features', values as FeatureName[])
              }
              value={watch('features') ?? []}
            >
              <Grid templateColumns="1fr 1fr">
                {FeatureNames.map((featureName) => (
                  <Checkbox as={GridItem} key={featureName} value={featureName}>
                    {formatLabel(featureName)}
                  </Checkbox>
                ))}
              </Grid>
            </CheckboxGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Toggles</FormLabel>
            <CheckboxGroup
              onChange={(values) => setValue('toggles', values as Toggle[])}
              value={watch('toggles') ?? []}
            >
              {Toggles.map((toggle) => (
                <Checkbox key={toggle} value={toggle}>
                  {formatLabel(toggle)}
                </Checkbox>
              ))}
            </CheckboxGroup>
          </FormControl>
          <Button
            alignSelf="center"
            colorScheme="kbuttonblue"
            onClick={handleSubmit(onCreateOrEditCustomer, onInvalidForm)}
            type="submit"
            variant="solid"
            isDisabled={createCustomer.isLoading || editCustomer.isLoading}
            isLoading={createCustomer.isLoading || editCustomer.isLoading}
          >
            {customer ? 'Update' : 'Create'}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

function formatLabel(str: String) {
  return str
    .replace(/([A-Z])/g, ' $1') // insert a space before all capital letters
    .replace(/^./, (str) => str.toUpperCase()); // capitalize the first letter
}
