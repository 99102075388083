import React from 'react';
import {Button} from '@chakra-ui/react';
import {useHasMarketBuilderChanges} from './tabState';
import {useScoringModeState} from '../ModelBuilder/ScoringMode.state';
import {useScoringModelSignalStore} from '../ModelBuilder/SignalAssignmentStore';
import {useSimilarityState} from '../ModelBuilder/Similarity.state';
import {useSamEditorStore} from '../../SamEditor/useSamEditorStore';

export const RevertButton = () => {
  const hasChanges = useHasMarketBuilderChanges();

  const similarityStore = useSimilarityState();
  const signalStore = useScoringModelSignalStore();
  const scoringModeStore = useScoringModeState();
  const samEditorStore = useSamEditorStore();

  return (
    <Button
      isDisabled={!hasChanges}
      colorScheme="kbuttonsecondary"
      textColor="kgray.400"
      fontWeight="normal"
      flexShrink="0"
      onClick={() => {
        similarityStore.resetClientChanges();
        signalStore.resetBucketChanges();
        signalStore.resetWeightChanges();
        scoringModeStore.resetState();
        samEditorStore.resetState();
      }}
    >
      Revert
    </Button>
  );
};
