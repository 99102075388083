import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(['control', 'container', 'label']);

const baseStyle = definePartsStyle({
  label: {
    flex: 1,
  },
});

const outline = definePartsStyle({
  control: {
    borderColor: 'kgray.200',
  },

  container: {
    border: '1px solid',
    borderColor: 'kgray.200',
    borderRadius: 'md',
    padding: 4,

    _checked: {
      bgColor: 'kblue.50',
      borderColor: 'kblue.200',
    },

    _hover: {
      bgColor: 'kblue.50',
    },
  },

  label: {
    marginStart: 4,
  },
});

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {outline},
});
