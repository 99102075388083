import React from 'react';
import {Box, FlexProps, Flex, Button} from '@chakra-ui/react';
import {
  ExportType,
  AccountQuery,
  ExportRequest,
  ExportResponse,
} from '../../../shared/api/api';
import {useAccountQueryParams} from '../AccountGrid.controlstate';
import {UseMutationResult} from '@tanstack/react-query';

type ExportModalSectionProps = FlexProps & {
  children: React.ReactNode;
  buttonText: string;
  exportMutation: UseMutationResult<ExportResponse, unknown, ExportRequest>;
  numberOfAccounts?: number;
  type: ExportType;
  mode?: 'sync' | 'async';
};

export const ExportModalSection = ({
  children,
  buttonText,
  type,
  mode = 'async',
  exportMutation,
  ...props
}: ExportModalSectionProps) => {
  const params = useAccountQueryParams();

  const handleExportClick = async () => {
    let query: AccountQuery | undefined;
    switch (type) {
      case 'filtered_accounts':
        query = params;
        break;
    }

    exportMutation.mutate({
      type,
      query,
      mode,
    });
  };

  return (
    <Flex border="1px" borderColor="kgray.200" p={4} mb={4} {...props}>
      <Box flexGrow="1">{children}</Box>
      <Button
        alignSelf="center"
        border="1px"
        colorScheme="kbuttonblue"
        variant="ghost"
        onClick={handleExportClick}
        isDisabled={exportMutation.isLoading}
        _hover={{
          bg: 'kblue.50',
        }}
        fontSize={14}
        fontWeight={500}
        px={6}
        py={3}
        transitionDuration="200ms"
        transitionProperty="background-color,border-color,color"
      >
        {buttonText}
      </Button>
    </Flex>
  );
};
