import React from 'react';
import {useOnboardingContext} from './Onboarding';
import {Box, Flex, Image, Button} from '@chakra-ui/react';

export const GetStarted = () => {
  const {onPageNavigate} = useOnboardingContext();
  return (
    <Flex
      alignItems={'center'}
      direction={'column'}
      h="100%"
      w="100%"
      gap={5}
      py={5}
      textAlign={'center'}
    >
      <Box fontSize="2xl">Start building your ICP with Keyplay</Box>
      <Image
        flexGrow={1}
        flexBasis={0}
        maxH={'475px'}
        minH={'300px'}
        objectFit="contain"
        src="/Keyplay_Ecosystem.png"
      />
      <Button
        colorScheme="kbuttonblue"
        onClick={() => onPageNavigate('next')}
        variant="solid"
        width="350px"
      >
        Get Started
      </Button>
    </Flex>
  );
};
