import {z} from 'zod';
import {ApiDefinition} from './definitions';
import {IntegrationTypeSchema} from '../integrations';

export const DisconnectIntegration = {
  method: 'post',
  options: {access: 'paid'},
  requestDataSchema: IntegrationTypeSchema,
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const DeleteIntegration = {
  method: 'post',
  options: {access: 'paid'},
  requestDataSchema: IntegrationTypeSchema,
  responseDataSchema: z.void(),
} satisfies ApiDefinition;
