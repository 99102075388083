import React from 'react';
import {Flex, FlexProps} from '@chakra-ui/react';

const StatusBar = ({
  children,
  ...props
}: React.PropsWithChildren<FlexProps>) => {
  return (
    <Flex
      alignItems="center"
      borderRadius="xl"
      bottom="5"
      color="white"
      justifyContent="space-between"
      left="0"
      maxWidth="5xl"
      minH="55px"
      m="auto"
      position="fixed"
      paddingEnd="2"
      paddingStart="4"
      py="2"
      // Add addition right inset to account for the Intercom chat widget
      right="70px"
      width="80%"
      zIndex="1"
      {...props}
    >
      {children}
    </Flex>
  );
};

export default StatusBar;
