import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {
  IntegrationResponse,
  IntegrationsResponse,
  UpdateIntegrationRequest,
} from '../../shared/api/api';
import {keys} from '../../shared/util';
import {IntegrationType} from '../../shared/integrations';
import {CrmField} from '../../shared/integrations';
import {useTaskStatus} from './tasks';
import {TaskState} from '../../shared/task';
import {AllIntegrations} from '../../components/Integrations/AllIntegrations';
import {useIsFreemiumCustomer} from './metadata';
import {useApiMutation} from './api';
import {
  DeleteIntegration,
  DisconnectIntegration,
} from '../../shared/api/integrations';

const integrationKeys = {
  all: ['integrations'],
  get: (name?: string) => [...integrationKeys.all, name],
} as const;

export function useIntegrations(
  options?: UseQueryOptions<IntegrationsResponse>
) {
  const isFreemiumCustomer = useIsFreemiumCustomer();
  const makeApiCall = useKeyplayApi();

  return useQuery<IntegrationsResponse>(
    integrationKeys.all,
    () => makeApiCall('/integrations'),
    {
      ...options,
      enabled: !isFreemiumCustomer,
    }
  );
}

export function useEnabledIntegration() {
  const {data, isLoading, isError} = useIntegrations();
  if (isLoading || isError) {
    return undefined;
  }

  return data[keys(data)[0]];
}

export function useIntegration(name: IntegrationType) {
  const makeApiCall = useKeyplayApi();

  return useQuery(integrationKeys.get(name), () =>
    makeApiCall<IntegrationResponse>(`/integrations/${name}`)
  );
}

export function useUpdateIntegration(name: IntegrationType) {
  const makeApiCall = useKeyplayApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateIntegrationRequest) =>
      makeApiCall(
        `/integrations/${name}`,
        {
          method: 'POST',
          data,
        },
        {
          toastOnError: true,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(integrationKeys.get(name));
    },
  });
}

export function useQueueFullCrmRun() {
  const makeApiCall = useKeyplayApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (name: IntegrationType) =>
      makeApiCall(
        `/integrations/${name}/enrich`,
        {
          method: 'POST',
        },
        {
          toastOnError: true,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(['taskStatus']);
    },
  });
}

export function useCrmFields(name: IntegrationType) {
  const makeApiCall = useKeyplayApi();

  return useQuery(
    ['integration', 'crmFields', name],
    () => makeApiCall<CrmField[]>(`/integrations/${name}/crmFields`),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useIsCrmSyncing(options?: {
  onSyncComplete?: (taskState: TaskState) => void;
}) {
  const integration = useEnabledIntegration();

  const {isTaskInProgress} = useTaskStatus({
    taskType: integration
      ? AllIntegrations[integration.type].taskType
      : undefined,
    onTaskComplete: options?.onSyncComplete,
  });

  return isTaskInProgress;
}

export function useDisconnectIntegration() {
  const queryClient = useQueryClient();
  return useApiMutation('/integrations/disconnect', DisconnectIntegration, {
    onSuccess: (_, name) => {
      queryClient.invalidateQueries(integrationKeys.get(name));
    },
  });
}

export function useDeleteIntegration() {
  const queryClient = useQueryClient();
  return useApiMutation('/integrations/delete', DeleteIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries(integrationKeys.all);
    },
  });
}
