export const ModelTestGroupName = {
  maxLength: 30,
  minLength: 5,
  pattern: /^[a-z0-9 !$+@#%&*()-]+$/i,
};

export const ImportSourceName = {
  maxLength: 30,
  minLength: 5,
  pattern: /^[a-z0-9]+$/i,
};

export const TagName = {
  maxLength: 30,
  minLength: 3,
  pattern: /^[a-z0-9 ]+$/i,
};

export const MaxBulkLookalikeDomains = 250;
