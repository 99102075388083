import React from 'react';
import {
  Flex,
  Spinner,
  Box,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {PlanLabels} from '../../shared/customer';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import _ from 'lodash';
import {Link as ReactRouterLink} from 'react-router-dom';
import {CreateOrEditCustomerButton} from './CreateOrEditCustomerButton';
import {ObjectId} from 'bson';
import {useGetCustomers} from './hooks/customer';
import {z} from 'zod';
import {GetCustomers} from '../../shared/api/definitions';

export const Customers = () => {
  const customers = useGetCustomers({});

  return (
    <>
      <Flex justifyContent="space-between" mb={8}>
        <Box fontSize="2xl">Customers</Box>
        <CreateOrEditCustomerButton />
      </Flex>
      <Flex direction="column">
        {customers.isLoading && <Spinner />}
        {customers.data && <CustomersList customers={customers.data} />}
      </Flex>
    </>
  );
};
type GetCustomersResponse = z.infer<
  (typeof GetCustomers)['responseDataSchema']
>;

const columnHelper = createColumnHelper<GetCustomersResponse[0]>();
const CustomersList = ({customers}: {customers: GetCustomersResponse}) => {
  const columns = [
    columnHelper.accessor(
      (acc): {name: string; id: ObjectId} => {
        return {name: acc.name, id: acc._id};
      },
      {
        header: 'Name',
        cell: (info) => (
          <Link
            as={ReactRouterLink}
            color="kblue.300"
            to={`${info.getValue().id}`}
          >
            {info.getValue().name}
          </Link>
        ),
      }
    ),
    columnHelper.accessor('plan', {
      header: 'Plan',
      cell: (info) => PlanLabels[info.getValue().type],
    }),
  ];

  const table = useReactTable({
    data: customers,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
