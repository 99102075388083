import React from 'react';
import {Box, BoxProps, Text} from '@chakra-ui/react';
import _ from 'lodash';
import {MinSamDefinitionSize} from '../../shared/api/api';
import {GuidanceBar, GuidanceBarMode} from '../GuidanceBar';
import {useSamEditorStore} from './useSamEditorStore';
import {useSamDefinitionLimits} from './useSamDefinitionLimits';
import {useIsFreemiumCustomer} from '../../hooks/api/metadata';

interface SamGuidanceMessageProps extends BoxProps {
  defaultTransparentBg: boolean;
}

export const SamGuidanceMessage = ({
  defaultTransparentBg,
  ...props
}: SamGuidanceMessageProps) => {
  const {hasInitialized, isEmpty} = useSamEditorStore();
  const {isFetching, withinLimits} = useSamDefinitionLimits();
  const isFreemiumCustomer = useIsFreemiumCustomer();

  let mode: GuidanceBarMode = defaultTransparentBg ? 'default' : 'success';
  let samSizeHelperText: React.ReactNode;

  if (isFreemiumCustomer) {
    if (isFetching || withinLimits === undefined) {
      samSizeHelperText = 'Checking list size...';
    } else if (hasInitialized && isEmpty()) {
      samSizeHelperText = 'Set your SAM filters to continue.';
    } else if (!withinLimits) {
      mode = 'warning';
      samSizeHelperText = (
        <>
          Less than
          <Text as="b" mx="1">
            {MinSamDefinitionSize.toLocaleString()}
          </Text>
          accounts matched. Expand your filters to continue.
        </>
      );
    } else {
      samSizeHelperText = "That's a nice size list!";
    }
  } else {
    if (hasInitialized && isEmpty()) {
      samSizeHelperText = 'Set your SAM filters to continue.';
    } else {
      return <></>;
    }
  }

  return (
    <GuidanceBar mode={mode} {...props}>
      <Box>{samSizeHelperText}</Box>
    </GuidanceBar>
  );
};
