import React from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
} from '@chakra-ui/react';
import {useUser} from '../../context/UserContext';

const Section = ({
  children,
  title,
}: React.PropsWithChildren<{title: string}>) => {
  return (
    <Flex direction="column" borderRadius="md" borderWidth="1px" gap="6" p="6">
      <Box fontSize="lg" fontWeight={500}>
        {title}
      </Box>
      {children}
    </Flex>
  );
};

export const Account = () => {
  const user = useUser();

  return (
    <Flex direction="column">
      <Section title="Personal info">
        <Flex flexWrap="wrap">
          <Box h="200px" w="200px" p="4">
            <Image borderRadius="full" src={user.auth0.picture} />
          </Box>
          <Flex direction="column" gap="4" flex="1" flexBasis="xs">
            <FormControl
              color="kgray.400"
              fontSize="sm"
              textTransform="uppercase"
            >
              <FormLabel>Full Name</FormLabel>
              <Input isReadOnly value={user.auth0.name} type="text" />
            </FormControl>
            <FormControl
              color="kgray.400"
              fontSize="sm"
              textTransform="uppercase"
            >
              <FormLabel>Email</FormLabel>
              <Input isReadOnly value={user.auth0.email} type="text" />
            </FormControl>
          </Flex>
        </Flex>
      </Section>
    </Flex>
  );
};
