import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

export interface SavedAccountsContext {
  accountName?: string;
  action: 'save' | 'tag';
  notificationType: 'modal' | 'toast';
  numAccounts: number;
}

interface SavedAccountsStore {
  savedAccountsContext: SavedAccountsContext | null;
  setSavedAccountsContext: (context: SavedAccountsContext | null) => void;
}

export const useSavedAccountsStore = create<SavedAccountsStore>()(
  devtools<SavedAccountsStore>((set) => ({
    savedAccountsContext: null,
    setSavedAccountsContext: (context) => set({savedAccountsContext: context}),
  }))
);
