import {
  GetNumAccountsToEnrichDefinition,
  RunEnrichmentDefinition,
} from '../../shared/api/definitions';
import {useApiMutation} from './api';

export const useNumAccountsToEnrich = () => {
  return useApiMutation(
    '/numAccountsToEnrich',
    GetNumAccountsToEnrichDefinition
  );
};

export const useRunEnrichment = () => {
  return useApiMutation('/runEnrichment', RunEnrichmentDefinition);
};
