import React from 'react';
import {Box, Flex} from '@chakra-ui/react';
import {ChakraArrowRight} from './Icons/Carbon';

export const DrawerCloser = ({onClose}: {onClose: () => void}) => {
  return (
    <Flex
      height="40px"
      width="24px"
      position="absolute"
      top="12px"
      marginLeft="-24px"
      borderWidth="1px"
      borderColor="kgray.200"
      borderTopLeftRadius="4px"
      borderBottomLeftRadius="4px"
      borderRight="0px"
      backgroundColor="white"
      direction="column"
      justifyContent="space-around"
      onClick={onClose}
      cursor="pointer"
      _hover={{backgroundColor: 'kgray.100'}}
    >
      <Box
        boxSize="16px"
        borderRightWidth="1px"
        borderRightColor="kgray.300"
        ml="4px"
      >
        <ChakraArrowRight color="kgray.300" boxSize="14px" mt="1px" />
      </Box>
    </Flex>
  );
};
