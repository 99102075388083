import {
  CreateCheckoutSession,
  GetCreditPrices,
} from '../../shared/api/definitions';
import {createApiQuery, useApiMutation} from './api';

export const useGetCreditPrices = createApiQuery(
  '/creditPrices',
  GetCreditPrices,
  () => ['creditPrices']
);

export function useCreateCheckoutSession() {
  return useApiMutation('/createSession', CreateCheckoutSession);
}
