import {Flex, chakra} from '@chakra-ui/react';

/** Helper component for wrapping the content of page that takes of the entire screen. */
export const WrapperFlex = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    height: '100%',
    inset: 0,
    position: 'absolute',
    width: '100%',
  },
});

/** Helper component for the scrollable content of page when wrapped by a WrapperFlex. */
export const ScrollableFlex = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
    paddingInline: 10,
    paddingBottom: 4,
  },
});
