import _ from 'lodash';
import {KeyplayScoringSignal, ResolvedScoringSignal} from './signals';
import {zodEnum, keys} from './util';
import {AccountTier, CustomerAccountSource, Tag} from './scoredAccounts';
import {AccountFieldLabels} from './fields';
import {
  BusinessAudience,
  PrimaryBusinessCategory,
  SecondaryBusinessCategory,
} from './account';
import {WithId} from './util';
import {z} from 'zod';
import {zodTypeguard} from './api/helpers';
import {KeyplayStatus} from './accountStatus';
import {ObjectId} from 'bson';
import {CountryCode} from './countries';

export interface EnrichmentFieldTypes {
  ats: string | undefined;
  audience: BusinessAudience[] | undefined;
  businessOverview: string | undefined;
  employeeCount: number | undefined;
  finalDomain: string | undefined;
  finalLinkedIn: string | undefined;
  fit: number;
  hiring: number | undefined;
  hiringEng: number | undefined;
  hiringSales: number | undefined;
  hiringSoftwareEng: number | undefined;
  hqCity: string | undefined;
  hqCountry: CountryCode | undefined;
  hqPostalCode: string | undefined;
  hqState: string | undefined;
  inSam: boolean | undefined;
  jobUrl: string | undefined;
  lastFundingDate: string | undefined;
  linkedInJobCount: number | undefined;
  mostSimilarTo: string | undefined;
  name: string | undefined;
  oneMonthHeadcountGrowth: number | undefined;
  oneMonthHiringEngChange: number | undefined;
  oneMonthHiringHRChange: number | undefined;
  oneMonthHiringProductChange: number | undefined;
  oneMonthOpenRolesChange: number | undefined;
  oneMonthRecruitingVelocityChange: number | undefined;
  oneMonthTotalFundingChange: number | undefined;
  openRolePercentage: number | undefined;
  org: ResolvedScoringSignal[];
  primaryCategory: PrimaryBusinessCategory[] | undefined;
  profile: ResolvedScoringSignal[];
  recruitingVelocity: number | undefined;
  relevance: ResolvedScoringSignal[];
  secondaryCategory: SecondaryBusinessCategory[] | undefined;
  signals: ResolvedScoringSignal[];
  signalScore: number | undefined;
  similarityScore: number | undefined;
  sixMonthHeadcountGrowth: number | undefined;
  slackCommunityPage: string | undefined;
  source: CustomerAccountSource | undefined;
  stackFit: ResolvedScoringSignal[];
  status: KeyplayStatus;
  tags: WithId<Tag>[];
  tier: AccountTier | undefined;
  totalFundingRaised: number | undefined;
  yearFounded: number | undefined;

  // customer specific
  iterable_b2bSignals: KeyplayScoringSignal[];
  iterable_b2cSignals: KeyplayScoringSignal[];
}

export const EnrichmentFieldLabels = {
  ats: 'ATS Used',
  audience: 'Audience',
  businessOverview: 'Business Overview',
  employeeCount: AccountFieldLabels.employeeCount,
  finalDomain: AccountFieldLabels.finalDomain,
  finalLinkedIn: AccountFieldLabels.finalLinkedIn,
  fit: 'Overall Fit',
  hiring: AccountFieldLabels.hiring,
  hiringEng: AccountFieldLabels.hiringEng,
  hiringSales: AccountFieldLabels.hiringSales,
  hiringSoftwareEng: AccountFieldLabels.hiringSoftwareEng,
  hqCity: AccountFieldLabels.hqCity,
  hqCountry: AccountFieldLabels.hqCountry,
  hqPostalCode: AccountFieldLabels.hqPostalCode,
  hqState: AccountFieldLabels.hqState,
  inSam: 'In SAM',
  jobUrl: 'Job URL',
  lastFundingDate: AccountFieldLabels.lastFundingDate,
  linkedInJobCount: AccountFieldLabels.linkedInJobCount,
  mostSimilarTo: 'Most Similar To',
  name: AccountFieldLabels.name,
  oneMonthHeadcountGrowth: AccountFieldLabels.oneMonthHeadcountGrowth,
  oneMonthHiringEngChange: AccountFieldLabels.oneMonthHiringEngChange,
  oneMonthHiringHRChange: AccountFieldLabels.oneMonthHiringHRChange,
  oneMonthHiringProductChange: AccountFieldLabels.oneMonthHiringProductChange,
  oneMonthOpenRolesChange: AccountFieldLabels.oneMonthOpenRolesChange,
  oneMonthRecruitingVelocityChange:
    AccountFieldLabels.oneMonthRecruitingVelocityChange,
  oneMonthTotalFundingChange: AccountFieldLabels.oneMonthTotalFundingChange,
  openRolePercentage: AccountFieldLabels.openRolePercentage,
  org: 'Org Signals',
  primaryCategory: 'Primary Categories',
  profile: 'Profile Signals',
  recruitingVelocity: AccountFieldLabels.recruitingVelocity,
  relevance: 'Relevance Signals',
  secondaryCategory: 'Secondary Categories',
  signals: 'Signals Matched',
  signalScore: 'Signal Score',
  similarityScore: 'Similarity Score',
  sixMonthHeadcountGrowth: AccountFieldLabels.sixMonthGrowth,
  slackCommunityPage: AccountFieldLabels.slackCommunityPage,
  source: 'Source',
  stackFit: 'Stack Signals',
  status: 'Status',
  tags: 'Tags',
  tier: 'Tier',
  totalFundingRaised: AccountFieldLabels.totalFundingRaised,
  yearFounded: 'Year Founded',

  // Note: These must match the corresponding rollup names.
  iterable_b2bSignals: 'B2B Signals',
  iterable_b2cSignals: 'B2C Signals',
} as const satisfies Record<keyof EnrichmentFieldTypes, string>;

export const EnrichmentFields = keys(EnrichmentFieldLabels);
export const EnrichmentFieldSchema = zodEnum(EnrichmentFields);
export type EnrichmentField = z.infer<typeof EnrichmentFieldSchema>;

// Managed enrichment fields are enabled by default and automatically created/updated by Keyplay.
const ManagedEnrichmentFields = [
  // Note: The order here determines the order in which fields are displayed in the UI.
  'finalDomain',
  'tier',
  'fit',
  'signals',
  'primaryCategory',
  'secondaryCategory',
  'audience',
  'tags',
  'profile',
  'org',
  'relevance',
  'stackFit',
  'source',
  'status',
  'signalScore',
  'similarityScore',
  'mostSimilarTo',
  'hqCountry',
  'inSam',

  'iterable_b2bSignals',
  'iterable_b2cSignals',
] as const satisfies readonly EnrichmentField[];

export const ManagedEnrichmentFieldSchema = zodEnum(ManagedEnrichmentFields);
export type ManagedEnrichmentField = z.infer<
  typeof ManagedEnrichmentFieldSchema
>;
export const isManagedEnrichmentField = zodTypeguard(
  ManagedEnrichmentFieldSchema
);

// Customer-specific managed fields.
export const OptionalManagedEnrichmentFields = [
  'profile',
  'org',
  'relevance',
  'stackFit',

  'iterable_b2bSignals',
  'iterable_b2cSignals',
] as const satisfies readonly ManagedEnrichmentField[];

// Available to all customers.
export const CoreManagedEnrichmentFields = _.difference(
  ManagedEnrichmentFields,
  OptionalManagedEnrichmentFields
);

export const ManagedEnrichmentFieldCrmNames = {
  audience: 'keyplay_audience',
  finalDomain: 'keyplay_final_domain',
  fit: 'keyplay_fit',
  hqCountry: 'keyplay_hq_country',
  inSam: 'keyplay_in_sam',
  mostSimilarTo: 'keyplay_similar_accounts',
  org: 'keyplay_org_signals',
  primaryCategory: 'keyplay_primary_categories',
  profile: 'keyplay_profile_signals',
  relevance: 'keyplay_relevance_signals',
  secondaryCategory: 'keyplay_secondary_categories',
  signalScore: 'keyplay_signal_score',
  signals: 'keyplay_signals',
  similarityScore: 'keyplay_similarity_score',
  source: 'keyplay_source',
  stackFit: 'keyplay_stack_signals',
  status: 'keyplay_status',
  tags: 'keyplay_tags',
  tier: 'keyplay_tier',

  iterable_b2bSignals: 'keyplay_iterable_b2b_signals',
  iterable_b2cSignals: 'keyplay_iterable_b2c_signals',
} as const satisfies Record<ManagedEnrichmentField, string>;

export const ManagedEnrichmentFieldCrmLabels = {
  audience: 'Keyplay Audience',
  finalDomain: 'Keyplay Final Domain',
  fit: 'Keyplay Fit Score',
  hqCountry: 'Keyplay HQ Country',
  inSam: 'Keyplay In SAM',
  mostSimilarTo: 'Keyplay Most Similar To',
  org: 'Keyplay Org Signals',
  primaryCategory: 'Keyplay Categories',
  profile: 'Keyplay Profile Signals',
  relevance: 'Keyplay Relevance Signals',
  secondaryCategory: 'Keyplay Secondary Categories',
  signals: 'Keyplay Signals',
  signalScore: 'Keyplay Signal Score',
  similarityScore: 'Keyplay Similarity Score',
  source: 'Keyplay Source',
  stackFit: 'Keyplay Stack Signals',
  status: 'Keyplay Status',
  tags: 'Keyplay Tags',
  tier: 'Keyplay Tier',

  iterable_b2bSignals: 'Keyplay B2B Signals',
  iterable_b2cSignals: 'Keyplay B2C Signals',
} as const satisfies Record<ManagedEnrichmentField, string>;

const AdditionalEnrichmentFields = [
  'ats',
  'businessOverview',
  'employeeCount',
  'finalDomain',
  'finalLinkedIn',
  'fit',
  'hiring',
  'hiringEng',
  'hiringSales',
  'hiringSoftwareEng',
  'hqCity',
  'hqCountry',
  'hqPostalCode',
  'hqState',
  'jobUrl',
  'lastFundingDate',
  'linkedInJobCount',
  'name',
  'oneMonthHeadcountGrowth',
  'oneMonthHiringEngChange',
  'oneMonthHiringHRChange',
  'oneMonthHiringProductChange',
  'oneMonthOpenRolesChange',
  'oneMonthRecruitingVelocityChange',
  'oneMonthTotalFundingChange',
  'openRolePercentage',
  'recruitingVelocity',
  'secondaryCategory',
  'signals',
  'signalScore',
  'similarityScore',
  'sixMonthHeadcountGrowth',
  'slackCommunityPage',
  'tier',
  'totalFundingRaised',
  'yearFounded',
] as const satisfies readonly EnrichmentField[];

export const AdditionalEnrichmentField = zodEnum(AdditionalEnrichmentFields);
export type AdditionalEnrichmentField = z.infer<
  typeof AdditionalEnrichmentField
>;
export const isAdditionalEnrichmentField = zodTypeguard(
  AdditionalEnrichmentField
);

// Customer-specific additional fields.
export const OptionalAdditionalEnrichmentFields = [
  'linkedInJobCount',
  'slackCommunityPage',
] as const satisfies readonly AdditionalEnrichmentField[];

// Available to all customers.
export const CoreAdditionalEnrichmentFields = _.difference(
  AdditionalEnrichmentFields,
  OptionalAdditionalEnrichmentFields
);

// Managed fields are fully managed by Keyplay.
// Additional fields are managed by the customer.
// - They can either be built-in fields (e.g. employee count) or custom fields.
const EnrichmentFieldTypeSchema = z.union([
  z.literal('managed'),
  z.literal('additional'),
]);
export type EnrichmentFieldType = z.infer<typeof EnrichmentFieldTypeSchema>;

export const MultiMarketFields = [
  'fit',
  'similarityScore',
  'signalScore',
  'tier',
] satisfies readonly AdditionalEnrichmentField[];

const EnrichmentFieldBaseConfigSchema = z.object({
  crmFieldName: z.string(),
  enrichmentFieldType: EnrichmentFieldTypeSchema,
  enabled: z.boolean(),
  kind: z.string(),
  marketId: z.instanceof(ObjectId).optional(),
});

// Managed enrichment field config types.

const ManagedEnrichmentFieldConfigSchema =
  EnrichmentFieldBaseConfigSchema.extend({
    enrichmentFieldType: z.literal('managed'),
    enrichmentField: ManagedEnrichmentFieldSchema,
    kind: z.literal('keyplay'),
  });
export type ManagedEnrichmentFieldConfig = z.infer<
  typeof ManagedEnrichmentFieldConfigSchema
>;
export function isManagedEnrichmentFieldConfig(
  config: EnrichmentFieldConfig
): config is ManagedEnrichmentFieldConfig {
  return config.enrichmentFieldType === 'managed' && config.kind === 'keyplay';
}

// Additional enrichment field config types.
const FieldOverrideSchema = z.union([
  // Always override value.
  z.literal('always'),
  // Override value if the field is empty.
  z.literal('ifEmpty'),
]);
export type FieldOverride = z.infer<typeof FieldOverrideSchema>;

const AdditionalEnrichmentFieldConfigBaseSchema =
  EnrichmentFieldBaseConfigSchema.extend({
    enrichmentFieldType: z.literal('additional'),
    override: FieldOverrideSchema,
  });

const AdditionalKeyplayEnrichmentFieldConfigSchema =
  AdditionalEnrichmentFieldConfigBaseSchema.extend({
    kind: z.literal('keyplay'),
    enrichmentField: AdditionalEnrichmentField,
  });
export type AdditionalKeyplayEnrichmentFieldConfig = z.infer<
  typeof AdditionalKeyplayEnrichmentFieldConfigSchema
>;
export function isAdditionalKeyplayEnrichmentFieldConfig(
  config: EnrichmentFieldConfig
): config is AdditionalKeyplayEnrichmentFieldConfig {
  return (
    config.enrichmentFieldType === 'additional' && config.kind === 'keyplay'
  );
}

const CustomEnrichmentFieldConfigSchema =
  AdditionalEnrichmentFieldConfigBaseSchema.extend({
    kind: z.literal('custom'),
    customFieldId: z.instanceof(ObjectId),
  });
export type CustomEnrichmentFieldConfig = z.infer<
  typeof CustomEnrichmentFieldConfigSchema
>;
export function isCustomEnrichmentFieldConfig(
  config: EnrichmentFieldConfig
): config is CustomEnrichmentFieldConfig {
  return (
    config.enrichmentFieldType === 'additional' && config.kind === 'custom'
  );
}

const AdditionalEnrichmentFieldConfigSchema = z.union([
  AdditionalKeyplayEnrichmentFieldConfigSchema,
  CustomEnrichmentFieldConfigSchema,
]);
export type AdditionalEnrichmentFieldConfig = z.infer<
  typeof AdditionalEnrichmentFieldConfigSchema
>;
export function isAdditionalEnrichmentFieldConfig(
  config: EnrichmentFieldConfig
): config is AdditionalEnrichmentFieldConfig {
  return config.enrichmentFieldType === 'additional';
}

// Type guard for strictly Keyplay enrichment fields (managed or additional).
export function isKeyplayEnrichmentFieldConfig(
  config: EnrichmentFieldConfig
): config is
  | AdditionalKeyplayEnrichmentFieldConfig
  | ManagedEnrichmentFieldConfig {
  return config.kind === 'keyplay';
}

export const EnrichmentFieldConfigSchema = z.union([
  AdditionalEnrichmentFieldConfigSchema,
  ManagedEnrichmentFieldConfigSchema,
]);
export type EnrichmentFieldConfig = z.infer<typeof EnrichmentFieldConfigSchema>;

export const OneWaySyncSettingSchema = z.object({
  type: z.literal('oneWay'),
});
export type OneWaySyncSetting = z.infer<typeof OneWaySyncSettingSchema>;

export const TwoWaySyncSettingSchema = z.object({
  type: z.literal('twoWay'),
  pushSavedAccounts: z.boolean(),
});
export type TwoWaySyncSetting = z.infer<typeof TwoWaySyncSettingSchema>;

export const SelectiveTwoWaySyncSettingSchema = z.object({
  type: z.literal('selectiveTwoWay'),
  pushSavedAccounts: z.boolean(),
});
export type SelectiveTwoWaySyncSetting = z.infer<
  typeof SelectiveTwoWaySyncSettingSchema
>;

export const SyncSettingsSchema = z.union([
  OneWaySyncSettingSchema,
  TwoWaySyncSettingSchema,
  SelectiveTwoWaySyncSettingSchema,
]);
export type SyncSettings = z.infer<typeof SyncSettingsSchema>;
export type SyncType = SyncSettings['type'];

export const IntegrationConfigSchema = z.object({
  autoSync: z.boolean().optional(),
  syncSettings: SyncSettingsSchema.optional(),
  fieldMapping: z.array(EnrichmentFieldConfigSchema).optional(),
  optionalFields: z.array(EnrichmentFieldSchema).optional(),
});
export type IntegrationConfig = z.infer<typeof IntegrationConfigSchema>;

// IntegrationStatus Schema
export const IntegrationStatusSchema = z.enum([
  'active',
  'disconnected',
  'refresh_token_expired',
]);
export type IntegrationStatus = z.infer<typeof IntegrationStatusSchema>;

export const CrmSyncDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
] as const;
export const CrmSyncDaySchema = zodEnum(CrmSyncDays);
export type CrmSyncDay = z.infer<typeof CrmSyncDaySchema>;

export const BaseOAuthDataSchema = z.object({
  issuedAt: z.date(),
  refreshToken: z.string(),
  tokenType: z.string(),
});

export const IntegrationTypeSchema = z.union([
  z.literal('hubspot'),
  z.literal('salesforce'),
]);
export type IntegrationType = z.infer<typeof IntegrationTypeSchema>;
export const isIntegrationType = zodTypeguard(IntegrationTypeSchema);

const BaseCrmIntegrationSchema = z.object({
  id: z.instanceof(ObjectId),
  timestamp: z.date(),
  type: IntegrationTypeSchema,

  oauth: BaseOAuthDataSchema,
  crmSyncDay: CrmSyncDaySchema,
  status: IntegrationStatusSchema,

  config: IntegrationConfigSchema.optional(),
  lastEnrichment: z.date().optional(),
});

const SalesforceIntegrationSchema = BaseCrmIntegrationSchema.extend({
  type: z.literal('salesforce'),
  oauth: BaseOAuthDataSchema.extend({
    id: z.string(),
    instanceUrl: z.string(),
    scope: z.string(),
    signature: z.string(),
    isSandbox: z.boolean().optional(),
  }),
});

const HubSpotIntegrationSchema = BaseCrmIntegrationSchema.extend({
  type: z.literal('hubspot'),
  oauth: BaseOAuthDataSchema.extend({
    expiresIn: z.number(),
  }),
});

export type SalesforceIntegration = z.infer<typeof SalesforceIntegrationSchema>;
export type HubSpotIntegration = z.infer<typeof HubSpotIntegrationSchema>;

export type CrmIntegration = SalesforceIntegration | HubSpotIntegration;

export interface CrmField {
  name: string; // The unique name of the field.
  label: string; // The display label of the field.
  type:
    | 'binary'
    | 'boolean'
    | 'date'
    | 'dateTime'
    | 'number'
    | 'string'
    | 'time';
}

export const AllowedCrmFieldTypes = [
  'date',
  'dateTime',
  'number',
] as const satisfies CrmField['type'][];
type AllowedCrmFieldType = (typeof AllowedCrmFieldTypes)[number];

export const isAllowedCrmFieldType = (
  type: CrmField['type']
): type is AllowedCrmFieldType =>
  AllowedCrmFieldTypes.includes(type as AllowedCrmFieldType);

const EnrichErrorSchema = z.object({
  type: z.literal('enrichError'),
  numFailedAccounts: z.number(),
});
export const SalesforceEnrichErrorSchema = EnrichErrorSchema.extend({
  details: z.object({
    integration: z.literal('salesforce'),
    jobId: z.string(),
  }),
});
export const PushSavedAccountsErrorSchema = z.object({
  type: z.literal('pushSavedAccountsError'),
  error: z.string(),
  numFailedAccounts: z.number(),
});
export const UnexpectedErrorSchema = z.object({
  type: z.literal('unexpectedError'),
  error: z.string(),
});

export const IntegrationErrorSchema = z.union([
  SalesforceEnrichErrorSchema,
  PushSavedAccountsErrorSchema,
  UnexpectedErrorSchema,
]);
export type IntegrationError = z.infer<typeof IntegrationErrorSchema>;
export const isIntegrationError = zodTypeguard(IntegrationErrorSchema);

const IntegrationRunSchema = z.object({
  customerId: z.instanceof(ObjectId),
  integrationId: z.instanceof(ObjectId),
  errors: IntegrationErrorSchema.array(),
  status: z.union([z.literal('success'), z.literal('failure')]),
  taskId: z.instanceof(ObjectId),
  timestamp: z.date(),
});
export type IntegrationRun = z.infer<typeof IntegrationRunSchema>;
