import {useQueryClient} from '@tanstack/react-query';
import {createApiQuery, useApiMutation} from './api';
import {
  DeleteModelTestGroup,
  GetModelTestDefinition,
  GetModelTestGroupResults,
  GetModelTestResults,
  PostModelTestGroup,
} from '../../shared/api/definitions';

export const ModelTestResultsCacheKey = 'modelTestResults';
export const ModelTestResultsKeys = {
  all: [ModelTestResultsCacheKey],
  market: (marketId: string) => [...ModelTestResultsKeys.all, marketId],
} as const;
export const useGetModelTestResults = createApiQuery(
  '/modelTestResults',
  GetModelTestResults,
  ({marketId}) => ModelTestResultsKeys.market(marketId)
);

export const ModelTestGroupCacheKey = 'modelTestGroupResults';
export const ModelTestGroupKeys = {
  all: [ModelTestGroupCacheKey],
  market: (marketId: string) => [...ModelTestGroupKeys.all, marketId],
  group: ({groupId, marketId}: {marketId: string; groupId: string}) => [
    ...ModelTestGroupKeys.market(marketId),
    groupId,
  ],
} as const;

export const useGetModelTestGroupResults = createApiQuery(
  '/modelTestGroupResults',
  GetModelTestGroupResults,
  ({groupId, marketId}) => ModelTestGroupKeys.group({marketId, groupId}),
  {
    staleTime: 1000 * 60 * 60, // keep data for an hour unless query is invalidated
  }
);

export const ModelTestDefinitionCacheKey = 'modelTestDefinition';
export const ModelTestDefinitionKeys = {
  all: [ModelTestDefinitionCacheKey],
  market: (marketId: string) => [...ModelTestDefinitionKeys.all, marketId],
} as const;

export const useGetModelTestDefinition = createApiQuery(
  '/modelTestDefinition',
  GetModelTestDefinition,
  ({marketId}) => ModelTestDefinitionKeys.market(marketId),
  {
    staleTime: 1000 * 60 * 60, // keep data for an hour unless query is invalidated
    refetchInterval: (data) =>
      data?.groups.some((group) => group.status === 'pending') ? 5_000 : 0,
  }
);

export const usePublishModelTestGroup = () => {
  const queryClient = useQueryClient();

  return useApiMutation('/modelTestGroup', PostModelTestGroup, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(ModelTestDefinitionKeys.all);
    },
  });
};

export const useDeleteModelTestGroup = () => {
  const queryClient = useQueryClient();

  return useApiMutation('/deleteModelTestGroup', DeleteModelTestGroup, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(ModelTestDefinitionKeys.all);
    },
  });
};
