import React from 'react';
import {Box, useTheme} from '@chakra-ui/react';
import {entries} from '../../../shared/util';
import {PieChart, Pie, Cell, Tooltip} from 'recharts';
import {TierCounts} from '../../../shared/scoring';

export const TierPieChart = ({tierCounts}: {tierCounts: TierCounts}) => {
  const theme = useTheme();
  const colors = {
    A: theme.colors.kgreen[400],
    B: theme.colors.kgreen[200],
    C: theme.colors.kyellow[400],
    D: theme.colors.kred[400],
  };

  const data = entries(tierCounts)
    .map(([tier, value]) => ({
      tier,
      value,
    }))
    .filter(({value}) => value > 0);

  return (
    <PieChart width={50} height={50}>
      <Pie data={data} innerRadius={12} outerRadius={24} dataKey="value">
        {data.map(({tier}) => (
          <Cell key={`cell-${tier}`} fill={colors[tier]} />
        ))}
      </Pie>
      <Tooltip
        content={({active, payload, coordinate}) => {
          const {x, y} = coordinate ?? {};
          const item = payload?.[0]?.payload;

          if (!active || !item || !x || !y) {
            return null;
          }

          return (
            <Box
              bg="white"
              border="1px solid #E8E8EB"
              p={1}
              transform={`translate(${x}px, ${y - 40}px)`}
            >{`Tier ${item.tier} - ${item.value}`}</Box>
          );
        }}
      />
    </PieChart>
  );
};
