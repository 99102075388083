import _ from 'lodash';
import {useIsMyAccountsView, useUserControls} from './AccountGrid.controlstate';
import {useScoredAccounts} from '../Hooks/scoredAccounts';
import {entries} from '../../shared/util';
import {useCustomer} from '../../hooks/api/metadata';
import {getCurrentValue} from '../../shared/customer';

export const NumAutoSelectAccounts = 3;

let rowSelectionMode: 'autoSelect' | 'userSelect' | undefined;
export function useShouldAutoSelectRows() {
  const customer = useCustomer();
  const accountsSaved = getCurrentValue(customer, 'saves');
  const isMyAccountsView = useIsMyAccountsView();

  const {numBulkSelected, rowSelection} = useUserControls();
  const {fetchedAccounts} = useScoredAccounts();

  if (
    isMyAccountsView ||
    customer.plan.type !== 'free' ||
    rowSelectionMode === 'userSelect' ||
    fetchedAccounts.length === 0
  ) {
    return false;
  }

  if (accountsSaved || numBulkSelected) {
    // If the user saves an account or bulk selects accounts, disable auto-select
    rowSelectionMode = 'userSelect';
    return false;
  }

  const selectedAccountIds = entries(rowSelection)
    .filter(([, selected]) => selected)
    .map(([accountId]) => accountId)
    .sort();

  if (!rowSelectionMode) {
    // if we don't have a selection mode yet, set it
    rowSelectionMode =
      selectedAccountIds.length === 0 ? 'autoSelect' : 'userSelect';
  } else if (
    selectedAccountIds.length !== 0 &&
    selectedAccountIds.length !== 3
  ) {
    // otherwise we know we're no longer in auto select if num selected changed
    rowSelectionMode = 'userSelect';
  }

  return rowSelectionMode === 'autoSelect';
}
