import React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {useAuth0} from '@auth0/auth0-react';
import {isKeyplayAdmin} from '../../../lib/user';
import {AdminDiscoverExport} from './AdminDiscoverExport';
import {MyAccountsExport} from './MyAccountsExport';
import {useExportMutation} from '../useExportMutation';
import {useIsMyAccountsView} from '../AccountGrid.controlstate';

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
  numberOfAccounts?: number;
}

export const ExportModal = ({
  isOpen,
  onClose,
  numberOfAccounts,
}: ExportModalProps) => {
  const {user} = useAuth0();
  const isMyAccountsView = useIsMyAccountsView();

  const showAdminDiscoverModal = !isMyAccountsView && isKeyplayAdmin(user);

  const exportMutation = useExportMutation({
    onSuccess: (result, request) => {
      if (request.mode === 'async') {
        onClose();
      }
    },
  });

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="800" p={4}>
        <ModalHeader textAlign="center" fontSize="xl">
          What would you like to export?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {showAdminDiscoverModal ? (
            <AdminDiscoverExport
              exportMutation={exportMutation}
              numberOfAccounts={numberOfAccounts}
            />
          ) : (
            <MyAccountsExport
              exportMutation={exportMutation}
              numberOfAccounts={numberOfAccounts}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
