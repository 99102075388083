import React from 'react';
import {
  AbsoluteCenter,
  Box,
  Flex,
  Image,
  Link,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import {Link as ReactRouterLink} from 'react-router-dom';
import {Bold} from '../text';

export const ErrorPage = () => {
  return (
    <>
      <Flex align="center" bgColor="kgray.400" h="50" px="4">
        <Box as={ReactRouterLink} to="/">
          <Image height="30px" src="/Keyplay_Primary_Logo_WhiteText.svg" />
        </Box>
      </Flex>
      <AbsoluteCenter axis="both">
        <Flex direction="column" gap="6">
          <Bold fontSize="lg">Our Website is Currently Unavailable</Bold>
          <Box>
            We&apos;re experiencing some technical issues at the moment, but our
            team is working hard to get everything back up and running as soon
            as possible.
          </Box>
          <Box>
            Please try the following:
            <UnorderedList>
              <ListItem>Refresh the page after a few minutes.</ListItem>
              <ListItem>
                Contact our support team at{' '}
                <Link
                  color="kblue.300"
                  href="mailto:support@keyplay.io"
                  isExternal
                >
                  support@keyplay.io
                </Link>{' '}
                if you need immediate assistance.
              </ListItem>
            </UnorderedList>
          </Box>
          <Box>Thank you for your patience and understanding!</Box>
        </Flex>
      </AbsoluteCenter>
    </>
  );
};
