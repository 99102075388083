import React, {useState} from 'react';
import {useCustomer} from '../../hooks/api/metadata';
import {PlanLabels, getSavesQuota} from '../../shared/customer';
import {
  Flex,
  Text,
  Progress,
  FlexProps,
  Spinner,
  RadioGroup,
  Radio,
  VStack,
  Button,
  StackDivider,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Link,
  useDisclosure,
  FormControl,
  HStack,
} from '@chakra-ui/react';
import {
  useCreateCheckoutSession,
  useGetCreditPrices,
} from '../../hooks/api/listBuilder';
import _ from 'lodash';
import {EventNames, analytics} from '../../analytics';
import {Bold} from '../text';
import {ChakraAddFilled} from '../Icons/Carbon';
import {BonusCodeInput, BonusCodeLength} from './BonusCodeInput';
import {useClaimBonusCodeMutation} from '../../hooks/api/bonus';
import {FreshCreditsContent} from '../Discover';

export const CreditUsage = ({...props}: FlexProps) => {
  const customer = useCustomer();
  const {current, limit} = getSavesQuota(customer);
  if (!limit) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      gap="2"
      role="group"
      whiteSpace="nowrap"
      {...props}
    >
      <Progress
        borderRadius="sm"
        colorScheme="kred"
        display={{base: 'none', md: 'block'}}
        max={limit}
        size="xs"
        value={current}
        w="50px"
      />
      <Flex alignItems="center" gap="2">
        <Text
          fontSize="sm"
          display={{base: 'none', md: 'block'}}
          _groupHover={{color: 'kgray.200'}}
        >
          {current.toLocaleString()} / {limit.toLocaleString()} credits
        </Text>
        <ChakraAddFilled
          color="kred.300"
          _groupActive={{color: 'kred.200'}}
          _groupHover={{color: 'kred.200'}}
        />
      </Flex>
    </Flex>
  );
};

export const CreditUsageWithModal = () => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <CreditUsage onClick={onOpen} _hover={{cursor: 'pointer'}} />
      <CreditsPurchaseModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export const CreditsPurchaseModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const customer = useCustomer();
  const [contentMode, setContentMode] = useState<
    'purchase' | 'bonus' | 'success'
  >('purchase');

  const closeHandler = () => {
    onClose();
    setTimeout(() => {
      setContentMode('purchase');
    }, 250);
  };

  let header, content;
  switch (contentMode) {
    case 'purchase':
      header = `You're on a ${PlanLabels[customer.plan.type]} plan`;
      content = (
        <Flex direction="column" gap="4">
          <CreditPricesHeader />
          <CreditPrices showBonusUx={() => setContentMode('bonus')} />
        </Flex>
      );
      break;

    case 'bonus':
      header = 'Redeem your bonus credits';
      content = (
        <BonusCodeContent onSuccess={() => setContentMode('success')} />
      );
      break;

    case 'success':
      header = "You've got fresh credits! 🎉";
      content = <FreshCreditsContent />;
      break;

    default:
      break;
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeHandler} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb={2} textAlign="center">
            {header}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{content}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const BonusCodeContent = ({onSuccess}: {onSuccess: () => void}) => {
  const [bonusCode, setBonusCode] = useState('');
  const claimMutation = useClaimBonusCodeMutation();

  return (
    <VStack mb={4} mt={4}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          claimMutation.mutate(
            {bonusCode},
            {
              onSuccess,
            }
          );
        }}
      >
        <HStack mb={6} spacing={0}>
          <FormControl>
            <BonusCodeInput
              onCodeChange={(code) => {
                setBonusCode(code);
              }}
            />
          </FormControl>
          <Button
            type="submit"
            isDisabled={bonusCode.length !== BonusCodeLength}
            colorScheme="kbuttonblue"
          >
            Redeem
          </Button>
        </HStack>
      </form>
      <Text>
        <strong>Looking for a free code?</strong>
      </Text>
      <Text lineHeight={1}>
        <Link
          isExternal={true}
          href="https://www.linkedin.com/posts/adamschoenfeld_sales-marketing-abm-activity-7211395368884846593-EACC/?utm_source=share&utm_medium=member_desktop"
          color="kblue.500"
        >
          Comment on this LinkedIn post
        </Link>{' '}
        and we&apos;ll send yours.
      </Text>
    </VStack>
  );
};

const CreditPricesHeader = () => {
  const customer = useCustomer();
  const {remaining: remainingSaves} = getSavesQuota(customer);

  let contentText;
  if (customer.plan.type === 'listBuilder') {
    const planEndDate = customer.plan.endDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    });
    contentText = (
      <>
        You have <Bold>{remainingSaves.toLocaleString()}</Bold> credits left,
        expiring {planEndDate}. Buy another credit pack to save &amp; export
        more accounts.
      </>
    );
  } else {
    contentText = (
      <>
        You have <Bold>{remainingSaves.toLocaleString()}</Bold> credits left.
        Select a credit pack below to save, export, and sync more accounts.
      </>
    );
  }

  return (
    <Flex direction="column" gap="2">
      <Text color="kgray.300" fontSize="sm">
        {contentText}
      </Text>
      <Bold color="kgray.300" fontSize="sm">
        This is NOT a subscription, just pay as you go.
      </Bold>
    </Flex>
  );
};

const CreditPrices = ({showBonusUx}: {showBonusUx: () => void}) => {
  const creditPrices = useGetCreditPrices({});
  const [selectedPrice, setSelectedPrice] = useState<string | undefined>();
  const createCheckoutSession = useCreateCheckoutSession();

  if (creditPrices.isInitialLoading) {
    return (
      <Center py="8">
        <Spinner />
      </Center>
    );
  }

  const bonusPrice = 'bonus';
  const handlePurchaseCredits = () => {
    if (selectedPrice === bonusPrice) {
      showBonusUx();
      return;
    }

    if (!selectedPrice || !creditPrices.data) {
      return;
    }

    const selectedCreditPrice = creditPrices.data.find(
      (price) => price.priceId === selectedPrice
    );
    analytics.track(EventNames.listBuilderCreditsPurchaseClicked, {
      amount: selectedCreditPrice?.amount,
      credits: selectedCreditPrice?.credits,
    });

    createCheckoutSession.mutate(
      {priceId: selectedPrice},
      {
        onSuccess: (data) => {
          window.location.assign(data.url);
        },
      }
    );
  };

  return (
    <>
      <Flex
        direction="column"
        bgColor="kgray.100"
        borderRadius="xl"
        gap="4"
        p="4"
      >
        <RadioGroup
          onChange={(price) => setSelectedPrice(price)}
          value={selectedPrice}
        >
          <VStack
            align="start"
            divider={<StackDivider borderColor="kgray.200" />}
            my="2"
            gap="2"
          >
            <Radio key="bonus" bgColor="white" value={bonusPrice} w="100%">
              <Flex alignItems="center" justifyContent="space-between" w="100%">
                <Text fontWeight="500">25 bonus</Text>
                <Flex
                  alignItems="center"
                  justifyContent="flex-end"
                  direction="column"
                  w="100px"
                >
                  <Text>FREE</Text>
                </Flex>
              </Flex>
            </Radio>
            {creditPrices.data?.map((price) => {
              const dollarAmount = price.amount / 100;
              return (
                <Radio
                  key={price.priceId}
                  bgColor="white"
                  value={price.priceId}
                  w="100%"
                >
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Text fontWeight="500">
                      {price.credits.toLocaleString()} credits
                    </Text>
                    <Flex
                      alignItems="center"
                      justifyContent="flex-end"
                      direction="column"
                      w="100px"
                    >
                      {' '}
                      <Text>${dollarAmount.toLocaleString()}</Text>
                      <Text fontSize="sm" color="kgray.300">
                        ${(dollarAmount / price.credits).toFixed(2)}
                        /credit
                      </Text>
                    </Flex>
                  </Flex>
                </Radio>
              );
            })}
          </VStack>
        </RadioGroup>
        <Button
          colorScheme="kbuttonblue"
          isDisabled={!selectedPrice}
          isLoading={createCheckoutSession.isLoading}
          onClick={handlePurchaseCredits}
        >
          Get it
        </Button>
        <Link
          alignSelf="center"
          color="kblue.300"
          fontSize="sm"
          href="https://docs.keyplay.io/en/articles/9269545-how-do-list-builder-credits-work"
          target="_blank"
        >
          how do credits work?
        </Link>
      </Flex>
    </>
  );
};
