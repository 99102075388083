import React, {useState} from 'react';
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Badge,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import {differenceInMilliseconds, format} from 'date-fns';
import {DetailedTaskState, GenericTask, TaskUpdate} from '../../shared/task';
import humanizeDuration from 'humanize-duration';
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {useDebounce} from 'usehooks-ts';
import {useCancelTask, useGetTasks} from './hooks';

const isCancelable = (task: GenericTask) => {
  return ['running', 'pending'].includes(task.state) && !task.pendingCancel;
};

const stateColor = (state?: string) => {
  switch (state) {
    case 'pending':
      return 'yellow';
    case 'running':
      return 'cyan';
    case 'done':
      return 'green';
    case 'failed':
      return 'red';
    case 'canceled':
      return 'orange';
  }
  return 'gray';
};

const TaskState = ({task}: {task: GenericTask}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const cancelRef = React.useRef(null);

  const cancelTask = useCancelTask();

  return (
    <>
      <Badge p={2} colorScheme={stateColor(task.state)}>
        {task.pendingCancel ? 'Pending Cancel' : task.state}
      </Badge>
      {task.state === 'failed' && (
        <Box mt={1}>
          <Popover>
            <PopoverTrigger>
              <Box as="b" _hover={{cursor: 'pointer'}}>
                View Error
              </Box>
            </PopoverTrigger>
            <PopoverContent width="100%">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Box me={6}>{task.error}</Box>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      )}
      {cancelTask.isLoading ? (
        <Box>Canceling task...</Box>
      ) : (
        <>
          {cancelTask.isError && <Box>Error canceling task.</Box>}
          {cancelTask.isSuccess && <Box>Successfully canceled task.</Box>}

          {isCancelable(task) && cancelTask.isIdle && (
            <>
              <Button mx={2} p={1} bg="red.200" onClick={onOpen}>
                Cancel
              </Button>
              <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
              >
                <AlertDialogOverlay />
                <AlertDialogContent>
                  <AlertDialogHeader>Cancel Task?</AlertDialogHeader>
                  <AlertDialogCloseButton />
                  <AlertDialogBody>
                    Are you sure you want to cancel the task? Note, the task may
                    continue running for a brief period of time before it is
                    stopped.
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      No
                    </Button>
                    <Button
                      colorScheme="red"
                      ml={3}
                      onClick={() => cancelTask.mutate({taskName: task.name})}
                    >
                      Yes
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </>
          )}
        </>
      )}
    </>
  );
};

const TaskInput = ({input}: {input: Record<string, unknown>}) => {
  if (!input) {
    return <Box>-</Box>;
  }

  const keys = Object.keys(input);
  if (!keys.length) {
    return <Box>-</Box>;
  }

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <Box as="b" _hover={{cursor: 'pointer'}}>
            Inputs ({keys.length})
          </Box>
        </PopoverTrigger>
        <PopoverContent width="100%">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Box as="pre" maxH="300px" overflowY="scroll">
              {JSON.stringify(input, null, 2)}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

const TaskTimestamp = ({task}: {task: GenericTask}) => {
  const lastUpdate = task.updates?.at(-1);

  let endTime = new Date();
  if (['canceled', 'done', 'failed'].includes(task.state) && lastUpdate) {
    endTime = lastUpdate.timestamp;
  }

  const duration = humanizeDuration(
    differenceInMilliseconds(endTime, task.timestamp),
    {
      round: true,
    }
  );

  return (
    <Flex direction="column">
      <Box>{format(task.timestamp, 'MM/d - h:mm:ss a')}</Box>
      <Box>
        Elapsed = <Box as="b">{duration}</Box>
      </Box>
    </Flex>
  );
};

const TaskUpdates = ({task}: {task: GenericTask}) => {
  const customer = (task.input?.customer as string) || '';
  const {isOpen, onOpen, onClose} = useDisclosure();
  const columnHelper = createColumnHelper<TaskUpdate>();

  // Note: columns are created directly instead of using the column helper to avoid
  // the 'Type instantiation is excessively deep and possibly infinite' error.
  const columns = [
    {
      accessorFn: (originalRow) => originalRow.timestamp,
      cell: (info) => (
        <Box fontSize={'sm'}>
          {format(new Date(info.getValue()), 'MM/d - h:mm:ss a')}
        </Box>
      ),
      header: 'Timestamp',
    } as ColumnDef<TaskUpdate, Date>,
    {
      accessorFn: (originalRow) => originalRow.state,
      cell: (info) => {
        const updateState = info.getValue();
        if (!updateState) {
          return '-';
        }

        return (
          <Badge p={2} colorScheme={stateColor(updateState)}>
            {updateState}
          </Badge>
        );
      },
      header: 'State',
    } as ColumnDef<TaskUpdate, TaskUpdate['state']>,
    {
      accessorFn: (originalRow) => originalRow.detailedState,
      cell: (info) => {
        const detailedState = info.getValue();
        if (!detailedState) {
          return '';
        }

        return (
          <Box as="pre" fontSize={'xs'} maxW={'200px'} overflow={'scroll'}>
            {JSON.stringify(detailedState, null, 2)}
          </Box>
        );
      },
      header: 'Detailed State',
    } as ColumnDef<TaskUpdate, DetailedTaskState>,
    {
      accessorFn: (originalRow) => originalRow.fetchStatus?.ready,
      cell: (info) => info.getValue(),
      header: 'Ready',
    } as ColumnDef<TaskUpdate, number>,
    columnHelper.group({
      id: 'errors',
      header: 'Errors',
      columns: [
        {
          accessorFn: (originalRow) => originalRow.fetchStatus?.fetchErrors,
          cell: (info) => info.getValue(),
          header: 'Fetch',
        } as ColumnDef<TaskUpdate, number>,
        {
          accessorFn: (originalRow) => originalRow.fetchStatus?.otherErrors,
          cell: (info) => info.getValue(),
          header: 'Other',
        } as ColumnDef<TaskUpdate, number>,
      ],
    }),
  ];
  const updates = task.updates || [];
  const table = useReactTable({
    data: updates,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Box as="b" onClick={onOpen} _hover={{cursor: 'pointer'}}>
        Updates ({updates.length})
      </Box>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Updates ({updates.length})</DrawerHeader>
          <DrawerBody>
            <Box>{customer}</Box>
            <Box>{task.type}</Box>
            {task.updates?.length === 0 && <div>No tasks found!</div>}
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody>
                  {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

// Note: columns are created directly instead of using the column helper to avoid
// the 'Type instantiation is excessively deep and possibly infinite' error.
const columns = [
  // By convention, customer name is often stored in the `customer` field of the input
  {
    accessorFn: (originalRow) => originalRow.debug?.customer?.name ?? '-',
    cell: (info) => info.getValue(),
    header: 'Customer',
  } as ColumnDef<GenericTask, string>,
  {
    accessorFn: (originalRow) => originalRow.type,
    cell: (info) => info.getValue(),
    header: 'Type',
  } as ColumnDef<GenericTask, string>,
  {
    accessorFn: (originalRow) => originalRow.input,
    cell: (info) => <TaskInput input={info.getValue()} />,
    header: 'Input',
  } as ColumnDef<GenericTask, Record<string, unknown>>,
  {
    accessorFn: (originalRow) => originalRow,
    cell: (info) => <TaskState task={info.getValue()} />,
    header: 'State',
  } as ColumnDef<GenericTask, GenericTask>,
  {
    accessorFn: (originalRow) => originalRow,
    cell: (info) => {
      // Display updates in reverse chronological order.
      const updates = [...(info.getValue().updates || [])].reverse();
      return (
        <TaskUpdates
          task={{
            ...info.getValue(),
            updates,
          }}
        />
      );
    },
    header: 'Updates',
  } as ColumnDef<GenericTask, GenericTask>,
  {
    accessorFn: (originalRow) => originalRow,
    cell: (info) => <TaskTimestamp task={info.getValue()} />,
    header: 'Start Time',
  } as ColumnDef<GenericTask, GenericTask>,
  {
    accessorFn: (originalRow) => originalRow.name,
    cell: (info) => <Box fontSize={12}>{info.getValue()}</Box>,
    header: 'Name',
  } as ColumnDef<GenericTask, string>,
];

const TaskList = React.memo(
  ({customerSearch}: {customerSearch: string}): JSX.Element => {
    const {data, error, isLoading} = useGetTasks({
      partialCustomerName: customerSearch,
    });

    const table = useReactTable({
      data: data ?? [],
      columns,
      getCoreRowModel: getCoreRowModel(),
    });

    if (isLoading) {
      return (
        <Center m={4}>
          <Spinner />
        </Center>
      );
    }

    if (error) {
      return (
        <Alert status="error">
          <AlertIcon />
          Something went wrong!
        </Alert>
      );
    }

    return (
      <Box position="absolute" inset="0" overflow="hidden">
        <TableContainer borderTop="1px solid #E8E8EB" h="100%" overflowY="auto">
          <Table
            variant="striped"
            style={{borderCollapse: 'separate', borderSpacing: 0}}
          >
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      bgColor="white"
                      borderBottom="1px solid #E8E8EB"
                      key={header.id}
                      position="sticky"
                      top="0"
                      zIndex="1"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
          {data?.length === 0 && <Alert status="info">No tasks found!</Alert>}
        </TableContainer>
      </Box>
    );
  }
);
TaskList.displayName = 'TaskList';

const Tasks = () => {
  const [partialCustomerName, setPartialCustomerName] = useState('');
  const debouncedPartialCustomerName = useDebounce(partialCustomerName, 250);

  return (
    <Flex direction="column" fontSize="14" h="100%">
      <Box maxW={300} m={4}>
        <Input
          value={partialCustomerName}
          onChange={(e) => setPartialCustomerName(e.target.value)}
          placeholder="Search for customers..."
        />
      </Box>
      <Box flexGrow="1" position="relative">
        <TaskList customerSearch={debouncedPartialCustomerName} />
      </Box>
    </Flex>
  );
};

export default Tasks;
