import React, {useMemo} from 'react';
import {
  Box,
  Button,
  Flex,
  Link,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {FieldDefinitionTypeLabels} from '../../shared/enrichment';
import {useCustomer, useMarkets} from '../../hooks/api/metadata';
import {pluralize} from '../../lib/helpers';
import {SimpleSignal} from '../signals';
import {assertNever} from '../../shared/util';
import {ScoringSignalToLabel} from '../../shared/signals';
import {AccountFieldLabels, isAccountField} from '../../shared/fields';
import {IbmWatsonStudio, OverflowMenuVertical} from '@carbon/icons-react';
import {getSignalsInMarket} from '../../shared/market';
import {Link as ReactRouterLink} from 'react-router-dom';
import {useIsKeyplayAdmin} from '../../hooks/api/user';
import {CreateSignalModal} from './CreateSignalModal';
import {
  SignalDefinition,
  SignalEvalFnInput,
  getSignalEvalFnInputs,
} from '../../shared/signalDefinition';
import {DeleteSignalModal} from './DeleteSignalModal';
import _ from 'lodash';

export const CustomSignals = () => {
  const customer = useCustomer();
  const signalDefinitions = (customer.signalDefinitions ?? []).sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  const isKeyplayAdmin = useIsKeyplayAdmin();
  const createSignalModal = useDisclosure();

  return (
    <Flex direction="column" gap="4">
      <Flex justifyContent="space-between">
        <Box>
          <Text fontWeight="500">Custom Signals</Text>
          <Text textColor="kgray.300" fontSize="sm">
            {pluralize(signalDefinitions.length, 'signal', 'signals')}
          </Text>
        </Box>
        {isKeyplayAdmin && (
          <Button
            colorScheme="kbuttonblue"
            fontWeight="normal"
            onClick={createSignalModal.onOpen}
          >
            Create Signal
          </Button>
        )}
      </Flex>
      <CustomSignalsList signalDefinitions={signalDefinitions} />
      <CreateSignalModal
        isOpen={createSignalModal.isOpen}
        onClose={createSignalModal.onClose}
      />
    </Flex>
  );
};

const CustomSignalMenu = ({
  signalDefinition,
}: {
  signalDefinition: SignalDefinition;
}) => {
  const deleteSignalModal = useDisclosure();

  return (
    <Menu>
      <MenuButton>
        <OverflowMenuVertical />
      </MenuButton>
      <MenuList>
        <MenuItem onClick={deleteSignalModal.onOpen} textColor="kred.500">
          Delete Signal
          <DeleteSignalModal
            signalDefinition={signalDefinition}
            isOpen={deleteSignalModal.isOpen}
            onClose={deleteSignalModal.onClose}
          />
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const columnHelper = createColumnHelper<SignalDefinition>();
const useColumns = () => {
  const fieldDefinitions = useCustomer().fieldDefinitions ?? [];
  const markets = useMarkets();
  const isKeyplayAdmin = useIsKeyplayAdmin();

  const formatSignalEvalInput = (input: SignalEvalFnInput) => {
    const {type} = input;
    switch (type) {
      case 'field':
        if (isAccountField(input.field)) {
          return `${AccountFieldLabels[input.field]} (Keyplay Field)`;
        } else {
          const field = fieldDefinitions.find((f) => f.id.equals(input.field));
          return field
            ? `${field.label} (${FieldDefinitionTypeLabels[field.type]})`
            : 'Unknown Field';
        }

      case 'signal':
        return `${ScoringSignalToLabel[input.signal]} (Keyplay Signal)`;

      default:
        assertNever(type);
    }
  };

  return [
    columnHelper.accessor('id', {
      header: 'Signal Name',
      size: 0,
      cell: (info) => {
        return (
          <SimpleSignal
            fontSize="sm"
            signal={info.getValue()}
            w="min-content"
          />
        );
      },
    }),
    columnHelper.accessor('evalFn', {
      header: 'Input',
      size: 400,
      cell: (info) => {
        let signalInput;
        let viewMoreInputs;

        const evalFnInputs = getSignalEvalFnInputs(info.getValue());
        if (evalFnInputs.length === 1) {
          signalInput = formatSignalEvalInput(evalFnInputs[0]);
        } else {
          signalInput = `${evalFnInputs.length} inputs`;
          viewMoreInputs = (
            <Popover trigger="hover">
              <PopoverTrigger>
                <Box cursor="help">
                  <IbmWatsonStudio />
                </Box>
              </PopoverTrigger>
              <PopoverContent p="2">
                <PopoverArrow />
                <PopoverBody>
                  <List spacing="2">
                    {evalFnInputs.map((input, index) => (
                      <ListItem
                        key={index}
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {formatSignalEvalInput(input)}
                      </ListItem>
                    ))}
                  </List>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          );
        }

        return (
          <Flex gap="2" alignItems="center" textColor="kgray.300">
            {signalInput}
            {viewMoreInputs}
          </Flex>
        );
      },
    }),
    columnHelper.accessor((data) => data.id, {
      id: 'usedIn',
      header: 'Used In',
      cell: (info) => {
        const marketsUsedIn = markets.filter((market) =>
          getSignalsInMarket(market).includes(info.getValue())
        );
        return (
          <Flex gap="2" justifyContent="center" textColor="kgray.300" w="80%">
            {marketsUsedIn.length ? (
              <Popover trigger="hover" size="sm">
                <PopoverTrigger>
                  <Box cursor="help" textColor="kblue.300">
                    {pluralize(marketsUsedIn.length, 'market', 'markets')}
                  </Box>
                </PopoverTrigger>
                <PopoverContent p="2">
                  <PopoverArrow />
                  <PopoverBody>
                    <List spacing="2">
                      {marketsUsedIn.map(({id, label}) => (
                        <ListItem
                          key={id.toString()}
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          <Link
                            as={ReactRouterLink}
                            to={`/markets/${id}`}
                            textColor="kblue.200"
                            _hover={{textDecoration: 'underline'}}
                          >
                            {label}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            ) : (
              '-'
            )}
          </Flex>
        );
      },
    }),
    columnHelper.accessor('timestamp', {
      header: 'Created',
      cell: (info) => {
        return (
          <Box textColor="kgray.300">
            {info.getValue().toLocaleDateString()}
          </Box>
        );
      },
    }),
    ...(isKeyplayAdmin
      ? [
          columnHelper.accessor((data) => data, {
            id: 'actions',
            header: '',
            size: 50,
            cell: (info) => (
              <CustomSignalMenu signalDefinition={info.getValue()} />
            ),
          }),
        ]
      : []),
  ];
};

const CustomSignalsList = ({
  signalDefinitions,
}: {
  signalDefinitions: SignalDefinition[];
}) => {
  const sortedSignals = useMemo(
    () => _.orderBy(signalDefinitions, ['groupId', 'label'], ['desc', 'asc']),
    [signalDefinitions]
  );

  const columns = useColumns();
  const table = useReactTable({
    columns,
    data: sortedSignals,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 0,
      size: 150,
    },
  });

  if (!sortedSignals.length) {
    return (
      <Flex direction="column" align="center" p={8}>
        <Text textColor="kgray.300">No custom signals defined.</Text>
      </Flex>
    );
  }

  let lastGroupId: string | undefined = undefined;

  return (
    <TableContainer border="1px" borderColor="kgray.200" borderRadius="md">
      <Table variant="simple">
        <Thead bg="kgray.100">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  w={header.getSize() ? `${header.getSize()}px` : 'auto'}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            const groupId = row.original.groupId?.toString();

            const isNewGroup = groupId !== lastGroupId;
            lastGroupId = groupId;

            return (
              <Tr
                key={row.id}
                role="group"
                borderTop={isNewGroup ? '2px solid' : '1px solid'}
                borderTopColor={isNewGroup ? 'kgray.300' : 'kgray.200'}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    _groupHover={{bg: 'kgray.100'}}
                    w={
                      cell.getContext().column.getSize()
                        ? `${cell.getContext().column.getSize()}px`
                        : 'auto'
                    }
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
