import React from 'react';
import {
  useToast,
  Spinner,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  SimpleGrid,
  GridItem,
  Tooltip,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Image,
  Button,
  Divider,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalBody,
  ModalHeader,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
  Code,
  GridItemProps,
  Link,
} from '@chakra-ui/react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import {User} from 'auth0';
import {format, formatDistanceToNow} from 'date-fns';
import {Navigate, useNavigate, useParams} from 'react-router';
import {useKeyplayApi} from '../../context/ApiContext';
import {WithId} from '../../shared/util';
import {Link as ReactRouterLink} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {
  Customer,
  PlanLabels,
  getFeatures,
  getLimit,
} from '../../shared/customer';
import {CreateOrEditCustomerButton} from './CreateOrEditCustomerButton';
import {TrashCan} from '@carbon/icons-react';
import {UpdateCreditsModal} from './UpdateCreditsModal';
import {
  CustomerQueryKeys,
  useAddUserMutation,
  useCustomerDetails,
  useRemoveUserMutation,
} from './hooks/customer';
import {ObjectId} from 'bson';
import {useUser} from '../../context/UserContext';
import {useAuth0} from '@auth0/auth0-react';
import {ApiAudience} from '../../shared/auth';

function RemoveUserCellComponent({
  userId,
  userEmail,
  customer,
}: {
  userId: string;
  userEmail: string;
  customer: WithId<Customer>;
}) {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Button colorScheme="red" onClick={onOpen} variant="outline">
        <TrashCan />
      </Button>
      <RemoveUserModal
        userId={userId}
        userEmail={userEmail}
        customer={customer}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

const GridItemLabel = ({
  children,
  ...props
}: React.PropsWithChildren<GridItemProps>) => (
  <GridItem fontWeight="500" textAlign="end" {...props}>
    {children}
  </GridItem>
);

export const CustomerDetails = () => {
  const user = useUser();
  const {getAccessTokenWithPopup} = useAuth0();
  const customerId = new ObjectId(useParams().customerId!);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const updateCreditsModal = useDisclosure();
  const toast = useToast();
  const addUserToCustomer = useAddUserMutation();

  const customerDetails = useCustomerDetails(customerId);
  if (customerDetails.isLoading) {
    return <Spinner />;
  }

  const customer = customerDetails.data?.customer;
  if (!customer) {
    toast({
      title: 'Unable to load customer',
      status: 'error',
    });

    return <Navigate to="/admin/provisioning" replace />;
  }

  const totalUsers = customerDetails.data?.users?.length ?? 0;
  const usersLimit = getLimit(customer, 'users');

  let plan = PlanLabels[customer.plan.type];
  if (customer.plan.type === 'listBuilder' && customer.plan.trial) {
    plan += ' (Trial)';
  }

  return (
    <Flex direction="column">
      <Breadcrumb textColor="kgray.300" mb={5}>
        <BreadcrumbItem>
          <BreadcrumbLink as={ReactRouterLink} to="/admin/provisioning">
            Customers
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink isCurrentPage>{customer.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box>
        <Flex justifyContent="space-between" mb={8}>
          <Flex alignItems="center">
            <Box fontSize="2xl" mr={2} mb={2}>
              {customer.name}
            </Box>
            <Box textColor="kgray.300" verticalAlign="center">
              ({customer._id.toString()})
            </Box>
          </Flex>
          <CreateOrEditCustomerButton customer={customer} />
        </Flex>
        <SimpleGrid templateColumns="max-content auto" spacing={2}>
          <GridItemLabel>Plan</GridItemLabel>
          <GridItem>{plan}</GridItem>
          <GridItemLabel>Onboarded</GridItemLabel>
          <GridItem>{customer.onboarded ? 'Yes' : 'No'}</GridItem>
          <GridItemLabel>Options</GridItemLabel>
          <GridItem>
            <Code>{JSON.stringify(customer.options ?? {}, null, 2)}</Code>
          </GridItem>
          <GridItemLabel>Features</GridItemLabel>
          <GridItem>
            <Code>{JSON.stringify(getFeatures(customer), null, 2)}</Code>
          </GridItem>
          <GridItemLabel>Toggles</GridItemLabel>
          <GridItem>
            <Code>{JSON.stringify(customer.toggles, null, 2)}</Code>
          </GridItem>
          <GridItemLabel>Saves Used/Quota</GridItemLabel>
          <GridItem>
            {customer.limits?.saves?.value?.toLocaleString() ?? '-'}
            {' / '}
            {getLimit(customer, 'saves')?.toLocaleString() ?? '-'}
          </GridItem>
          <GridItemLabel>Saves per Month Used/Quota</GridItemLabel>
          <GridItem>
            {customer.limits?.savesPerMonth?.value?.toLocaleString() ?? '-'}
            {' / '}
            {getLimit(customer, 'savesPerMonth')?.toLocaleString() ?? '-'}
          </GridItem>
          <GridItemLabel>Recommended Account Limit</GridItemLabel>
          <GridItem>
            {getLimit(customer, 'recommendedAccounts').toLocaleString()}
          </GridItem>
          <GridItemLabel>Active Accounts</GridItemLabel>
          <GridItem>
            {getLimit(customer, 'activeAccounts').toLocaleString()}
          </GridItem>
          <GridItemLabel>Markets</GridItemLabel>
          <GridItem>{getLimit(customer, 'markets')}</GridItem>
          <GridItemLabel>Credits</GridItemLabel>
          <GridItem>
            <Flex gap="2">
              {customer.credits.toLocaleString()}
              <Link color="kblue.300" onClick={updateCreditsModal.onOpen}>
                Update Credits
              </Link>
              <UpdateCreditsModal
                currentCredits={customer.credits}
                customerId={customer._id}
                isOpen={updateCreditsModal.isOpen}
                onClose={updateCreditsModal.onClose}
              />
            </Flex>
          </GridItem>
          <GridItemLabel>Optional Scoring Signals</GridItemLabel>
          <GridItem>
            <Code>{JSON.stringify(customer.signals, null, 2)}</Code>
          </GridItem>
          {(customer.integrations.hubspot?.crmSyncDay ||
            customer.integrations.salesforce?.crmSyncDay) && (
            <>
              <GridItemLabel>CRM Sync Day</GridItemLabel>
              <GridItem>
                <Code>
                  {customer.integrations.hubspot?.crmSyncDay ??
                    customer.integrations.salesforce?.crmSyncDay}
                </Code>
              </GridItem>
            </>
          )}
        </SimpleGrid>
      </Box>
      <Divider my={8} />
      <AddUserModal customer={customer} isOpen={isOpen} onClose={onClose} />
      <Box>
        <Flex alignItems="center" gap={2} mb={4}>
          <Box flexGrow={1} fontSize="xl">
            Members{' '}
            <Box as="span" color="kgray.400" fontSize="md">
              ({totalUsers} / {usersLimit})
            </Box>
          </Box>
          <Button
            colorScheme="red"
            fontWeight="normal"
            isLoading={addUserToCustomer.isLoading}
            onClick={() => {
              addUserToCustomer.mutate(
                {
                  email: user.auth0.email!,
                  customerId: customer._id,
                  isTemporary: true,
                },
                {
                  onSuccess: async () => {
                    await getAccessTokenWithPopup({
                      audience: ApiAudience,
                      customerId: customer._id,
                    });
                    window.location.reload();
                  },
                }
              );
            }}
            variant="ghost"
          >
            Login as Temp User
          </Button>
          <Button
            colorScheme="kbuttonblue"
            isDisabled={totalUsers >= usersLimit}
            onClick={onOpen}
            variant="solid"
          >
            Add User
          </Button>
        </Flex>
        {customerDetails.data?.users &&
          (customerDetails.data.users.length === 0 ? (
            <Box>
              <Flex alignItems="center" justifyContent="center" mt={4} mb={4}>
                This Customer has no members
              </Flex>
              <Flex alignItems="center" justifyContent="center" mt={4} mb={4}>
                <DeleteCustomerButton
                  customerId={customer._id}
                  customerName={customer.name}
                />
              </Flex>
            </Box>
          ) : (
            <MembersList
              users={customerDetails.data.users}
              customer={customer}
            />
          ))}
      </Box>
    </Flex>
  );
};

const membersColumnHelper = createColumnHelper<User>();
const MembersList = ({
  users,
  customer,
}: {
  users: User[];
  customer: WithId<Customer>;
}) => {
  const columns = [
    membersColumnHelper.accessor('picture', {
      header: () => null,
      cell: (info) => (
        <Image borderRadius="full" src={info.getValue()} w={10} h={10} />
      ),
    }),
    membersColumnHelper.accessor(
      (acc): {name?: string; email?: string} => {
        return {name: acc.name, email: acc.email};
      },
      {
        header: 'Name',
        cell: (info) => (
          <Box>
            <Box>{info.getValue().name ?? info.getValue().email}</Box>
            <Box textColor="kgray.300">{info.getValue().email}</Box>
          </Box>
        ),
      }
    ),
    membersColumnHelper.accessor('logins_count', {
      header: 'Logins',
      cell: (info) => info.getValue() ?? 0,
    }),
    membersColumnHelper.accessor('last_login', {
      header: 'Last Login',
      cell: (info) => {
        const value = info.getValue();
        return (
          <>
            {value ? (
              <Tooltip label={format(new Date(value), 'PPpp')}>
                <Box>
                  {formatDistanceToNow(new Date(value), {
                    addSuffix: true,
                  })}
                </Box>
              </Tooltip>
            ) : (
              'never'
            )}
          </>
        );
      },
    }),
    membersColumnHelper.accessor(
      (acc): {id?: string; email?: string} => {
        return {id: acc.user_id, email: acc.email};
      },
      {
        id: 'Remove User',
        header: () => null,
        cell: (info) => (
          <RemoveUserCellComponent
            userId={info.getValue().id ?? ''}
            userEmail={info.getValue().email ?? ''}
            customer={customer}
          />
        ),
      }
    ),
  ];

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} py={4}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

const AddUserModal = ({
  customer,
  isOpen,
  onClose,
}: {
  customer: WithId<Customer>;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {
    formState: {errors},
    getValues,
    handleSubmit,
    register,
    reset,
  } = useForm<{email: string}>();
  const toast = useToast();
  const addUserToCustomer = useAddUserMutation();

  const onHandleSubmit = async () => {
    const userEmail = getValues().email;
    addUserToCustomer.mutate(
      {email: userEmail, customerId: customer._id},
      {
        onSuccess: () => {
          toast({
            title: 'User added!',
            // The Auth0 search API is eventually consistent, so we might not be able to show the new user right away.
            description:
              'If the new user does not show in the members list, try refreshing the page.',
            status: 'success',
            position: 'bottom',
          });

          reset();
          onClose();
        },
      }
    );
  };

  return (
    <Modal
      isCentered={true}
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
      size="md"
    >
      <ModalOverlay />
      <ModalContent py={4} px={2}>
        <ModalHeader textAlign="center" fontSize="xl" fontWeight="normal">
          Add User to{' '}
          <Box as="span" fontWeight="bold">
            {customer.name}
          </Box>
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" gap={4}>
          <Box>Enter the email address of the user to invite.</Box>
          <Alert status="warning" mb={4}>
            <AlertIcon />
            Only new users will be sent an email.
          </Alert>
          <form onSubmit={handleSubmit(onHandleSubmit)}>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                autoComplete="off"
                type="email"
                {...register('email', {required: true})}
              />
              <FormErrorMessage>
                {errors.email && 'Email is required'}
              </FormErrorMessage>
            </FormControl>
            <Button
              colorScheme="kbuttonblue"
              disabled={addUserToCustomer.isLoading}
              isLoading={addUserToCustomer.isLoading}
              mt={4}
              type="submit"
              variant="solid"
              w="full"
            >
              Invite
            </Button>
          </form>
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

const RemoveUserModal = ({
  userId,
  userEmail,
  customer,
  isOpen,
  onClose,
}: {
  userId: string;
  userEmail: string;
  customer: WithId<Customer>;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const toast = useToast();
  const removeUserFromCustomer = useRemoveUserMutation();

  const onHandleSubmit = async () => {
    removeUserFromCustomer.mutate(
      {userId, customerId: customer._id},
      {
        onSuccess: () => {
          toast({
            title: 'User removed',
            // The Auth0 search API is eventually consistent, so we might not be able to display the removed user right away.
            description:
              'If the user does not disappear from the members list, try refreshing the page.',
            status: 'success',
            position: 'bottom',
          });

          onClose();
        },
      }
    );
  };

  return (
    <Modal
      isCentered={true}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size="md"
    >
      <ModalOverlay />
      <ModalContent py={4} px={2}>
        <ModalHeader textAlign="center" fontSize="xl" fontWeight="normal">
          Remove user{' '}
          <Box as="span" fontWeight="bold">
            {userEmail}
          </Box>
          {' from '}
          <Box as="span" fontWeight="bold">
            {customer.name}
          </Box>
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" gap={4}>
          <Button
            colorScheme="kred"
            disabled={removeUserFromCustomer.isLoading}
            isLoading={removeUserFromCustomer.isLoading}
            mt={4}
            type="submit"
            variant="solid"
            w="full"
            onClick={onHandleSubmit}
          >
            Remove
          </Button>
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

function DeleteCustomerButton({
  customerId,
  customerName,
}: {
  customerId: ObjectId;
  customerName: string;
}) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  return (
    <>
      <Button colorScheme="kred" onClick={onOpen} variant="solid">
        Delete Customer
      </Button>
      <DeleteCustomerModal
        customerId={customerId}
        customerName={customerName}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

const DeleteCustomerModal = ({
  customerId,
  customerName,
  isOpen,
  onClose,
}: {
  customerId: ObjectId;
  customerName: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const makeApiCall = useKeyplayApi();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();

  const deleteCustomer = useMutation({
    mutationFn: ({customerId}: {customerId: ObjectId}) =>
      makeApiCall(
        '/debug/customer/deleteCustomer',
        {method: 'POST', data: {customerId}},
        {toastOnError: true}
      ),
    onSuccess: async () => {
      toast({
        title: 'Customer deleted',
        description:
          'If the customer does not disappear from the list, try refreshing the page.',
        status: 'success',
        position: 'bottom',
      });
      await queryClient.removeQueries(CustomerQueryKeys.list());
      navigate('/admin/provisioning', {replace: true});
      onClose();
    },
  });

  const onHandleSubmit = async () => {
    deleteCustomer.mutate({customerId: customerId});
  };

  return (
    <Modal
      isCentered={true}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size="md"
    >
      <ModalOverlay />
      <ModalContent py={4} px={2}>
        <ModalHeader textAlign="center" fontSize="xl" fontWeight="normal">
          Delete{' '}
          <Box as="span" fontWeight="bold">
            {customerName}
          </Box>
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" gap={4}>
          <Button
            colorScheme="kred"
            disabled={deleteCustomer.isLoading}
            isLoading={deleteCustomer.isLoading}
            mt={4}
            type="submit"
            variant="solid"
            w="full"
            onClick={onHandleSubmit}
          >
            Delete
          </Button>
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
