import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Image,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import React, {useState} from 'react';
import Select, {
  DropdownIndicatorProps,
  PropsValue,
  StylesConfig,
} from 'react-select';
import {useDebounce} from 'usehooks-ts';
import {useAccountTypeahead} from '../../../hooks/api/accountTypeahead';
import {useAccountSignals} from '../../../hooks/api/accountSignals';
import {ScoringSignal, isKeyplayScoringSignal} from '../../../shared/signals';
import {useScoringModelSignalStore} from './SignalAssignmentStore';
import {getAccountScore, getOverallFit} from '../../../shared/scoring';
import {useScoringSignalResolver} from '../../../hooks/scoringSignal';
import {FitRenderer} from '../../AccountGrid/AccountGrid.components';
import {findSignalGroup} from '../../../shared/signalGroups';
import {useCustomer, useIsFreemiumCustomer} from '../../../hooks/api/metadata';
import {useAccountSimilarityScore} from '../../../hooks/api/accountSimilarityScore';
import {useScoringModeState} from './ScoringMode.state';
import {ObjectId} from 'bson';
import {AccountWithLogo} from '../../../shared/scoredAccounts';
import {useMarketFromContext} from '../MarketProvider';
import {SearchDropdownIndicator} from '../../SearchDropdownIndicator';

// default signals -- all customer signals, except only pick the first
// signal from each group and only pick the first custom signal to prevent
// weird collisions
const useDefaultSignals = () => {
  const {getServerFormat} = useScoringModelSignalStore();
  const signalsWithWeights = getServerFormat();
  const {signalDefinitions} = useCustomer();

  const signals: ScoringSignal[] = [];
  const seenGroups = new Set<string>();
  let hasCustomSignal = false;

  for (const {signal} of signalsWithWeights) {
    if (isKeyplayScoringSignal(signal)) {
      const group = findSignalGroup({
        signal,
        signalDefinitions: signalDefinitions ?? [],
      });
      if (group) {
        if (seenGroups.has(group)) {
          continue;
        }
        seenGroups.add(group);
      }
    } else {
      if (hasCustomSignal) {
        continue;
      }
      hasCustomSignal = true;
    }

    signals.push(signal);
  }

  return signals;
};

const AccountScoreSection = (props: BoxProps) => {
  return (
    <Box
      overflowX="auto"
      borderRadius="8px"
      borderColor="kgray.200"
      borderWidth="1px"
      backgroundColor="kgray.100"
      py={2}
      px={4}
      {...props}
    />
  );
};

const AccountScoreSectionHeader = ({
  header,
  score,
  accountWithLogo,
  isLoading,
  ...rest
}: {
  header: string;
  score: number;
  accountWithLogo?: AccountWithLogo;
  isLoading: boolean;
} & FlexProps) => {
  return (
    <Flex alignItems="center" {...rest}>
      <Box textTransform="none">
        <Text fontSize="14" fontWeight="normal" color="black">
          {header}
        </Text>
      </Box>

      <Box flex="1" pl={2}>
        {accountWithLogo ? (
          <Tooltip
            label={`Most similar to ${accountWithLogo.name}`}
            key="accountWithLogo"
            aria-label="tooltip"
          >
            <Image
              textAlign="left"
              key={accountWithLogo.logoUrl}
              src={accountWithLogo.logoUrl}
              alt="icon"
              boxSize="24px"
              fallbackSrc="/accountFallback.png"
            />
          </Tooltip>
        ) : (
          <></>
        )}
      </Box>

      <Box textAlign="center" width="50px">
        <Skeleton isLoaded={!isLoading}>
          <FitRenderer value={score} />
        </Skeleton>
      </Box>
    </Flex>
  );
};

const defaultLookalike = {
  name: 'Hooli',
  logoUrl: '/hooli.webp',
} as AccountWithLogo;
const defaultSimilarityScore = 80;

const AccountScoreBreakdown = ({accountId}: {accountId?: ObjectId}) => {
  const {getServerFormat} = useScoringModelSignalStore();
  const scoringSignalResolver = useScoringSignalResolver();
  const signalsWithWeights = getServerFormat();
  const defaultAccountSignals = useDefaultSignals();
  const {clientState: scoringMode} = useScoringModeState();
  const {id: marketId} = useMarketFromContext();
  const {signalDefinitions} = useCustomer();

  const {data: accountSignals, isInitialLoading: accountSignalsLoading} =
    useAccountSignals(accountId);
  const {data: similarityData, isInitialLoading: similarityScoreLoading} =
    useAccountSimilarityScore({
      accountId: accountId,
      marketId,
    });

  const isLoading = !!(
    accountId &&
    (accountSignalsLoading || similarityScoreLoading)
  );

  const signals = (accountId ? accountSignals : defaultAccountSignals) ?? [];
  const similarityScore =
    (accountId ? similarityData?.bestScore : defaultSimilarityScore) ?? 0;
  const lookalike = accountId
    ? similarityData?.bestLookalike
    : defaultLookalike;

  const {scoreBreakdown, totalScore: signalScore} = getAccountScore({
    signalsWithWeights,
    signals,
    signalDefinitions: signalDefinitions ?? [],
  });

  const overallScore = getOverallFit({
    signalScore,
    similarityScore,
    scoringMode: scoringMode ?? {type: 'highest_value'},
  });

  const textStyle = {
    fontSize: '12',
    fontWeight: 'thin',
    color: 'kgray.300',
  };

  const thTdStyle = {
    py: 1,
    px: 0,
    borderBottom: 'none',
  };

  return (
    <Flex
      flex="1"
      mr={-6}
      overflowY="auto"
      display="flex"
      flexDirection="column"
    >
      <Box textAlign="left" fontSize="2xl" py={2}>
        Score Breakdown
      </Box>
      <Box overflowY="auto" sx={{scrollbarGutter: 'stable'}} pr={2}>
        <AccountScoreSection my={2}>
          <AccountScoreSectionHeader
            header="Overall Score"
            score={overallScore}
            isLoading={isLoading}
          />
        </AccountScoreSection>
        <AccountScoreSection mb={2}>
          <AccountScoreSectionHeader
            header="Similarity Score"
            score={similarityScore}
            isLoading={isLoading}
            accountWithLogo={lookalike}
          />
        </AccountScoreSection>
        <AccountScoreSection mt={2}>
          <AccountScoreSectionHeader
            header="Signal Score"
            score={signalScore}
            pb={scoreBreakdown.length > 0 ? 2 : 0}
            isLoading={isLoading}
          />
          <Table>
            <Tbody>
              {scoreBreakdown.map(({signals, weight}) => (
                <Tr key={signals[0]}>
                  <Td {...thTdStyle} pb={0}>
                    <Text {...textStyle}>
                      {signals
                        .map((signal) => scoringSignalResolver(signal)?.label)
                        .join(', ')}
                    </Text>
                  </Td>
                  <Td {...thTdStyle} pb={0} textAlign="right" width="50px">
                    <Text {...textStyle} textAlign="center">
                      {Math.round(weight * 10) / 10}
                    </Text>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </AccountScoreSection>
      </Box>
    </Flex>
  );
};

type SelectAccount = AccountWithLogo;

const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #EBE8EA',
    borderRadius: '10px',
    cursor: 'text',
    flexDirection: state.hasValue ? 'row' : 'row-reverse',
    padding: '0px',
    margin: '0px',
    fontSize: '16px',
    height: '58px',
    fontWeight: '300',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '16px',
    fontWeight: '300',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0, // remove padding around the list
  }),
  menuPortal: (base) => ({...base, zIndex: 9999}),
} satisfies StylesConfig<{value: AccountWithLogo}>;

const DropdownIndicator = ({
  selectProps,
}: DropdownIndicatorProps<{value: AccountWithLogo}>) =>
  selectProps.value ? <></> : <SearchDropdownIndicator />;

const defaultAccount = {
  name: 'Pied Piper',
  logoUrl: '/pied-piper.webp',
} as SelectAccount;

export const AccountScorePreview = () => {
  const [search, setSearch] = useState('');
  const [displayedAccount, setDisplayedAccount] = useState<
    PropsValue<{value: AccountWithLogo}> | undefined
  >({value: defaultAccount});
  const [selectedAccount, setSelectedAccount] =
    useState<SelectAccount>(defaultAccount);
  const debouncedSearch = useDebounce<string>(search, 200);
  const accountTypeaheadQuery = useAccountTypeahead(debouncedSearch);
  const isFreemium = useIsFreemiumCustomer();

  const typeaheadAccounts = accountTypeaheadQuery.data ?? [];

  const formatOptionLabel = (
    data: {value: SelectAccount},
    {context}: {context: string}
  ) => (
    <Box display="flex" alignItems="center" ml="1" mt="0">
      <Image
        src={data.value.logoUrl}
        alt="icon"
        boxSize="40px"
        mr="2"
        fallbackSrc="/accountFallback.png"
      />
      {context === 'value' ? (
        <Text fontWeight="500" fontSize="20px">
          {data.value.name}
        </Text>
      ) : (
        <Text fontWeight="thin" fontSize="14px">
          {data.value.name}
        </Text>
      )}
    </Box>
  );

  return (
    <Flex height="100%" gap="4" width="100%" direction="column">
      <Box>
        <Select
          menuPortalTarget={document.body}
          placeholder="Start typing to pick an account..."
          value={displayedAccount}
          noOptionsMessage={() => null}
          styles={customSelectStyles}
          components={{DropdownIndicator, IndicatorSeparator: null}}
          formatOptionLabel={formatOptionLabel}
          inputValue={search}
          onInputChange={(val: string) => setSearch(val)}
          isMulti={false}
          isClearable={true}
          isDisabled={isFreemium}
          hideSelectedOptions={true}
          controlShouldRenderValue={true}
          options={typeaheadAccounts.map((account) => ({
            value: account,
            label: account.domain,
          }))}
          onChange={(selected) => {
            setDisplayedAccount(selected);
            if (selected?.value) {
              setSelectedAccount(selected?.value);
            }
          }}
          onBlur={() => setDisplayedAccount({value: selectedAccount})}
        />
      </Box>
      <Box ml={-6} mr={-6} borderBottom="1px" borderColor="kgray.200" />
      <AccountScoreBreakdown accountId={selectedAccount._id} />
    </Flex>
  );
};
