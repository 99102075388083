import React from 'react';
import {ModalHeader, Flex, Text} from '@chakra-ui/react';

export const AiFieldBuilderModalHeader = () => {
  return (
    <ModalHeader bgColor="kyellow.100" fontSize="sm" fontWeight="normal" py="3">
      <Flex alignItems="baseline" gap="2" justifyContent="center">
        <Text
          bgColor="kyellow.300"
          borderRadius="lg"
          fontWeight="normal"
          px="2"
          py="1"
        >
          Build Mode
        </Text>
        <Text>
          You won&apos;t be charged for previewing results until you run an
          enrichment.
        </Text>
      </Flex>
    </ModalHeader>
  );
};
