import React from 'react';
import {useKeyplayApi} from '../../context/ApiContext';
import {useQuery} from '@tanstack/react-query';
import {Center, Spinner, useToast} from '@chakra-ui/react';
import {Navigate, useNavigate, useSearchParams} from 'react-router-dom';
import {DownloadRequest, DownloadResponse} from '../../shared/api/api';

const Download = () => {
  const makeApiCall = useKeyplayApi();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const toast = useToast();
  const exportId = searchParams.get('exportId');

  const {isLoading} = useQuery<DownloadRequest, unknown, DownloadResponse>(
    ['download', exportId],
    () =>
      makeApiCall(
        '/download',
        {
          params: {
            exportId,
          },
        },
        {toastOnError: true}
      ),
    {
      enabled: !!exportId,
      // Only make the download request once.
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // This will trigger the download.
        window.location.replace(data.url);
        // What to do now since I don't think we can close the window...
        // Show a success toast and navigate to the Accounts page.
        toast({
          duration: 5_000,
          isClosable: true,
          title: 'Download complete',
          position: 'bottom-left',
        });
        // Replace the history entry so the user cannot navigate back to this page.
        navigate('/', {replace: true});
      },
      onError: () => {
        // There's nothing to do on the download page so redirect back to the Accounts page.
        navigate('/');
      },
    }
  );

  return (
    <Center width="100vw" height="100vh">
      {!exportId && <Navigate to="/" />}
      {isLoading && <Spinner />}
    </Center>
  );
};

export default Download;
