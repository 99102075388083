import React from 'react';
import {
  PrimaryBusinessCategory,
  SecondaryBusinessCategory,
  PrimaryBusinessCategoryLabels,
  SecondaryBusinessCategoryLabels,
} from '../../shared/account';
import {fromEntries} from '../../shared/util';
import {
  PillSelectSentenceFragment,
  GenericPillSelectSentenceFragmentOptionGroup,
} from './Definition.components';
import {FilterSentence} from './FilterSentence';
import {Box, Text} from '@chakra-ui/react';

const secondaryBusinessCategoriesToInclude = [
  'apparelAndFashion',
  'banking',
  'beautyAndPersonalCare',
  'consulting',
  'electronicsAndAppliances',
  'foodAndBeverage',
  'homeAndLifestyle',
  'hrAndRecruitingAgencies',
  'insurance',
  'itServices',
  'marketingAndAdAgencies',
  'petProducts',
  'professionalServices',
  'sportsAndFitness',
  'toysAndGames',
  'verticalSaas',
] as const satisfies readonly SecondaryBusinessCategory[];

export const CategoryFilterSentence = ({
  children,
  primaryCategories,
  secondaryCategories,
  setPrimaryCategories,
  setSecondaryCategories,
  type,
}: React.PropsWithChildren<{
  primaryCategories: PrimaryBusinessCategory[];
  secondaryCategories: SecondaryBusinessCategory[];
  setPrimaryCategories: (categories: PrimaryBusinessCategory[]) => void;
  setSecondaryCategories: (categories: SecondaryBusinessCategory[]) => void;
  type: 'inclusive' | 'exclusive';
}>) => {
  return (
    <FilterSentence>
      <Box mr={4}>
        that{' '}
        <Text as="span" borderBottom="1px dashed" mx="1">
          {type === 'inclusive' ? 'are' : 'are not'}
        </Text>{' '}
        in
      </Box>
      <PillSelectSentenceFragment
        groupLabelToOptionGroup={{
          'Primary Categories': {
            selected: primaryCategories,
            setSelected: setPrimaryCategories,
            options: PrimaryBusinessCategoryLabels,
          } satisfies GenericPillSelectSentenceFragmentOptionGroup<PrimaryBusinessCategory>,
          'Secondary Categories': {
            selected: secondaryCategories,
            setSelected: setSecondaryCategories,
            options: fromEntries(
              secondaryBusinessCategoriesToInclude.map((category) => [
                category,
                SecondaryBusinessCategoryLabels[category],
              ])
            ),
          } satisfies GenericPillSelectSentenceFragmentOptionGroup<SecondaryBusinessCategory>,
        }}
        promptText="select industry categories..."
      />
      {children}
    </FilterSentence>
  );
};
