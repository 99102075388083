import {useMutation} from '@tanstack/react-query';
import axios from 'axios';

interface UseUploadFileMutationProps {
  selectedFile: File | null;
  onSuccess: () => void;
  onError: (error: unknown) => void;
}

export const useUploadFileMutation = ({
  selectedFile,
  onSuccess,
  onError,
}: UseUploadFileMutationProps) => {
  return useMutation<void, unknown, {url: string}>({
    mutationFn: ({url}) => {
      if (!selectedFile) {
        throw new Error('No file selected');
      }

      const contentSize = selectedFile.size;
      return axios.put(url, selectedFile, {
        headers: {
          'Content-Type': 'text/csv',
          'x-goog-content-length-range': `${contentSize},${contentSize}`,
        },
      });
    },
    onSuccess,
    onError,
  });
};
