import {ObjectId} from 'bson';
import {StaticAccountData} from './account';
import {Tag} from './scoredAccounts';
import {WithId} from './util';
import {KeyplayScoringSignal} from './signals';

export type WithTags<T> = T & {
  tags: WithId<Tag>[];
};
export type WithStaticAccountData<T> = T & {
  staticAccountData?: StaticAccountData;
};
export type WithSimilarAccount<T> = T & {
  similarAccountLookup:
    | {
        _id: ObjectId;
        domain: string;
      }
    | undefined;
};

export type WithScoringDataSignals<T> = T & {
  scoringData: {
    signals: KeyplayScoringSignal[];
  };
};

export type WithAllLookups<T> = WithStaticAccountData<
  WithSimilarAccount<WithTags<T>>
>;

export const MaxClayExportSize = 5_000;
export const ClayExportRegex = /^https:\/\/api.clay.com/;
