import {AnalyticsBrowser, EventProperties} from '@segment/analytics-next';

export const analytics = new AnalyticsBrowser();

export function initAnalytics() {
  analytics.load({writeKey: 'Em4G07O4A0CuBrzHwA2HsU2R0sZ0Xbzd'});
}

type TrackProps = {
  properties?: EventProperties;
  user?: {
    email?: string;
  };
};

export function track(
  event: keyof typeof EventNames,
  {properties, user}: TrackProps = {}
) {
  analytics.track(EventNames[event], properties, {
    context: {
      // Additional fields required for HubSpot syncing:
      // 1. email is used to identify users, not IDs
      // 2. only UNIX timestamps seem to work, even though the API says it accepts ISO 8601
      email: user?.email,
      timestamp: new Date().getTime(),
    },
  });
}

export const EventNames = {
  accountDetailsNavigated: 'Account Details Navigated',
  accountDetailsOpened: 'Account Details Opened',
  accountsFiltered: 'Accounts Filtered',
  accountsSorted: 'Accounts Sorted',
  accountsViewColumnsUpdated: 'Accounts View Columns Updated',
  accountsViewModeUpdated: 'Accounts View Mode Updated',

  exportListCanceled: 'Export List Canceled',
  exportListClicked: 'Export List Clicked',

  listBuilderCreditsPurchaseCanceled: 'List Builder Credits Purchase Canceled',
  listBuilderCreditsPurchaseClicked: 'List Builder Credits Purchase Clicked',

  onboardingAdvanced: 'Onboarding Advanced',

  scoringModelReset: 'Scoring Model Reset',
};
