import React, {useCallback, useMemo} from 'react';
import Select from 'react-select';
import {Button, CloseButton, Flex} from '@chakra-ui/react';
import _ from 'lodash';

interface Option<Value> {
  label: string;
  value: Value;
}

interface MultiGroupSelectProps<Value> {
  addFilterText: string;
  allOptions: Option<Value>[];
  groupSeparator?: JSX.Element;
  isDisabled: boolean;
  maxGroups: number;
  noOptionsMessage: string;
  onChange: (values: Value[][]) => void;
  selectedOptions: Option<Value>[][];
}

function toValuesArray<V>(options: readonly Option<V>[]) {
  return options.map((o) => o.value);
}

// A component that displays multiple groups of Select components that allow
// for choosing from the same set of options. If an option is selected, it will
// not be available for choosing in any of the other Select components.
export const MultiGroupSelect = <U extends string>({
  addFilterText,
  allOptions,
  groupSeparator,
  isDisabled,
  maxGroups,
  noOptionsMessage,
  onChange,
  selectedOptions,
}: MultiGroupSelectProps<U>) => {
  if (selectedOptions.length > maxGroups) {
    // Shouldn't happen, but good to know if it does
    console.error(
      `Number of filter group selects exceeds the max of ${maxGroups}`
    );
  }

  // Coalesce each filter group before passing to the onChange callback.
  const onOptionGroupChanged = useCallback(
    (modifiedGroup: readonly Option<U>[], modifiedGroupIndex: number) => {
      const allUpdatedOptions = selectedOptions.map(toValuesArray);
      allUpdatedOptions[modifiedGroupIndex] = toValuesArray(modifiedGroup);
      onChange(allUpdatedOptions);
    },
    [onChange, selectedOptions]
  );

  // We only want to show options that are not already selected, regardless of
  // what Select component it is contained in. This works in a single Select
  // component since it supports not showing selected options without any
  // additional work on our end, but since we now have multiple, related Select
  // components, the other ones aren't aware of what's selected in any others.
  const allUnselectedOptions = useMemo(() => {
    const allSelectedOptions = _.flatten(selectedOptions);
    return _.differenceWith(
      allOptions,
      allSelectedOptions,
      (o1, o2) => o1.value === o2.value
    );
  }, [allOptions, selectedOptions]);

  return (
    <Flex direction={'column'} gap={4}>
      {selectedOptions.map((optionGroup, index) => {
        const showAddFilterButton =
          index === selectedOptions.length - 1 &&
          selectedOptions.length < maxGroups;
        const showGroupSeparator = groupSeparator && index > 0;

        return (
          <Flex direction={'column'} key={index} gap={2}>
            {showGroupSeparator && <>{groupSeparator}</>}
            <Flex gap={2}>
              <Select
                styles={{
                  container: (base) => ({
                    ...base,
                    flexGrow: 1,
                  }),
                }}
                noOptionsMessage={() => noOptionsMessage}
                options={allUnselectedOptions}
                isMulti
                onChange={(values) => {
                  onOptionGroupChanged(values, index);
                }}
                value={optionGroup}
                isDisabled={isDisabled}
              />
              {selectedOptions.length > 1 && (
                <CloseButton
                  onClick={() => {
                    const updatedOptions = [...selectedOptions];
                    updatedOptions.splice(index, 1);
                    onChange(updatedOptions.map(toValuesArray));
                  }}
                />
              )}
            </Flex>
            {showAddFilterButton && (
              <Button
                isDisabled={isDisabled}
                variant="ghost"
                size="sm"
                onClick={() => {
                  const updatedOptions = selectedOptions.map(toValuesArray);
                  updatedOptions.push([]);
                  onChange(updatedOptions);
                }}
              >
                {addFilterText}
              </Button>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};
