import React, {useMemo} from 'react';
import {Box, Button, Flex} from '@chakra-ui/react';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import {KeyplayScoringSignal} from '../../shared/signals';
import {
  AudienceSelector,
  EmployeeCountSelector,
  SimplePillSelectSentenceFragment,
} from './Definition.components';
import {useIsFreemiumCustomer} from '../../hooks/api/metadata';
import {ActiveCountryList} from '../../shared/countries';
import {Add} from '@carbon/icons-react';
import Select from 'react-select';
import {FilterSentence} from './FilterSentence';
import {CategoryFilterSentence} from './CategoryFilterSentence';
import {MoreFiltersPopover, MoreFiltersButton} from './MoreFiltersPopover';
import {
  LocationLabels,
  LocationType,
  useSamEditorStore,
} from './useSamEditorStore';
import {entries} from '../../shared/util';

const MaxAdvancedSignalFilterGroups = 3;

type LocationOption = {
  label: string;
  value: LocationType;
};

const locationOptions: LocationOption[] = entries(LocationLabels).map(
  ([value, label]) => ({value, label})
);

export const SamEditor = () => {
  const isFreemiumCustomer = useIsFreemiumCustomer();
  const {
    audience,
    setAudience,
    primaryCategories,
    setPrimaryCategories,
    secondaryCategories,
    setSecondaryCategories,
    excludedPrimaryCategories,
    setExcludedPrimaryCategories,
    excludedSecondaryCategories,
    setExcludedSecondaryCategories,
    minEmployees,
    setMinEmployees,
    maxEmployees,
    setMaxEmployees,
    locations,
    setLocations,
    locationsType,
    setLocationsType,
    stackFitSignals,
    setStackFitSignals,
    advancedSignalGroups,
    setAdvancedSignalGroups,
    signalOptions,
    stackFitOptions,
  } = useSamEditorStore();

  const [showExcludedCategories, setShowExcludedCategories] = useState(false);
  useEffect(() => {
    if (
      excludedPrimaryCategories?.length ||
      excludedSecondaryCategories?.length
    ) {
      setShowExcludedCategories(true);
    }
  }, [excludedPrimaryCategories, excludedSecondaryCategories]);

  const [showStackFitSignals, setShowStackFitSignals] = useState(false);
  useEffect(() => {
    if (stackFitSignals?.length) {
      setShowStackFitSignals(true);
    }
  }, [stackFitSignals]);

  // Compute the available options for a given filter group by excluding any signals already
  // selected for another filter group.
  const filteredOptions = useMemo(() => {
    if (!advancedSignalGroups) {
      return [];
    }

    return _.times(advancedSignalGroups.length, (idx) => {
      const allOtherSelectedSignals = [...advancedSignalGroups];
      allOtherSelectedSignals.splice(idx, 1);
      return _.omit(signalOptions, allOtherSelectedSignals.flat());
    });
  }, [advancedSignalGroups, signalOptions]);

  return (
    <Flex direction="column" fontSize={24} gap={4}>
      <FilterSentence>
        <Box mr={4}>We sell to</Box>
        <AudienceSelector audience={audience} setAudience={setAudience} />
        companies
      </FilterSentence>
      <CategoryFilterSentence
        primaryCategories={primaryCategories}
        secondaryCategories={secondaryCategories}
        setPrimaryCategories={setPrimaryCategories}
        setSecondaryCategories={setSecondaryCategories}
        type="inclusive"
      />
      {showExcludedCategories && (
        <CategoryFilterSentence
          primaryCategories={excludedPrimaryCategories ?? []}
          secondaryCategories={excludedSecondaryCategories ?? []}
          setPrimaryCategories={setExcludedPrimaryCategories}
          setSecondaryCategories={setExcludedSecondaryCategories}
          type="exclusive"
        >
          <Button
            onClick={() => {
              setShowExcludedCategories(false);
              setExcludedPrimaryCategories([]);
              setExcludedSecondaryCategories([]);
            }}
            size="sm"
            variant="link"
          >
            Remove exclusions
          </Button>
        </CategoryFilterSentence>
      )}
      {!showExcludedCategories && (
        <Flex>
          <Button
            colorScheme="kbuttonblue"
            fontWeight="normal"
            leftIcon={
              <>
                <Add />
              </>
            }
            onClick={() => {
              setShowExcludedCategories(true);
            }}
            size="sm"
            variant="link"
          >
            Add category exclusions
          </Button>
        </Flex>
      )}
      <FilterSentence>
        <Box mr={4}>who have</Box>
        <EmployeeCountSelector
          min={minEmployees}
          max={maxEmployees}
          setMin={setMinEmployees}
          setMax={setMaxEmployees}
        />
        employees
      </FilterSentence>
      <FilterSentence>
        <Box mr={4}>
          with
          <Select
            isSearchable={false}
            options={locationOptions}
            onChange={(newValue) => {
              if (newValue) {
                setLocationsType(newValue.value);
              }
            }}
            value={locationOptions.find((o) => o.value === locationsType)}
            styles={{
              container: (base) => ({
                ...base,
                display: 'inline-block',
                marginInline: '0.5em',
                minWidth: 100,
              }),
            }}
          />
          in
        </Box>
        <SimplePillSelectSentenceFragment
          selected={locations}
          setSelected={setLocations}
          options={ActiveCountryList}
          promptText="select locations..."
        />
      </FilterSentence>
      {!isFreemiumCustomer && (
        <>
          {showStackFitSignals && (
            <Flex>
              <FilterSentence>
                <Box mr={4}>and use any of</Box>
                <SimplePillSelectSentenceFragment
                  selected={stackFitSignals}
                  setSelected={setStackFitSignals}
                  options={
                    stackFitOptions as Record<KeyplayScoringSignal, string>
                  }
                  promptText="select technologies..."
                />
              </FilterSentence>
              <Button
                alignSelf="start"
                onClick={() => {
                  setShowStackFitSignals(false);
                  setStackFitSignals([]);
                }}
                mt={3}
                size="sm"
                variant="link"
              >
                Remove
              </Button>
            </Flex>
          )}
          {advancedSignalGroups.map((signalGroup, idx) => (
            <Flex key={idx}>
              <FilterSentence>
                <Box mr={4}>and match any of</Box>
                <SimplePillSelectSentenceFragment
                  selected={signalGroup}
                  setSelected={(selected) => {
                    const updatedSignals = [...advancedSignalGroups];
                    updatedSignals[idx] = selected;
                    setAdvancedSignalGroups(updatedSignals);
                  }}
                  options={
                    filteredOptions[idx] as Record<KeyplayScoringSignal, string>
                  }
                  promptText="select advanced signals..."
                />
              </FilterSentence>
              {advancedSignalGroups.length > 0 && (
                <Button
                  alignSelf="start"
                  onClick={() => {
                    const updatedSignals = [...advancedSignalGroups];
                    updatedSignals.splice(idx, 1);
                    setAdvancedSignalGroups(updatedSignals);
                  }}
                  mt={3}
                  size="sm"
                  variant="link"
                >
                  Remove
                </Button>
              )}
            </Flex>
          ))}
          <MoreFiltersPopover>
            {({onClose}) => (
              <>
                <MoreFiltersButton
                  isDisabled={showStackFitSignals}
                  variant="link"
                  onClick={() => {
                    setShowStackFitSignals(true);
                    onClose();
                  }}
                >
                  Tech Stack
                </MoreFiltersButton>
                <MoreFiltersButton
                  isDisabled={
                    (advancedSignalGroups?.length ?? 0) >=
                    MaxAdvancedSignalFilterGroups
                  }
                  variant="link"
                  onClick={() => {
                    const updatedSignals = [...advancedSignalGroups];
                    updatedSignals.push([]);
                    setAdvancedSignalGroups(updatedSignals);
                    onClose();
                  }}
                >
                  Advanced Signals
                </MoreFiltersButton>
              </>
            )}
          </MoreFiltersPopover>
        </>
      )}
    </Flex>
  );
};
