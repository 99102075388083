import React from 'react';
import {Customers} from './Provisioning.customers';
import {Flex} from '@chakra-ui/react';
import {Route, Routes} from 'react-router';
import {CustomerDetails} from './Provisioning.customerDetails';

export const Provisioning = () => {
  return (
    <Flex direction="column" m="0 auto" py={8} w="container.md">
      <Routes>
        <Route path=":customerId" element={<CustomerDetails />} />
        <Route path="*" element={<Customers />} />
      </Routes>
    </Flex>
  );
};
