import {Box, Flex, HStack, Image} from '@chakra-ui/react';
import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {useIsFreeOrListBuilderCustomer} from '../../hooks/api/metadata';
import {CreditUsageWithModal} from './CreditUsage';
import {AppMenu} from './AppMenu';

const NavItem = ({to, children}: {to: string; children: React.ReactNode}) => {
  const baseStyle = {
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#fff',
    fontWeight: 400,
    paddingX: 3,
    paddingY: 2,
    _hover: {
      bg: '#0A0A0A',
    },
  };

  const activeStyle = {
    ...baseStyle,
    bg: '#0A0A0A',
    fontWeight: 500,
  };

  return (
    <NavLink
      to={to}
      className={({isActive}) => (isActive ? 'activeNavLink' : undefined)}
    >
      <Flex
        direction="column"
        justify="space-around"
        {...baseStyle}
        sx={{'.activeNavLink &': activeStyle}}
      >
        {children}
      </Flex>
    </NavLink>
  );
};

export const Header = ({token}: {token: string}) => {
  const isFreeOrListBuilderCustomer = useIsFreeOrListBuilderCustomer();

  return (
    <Box position="fixed" top={0} left={0} right={0} height="50px" zIndex={5}>
      <Flex
        bg="kgray.400"
        color="white"
        align="center"
        h="50"
        gap={2}
        px={4}
        justifyContent="space-between"
      >
        <Box as={Link} flex="1" to="/discover/recommended">
          <Image
            display={{base: 'block', md: 'none'}}
            height="30px"
            src="/Keyplay_Primary_Icon.svg"
          />
          <Image
            display={{base: 'none', md: 'block'}}
            height="30px"
            src="/Keyplay_Primary_Logo_WhiteText.svg"
          />
        </Box>
        <HStack flexShrink="0">
          <NavItem to="/discover">Discover</NavItem>
          <NavItem to="/accounts">My Accounts</NavItem>
        </HStack>
        <Flex flex="1" justifyContent="end" gap="6">
          {isFreeOrListBuilderCustomer && <CreditUsageWithModal />}
          <AppMenu token={token} />
        </Flex>
      </Flex>
    </Box>
  );
};
