import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import {useQueryClient, useMutation} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {ObjectId} from 'bson';

interface DeleteApiKeyModalProps {
  isOpen: boolean;
  onClose: () => void;
  apiKeyIdToDelete?: ObjectId;
}

export const DeleteApiKeyModal = ({
  isOpen,
  onClose,
  apiKeyIdToDelete,
}: DeleteApiKeyModalProps) => {
  const makeApiCall = useKeyplayApi();
  const queryClient = useQueryClient();
  const toast = useToast();
  const deleteApiKey = useMutation<void, unknown, ObjectId>({
    mutationFn: (keyId: ObjectId) => {
      return makeApiCall(
        `/apiKeys/${keyId}`,
        {
          method: 'DELETE',
        },
        {toastOnError: true}
      );
    },
    onSuccess: async () => {
      toast({
        status: 'warning',
        title: 'API key deleted.',
        duration: 2_000,
        isClosable: true,
      });
      await queryClient.invalidateQueries(['apiKeys']);
    },
  });

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent minW="300" p={4}>
        <ModalHeader textAlign="center" fontSize="xl">
          Are you sure? This action can&#39;t be undone.
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p={4}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Box p={1}>
            <Button
              key="delete"
              fontWeight="500"
              fontSize="14"
              colorScheme="red"
              onClick={() => {
                if (apiKeyIdToDelete) {
                  deleteApiKey.mutate(apiKeyIdToDelete);
                }
                onClose();
              }}
            >
              Delete
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
