import React from 'react';
import {Flex, FlexProps} from '@chakra-ui/react';

export type GuidanceBarMode = 'default' | 'success' | 'warning';
export type GuidanceBarProps = FlexProps & {
  mode: GuidanceBarMode;
};

export const GuidanceBar = ({
  children,
  mode,
  ...props
}: React.PropsWithChildren<GuidanceBarProps>) => {
  const bgColor =
    mode === 'default' ? '' : mode === 'success' ? 'blue.100' : 'orange.100';

  return (
    <Flex
      alignItems="center"
      bgColor={bgColor}
      justifyContent="center"
      px={4}
      py={2}
      {...props}
    >
      {children}
    </Flex>
  );
};
