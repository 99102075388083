import React from 'react';
import {Navigate, useSearchParams} from 'react-router-dom';

/*
 * So, we can't navigate to an auth0 authenticated page with both code and state
 * parameters - that interferes with auth0's own login logic. So instead we need
 * to have a callback page that rewrites the search parameters and then redirects
 * to a page within our app.
 */
const OAuthCallback = () => {
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);

  const encodedParams = btoa(JSON.stringify(params));
  return (
    <Navigate to={`/settings/integrations/callback?params=${encodedParams}`} />
  );
};

export default OAuthCallback;
