import React, {Fragment} from 'react';
import {useUser} from '../../context/UserContext';
import {
  CaretDown,
  Code,
  Copy,
  DirectionStraightRight,
  Launch,
  List,
  Logout,
  Loop,
  Reset,
  SettingsAdjust,
  Share,
  Store,
  User,
  UserMultiple,
} from '@carbon/icons-react';
import {
  Alert,
  Box,
  Button,
  Center,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useClipboard,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {lightFormat} from 'date-fns';
import {Link} from 'react-router-dom';
import {useMembership} from '../../hooks/api/customer';
import {useLogout} from '../../hooks/api/logout';
import {
  useCustomer,
  useIsFreemiumCustomer,
  useIsListBuilderCustomer,
  useIsFreeOrListBuilderCustomer,
  useMarkets,
} from '../../hooks/api/metadata';
import {useScoringInfo, useRefreshAccounts} from '../../hooks/api/scoringInfo';
import {useIsKeyplayAdmin} from '../../hooks/api/user';
import {CustomerListWithLogin} from './CustomerSelector';

export const AppMenu = ({token}: {token: string}) => {
  const user = useUser();
  const isKeyplayAdmin = useIsKeyplayAdmin();
  const customer = useCustomer();
  const isFreemiumCustomer = useIsFreemiumCustomer();
  const isListBuilderCustomer = useIsListBuilderCustomer();
  const isFreeOrListBuilderCustomer = useIsFreeOrListBuilderCustomer();

  const toast = useToast();
  const {onCopy} = useClipboard(import.meta.env.DEV ? token : '');
  const {isOpen, onOpen, onClose} = useDisclosure();

  const markets = useMarkets();
  const {data: scoringInfo} = useScoringInfo();
  const logout = useLogout();

  const triggerRescore = useRefreshAccounts();

  const internalMenuOptions = [];
  if (isKeyplayAdmin) {
    internalMenuOptions.push([
      <MenuItem as={Link} icon={<Launch />} key="admin" to="/admin">
        Admin Tools
      </MenuItem>,

      <Fragment key="account-switcher">
        <MenuItem icon={<Loop />} onClick={onOpen}>
          Switch Accounts
        </MenuItem>
        <SwitchAccountModal isOpen={isOpen} onClose={onClose} />
      </Fragment>,
    ]);

    if (import.meta.env.DEV) {
      internalMenuOptions.push([
        <MenuItem
          key="token"
          as={Link}
          icon={<Copy />}
          onClick={() => {
            onCopy();
            toast({title: 'Token copied!', position: 'bottom-left'});
          }}
        >
          Copy Token
        </MenuItem>,
      ]);
    }
  }

  const internalMenu =
    internalMenuOptions.length > 0 ? (
      <MenuGroup title="Internal">
        {internalMenuOptions}
        <MenuDivider />
      </MenuGroup>
    ) : null;

  const allMenuItems = {
    accountScoring: (
      <MenuItem
        icon={
          <Box opacity={0.25}>
            <DirectionStraightRight />
          </Box>
        }
        as={Link}
        to={`/markets/${markets[0].id}`}
      >
        Account Scoring
      </MenuItem>
    ),
    api: (
      <MenuItem icon={<Code />} as={Link} to="/settings/api">
        API
      </MenuItem>
    ),
    customFields: (
      <MenuItem icon={<List />} as={Link} to="/settings/fields">
        Custom Fields
      </MenuItem>
    ),
    customSignals: (
      <MenuItem icon={<SettingsAdjust />} as={Link} to="/settings/signals">
        Custom Signals
      </MenuItem>
    ),
    divider: <MenuDivider />,
    integrations: (
      <MenuItem icon={<Share />} as={Link} to="/settings/integrations">
        Integrations
      </MenuItem>
    ),
    logout: (
      <MenuItem key="logout" icon={<Logout />} onClick={() => logout.mutate()}>
        Log Out
      </MenuItem>
    ),
    markets: (
      <MenuItem icon={<Store />} as={Link} to="/markets">
        Markets
      </MenuItem>
    ),
    refreshData: (
      <MenuItem
        alignItems="start"
        isDisabled={scoringInfo?.isRescoring}
        icon={
          <Box mt="1">
            <Reset />
          </Box>
        }
        onClick={() => triggerRescore.mutate()}
      >
        <Flex direction="column" gap="1">
          <Box>Refresh Data</Box>
          {scoringInfo?.latestRefreshTimestamp && (
            <Box>
              <Text fontSize="xs" color="kgray.300">
                Last updated:{' '}
                {lightFormat(scoringInfo.latestRefreshTimestamp, 'M/dd h:mm a')}
              </Text>
            </Box>
          )}
        </Flex>
      </MenuItem>
    ),
    serviceableMarket: (
      <MenuItem
        icon={
          <Box opacity={0.25}>
            <DirectionStraightRight />
          </Box>
        }
        as={Link}
        to={`/markets/${markets[0].id}/define`}
      >
        Serviceable Market
      </MenuItem>
    ),
    account: (
      <MenuItem icon={<User />} as={Link} to="/settings/account">
        Account
      </MenuItem>
    ),
    users: (
      <MenuItem icon={<UserMultiple />} as={Link} to="/settings/users">
        Users
      </MenuItem>
    ),
  };

  const menuItems = isFreemiumCustomer ? (
    <>
      {allMenuItems.markets}
      {allMenuItems.accountScoring}
      {allMenuItems.serviceableMarket}
      {allMenuItems.divider}
      <MenuGroup title="Settings">
        {allMenuItems.account}
        {allMenuItems.users}
      </MenuGroup>
      {allMenuItems.divider}
      {allMenuItems.logout}
    </>
  ) : (
    <>
      {!isListBuilderCustomer && (
        <>
          {allMenuItems.refreshData}
          {allMenuItems.divider}
        </>
      )}
      {allMenuItems.markets}
      {markets.length === 1 && (
        <>
          {allMenuItems.accountScoring}
          {allMenuItems.serviceableMarket}
        </>
      )}
      {allMenuItems.divider}
      <MenuGroup title="Settings">
        {allMenuItems.account}
        {allMenuItems.users}
        {allMenuItems.integrations}
        {allMenuItems.customFields}
        {allMenuItems.customSignals}
        {allMenuItems.api}
      </MenuGroup>
      {allMenuItems.divider}
      {allMenuItems.logout}
    </>
  );

  return (
    <Flex overflowX="hidden">
      <Menu>
        <MenuButton
          as={Button}
          variant="link"
          rightIcon={
            <Box>
              <CaretDown />
            </Box>
          }
          iconSpacing="2px"
          color="kgray.200"
          _active={{
            color: 'white',
          }}
          _hover={{
            textDecoration: 'none',
            color: 'white',
          }}
          fontWeight="500"
          flex="1"
        >
          <Flex alignItems="center">
            {!isFreeOrListBuilderCustomer && (
              <Text overflowX="hidden" textOverflow="ellipsis">
                {customer.name}
              </Text>
            )}
            <Image src={user.auth0.picture} h={8} borderRadius="25px" ml={3} />
          </Flex>
        </MenuButton>
        <MenuList color="#0A0A0A">
          {internalMenu}
          {menuItems}
        </MenuList>
      </Menu>
    </Flex>
  );
};

const SwitchAccountModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const membership = useMembership({enabled: isOpen});

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent py={4}>
        <ModalHeader textAlign="center" fontSize="xl">
          Switch Accounts
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" gap={4} px={20} py={0}>
          <Box textAlign="center">
            Select the account you would like to switch to.
          </Box>
          {membership.isLoading ? (
            <Center>
              <Spinner size="md" />
            </Center>
          ) : membership.isError ? (
            <Alert status="error">Something went wrong.</Alert>
          ) : (
            <CustomerListWithLogin
              customers={membership.data}
              onTokenUpdated={() => {
                onClose();
                location.reload();
              }}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
