import React from 'react';
import Tasks from '../Tasks';
import {Link, Navigate, Route, Routes, useMatch} from 'react-router-dom';
import {Box, Tab, TabList, Tabs} from '@chakra-ui/react';
import {TabbedPage} from '../TabbedPage';
import {Signals} from '../Debug/Signals';
import _ from 'lodash';
import {Provisioning} from '../Debug/Provisioning';

const Admin = () => {
  const isTasks = useMatch('/admin/tasks');
  const isProvisioning = useMatch('/admin/provisioning/*');
  const isSignals = useMatch('/admin/signals');

  return (
    <TabbedPage title="Admin">
      <Tabs
        variant="line"
        index={_.findIndex(
          [isTasks, isProvisioning, isSignals],
          (pathMatch) => !!pathMatch
        )}
        display="flex"
        flexDirection="column"
        flexGrow="1"
      >
        <TabList>
          <Tab as={Link} to="tasks">
            Tasks
          </Tab>
          <Tab as={Link} to="provisioning">
            Provisioning
          </Tab>
          <Tab as={Link} to="signals">
            Signals
          </Tab>
        </TabList>

        <Box flexGrow="1" position="relative">
          <Box position="absolute" inset="0" overflow="auto">
            <Routes>
              <Route path="/provisioning/*" element={<Provisioning />} />
              <Route path="/tasks" element={<Tasks />} />
              <Route path="/signals" element={<Signals />} />
              <Route path="*" element={<Navigate to="/admin/tasks" />} />
            </Routes>
          </Box>
        </Box>
      </Tabs>
    </TabbedPage>
  );
};

export default Admin;
