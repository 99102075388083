import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {UploadURLResponse} from '../../shared/api/api';
import {FileValidationError} from '../../lib/validateFile';

type Step = 'chooseFile' | 'validateFile' | 'uploadFile' | 'errors' | 'done';
interface ImportModalState {
  step: Step;
  selectedFile: File | null;
  remoteFileLocation: UploadURLResponse | null;
  fileValidationErrors: Set<FileValidationError> | null;

  setState: ({
    fileValidationErrors,
    remoteFileLocation,
    selectedFile,
    step,
  }: {
    fileValidationErrors?: Set<FileValidationError> | null;
    remoteFileLocation?: UploadURLResponse | null;
    selectedFile?: File | null;
    step?: Step;
  }) => void;

  reset: () => void;
}

export const useImportModalStore = create<ImportModalState>()(
  devtools<ImportModalState>((set, get) => ({
    step: 'chooseFile',
    selectedFile: null,
    remoteFileLocation: null,
    fileValidationErrors: null,

    setState: ({
      fileValidationErrors,
      remoteFileLocation,
      selectedFile,
      step,
    }) => {
      set({
        fileValidationErrors:
          fileValidationErrors === undefined
            ? get().fileValidationErrors
            : fileValidationErrors,
        remoteFileLocation:
          remoteFileLocation === undefined
            ? get().remoteFileLocation
            : remoteFileLocation,
        selectedFile:
          selectedFile === undefined ? get().selectedFile : selectedFile,
        step: step || get().step,
      });
    },

    reset: () => {
      set({
        step: 'chooseFile',
        selectedFile: null,
        remoteFileLocation: null,
        fileValidationErrors: null,
      });
    },
  }))
);
