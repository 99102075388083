import {AccountQuery, ImportSourceCountsResponse} from '../../shared/api/api';
import {useKeyplayApi} from '../../context/ApiContext';
import {useQuery} from '@tanstack/react-query';
import {useAccountView} from './AccountGrid';

interface UseImportSourceCountsProps {
  enabled: boolean;
  queryParams: AccountQuery | '';
}

// TODO: investigate unifying with useSignalCounts
export function useImportSourceCounts({
  queryParams,
  enabled,
}: UseImportSourceCountsProps) {
  const accountView = useAccountView();
  const makeApiCall = useKeyplayApi();
  return useQuery(
    ['importSourceCounts', accountView, JSON.stringify(queryParams)],
    () =>
      makeApiCall<ImportSourceCountsResponse>(
        `/importSourceCounts?query=${JSON.stringify(queryParams)}`
      ),
    {
      enabled,
      keepPreviousData: true,
      retry: 1,
    }
  );
}
