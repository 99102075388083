import React from 'react';
import {
  useToast,
  Flex,
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {useUpdateCreditsMutation} from './hooks/customer';
import {ObjectId} from 'bson';

export const UpdateCreditsModal = ({
  currentCredits,
  customerId,
  isOpen,
  onClose,
}: {
  currentCredits: number;
  customerId: ObjectId;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {
    formState: {errors},
    getValues,
    handleSubmit,
    register,
    reset,
  } = useForm<{amount: number; description: string}>();
  const toast = useToast();
  const updateCredits = useUpdateCreditsMutation(customerId);

  const onHandleSubmit = async () => {
    const amount = Number(getValues().amount);
    if (amount === currentCredits) {
      return;
    }

    const description = getValues().description;
    updateCredits.mutate(
      {amount, description},
      {
        onSuccess: () => {
          toast({
            title: 'Credits updated',
            status: 'success',
          });

          reset();
          onClose();
        },
      }
    );
  };

  return (
    <Modal
      isCentered={true}
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
      size="md"
    >
      <ModalOverlay />
      <ModalContent py={4} px={2}>
        <ModalHeader textAlign="center" fontSize="xl" fontWeight="normal">
          Add/Remove Credits
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" gap={4}>
          <form onSubmit={handleSubmit(onHandleSubmit)}>
            <Flex direction="column" gap={4}>
              <FormControl>
                <FormLabel>Current Credits</FormLabel>
                <Input type="number" isDisabled={true} value={currentCredits} />
              </FormControl>
              <FormControl isInvalid={!!errors.amount}>
                <FormLabel htmlFor="credits">Credits Adjustment</FormLabel>
                <Input
                  autoComplete="off"
                  type="number"
                  placeholder="Positive to add, negative to remove"
                  {...register('amount', {required: true})}
                />
                <FormErrorMessage>
                  {errors.amount && 'Credits is required'}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.description}>
                <FormLabel htmlFor="description">Description</FormLabel>
                <Input
                  type="text"
                  placeholder="Reason for adding/removing credits"
                  {...register('description', {required: true})}
                />
                <FormErrorMessage>
                  {errors.description && 'Description is required'}
                </FormErrorMessage>
              </FormControl>
            </Flex>
            <Button
              colorScheme="kbuttonblue"
              isLoading={updateCredits.isLoading}
              mt={4}
              type="submit"
              w="full"
            >
              Update
            </Button>
          </form>
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
