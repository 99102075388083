import React, {useEffect} from 'react';
import {Box, Flex, Spinner} from '@chakra-ui/react';
import {ValidateFileProps, validateFile} from '../../lib/validateFile';

export const FileValidator = <T extends object>({
  maxRows,
  onFileValidate,
  selectedFile,
  rowSchemaTypeguard,
}: ValidateFileProps<T>) => {
  useEffect(() => {
    validateFile({maxRows, onFileValidate, rowSchemaTypeguard, selectedFile});
  }, [selectedFile, maxRows, onFileValidate, rowSchemaTypeguard]);

  return (
    <Flex direction="column" alignItems="center" gap={8}>
      <Box mt={8}>
        Checking <em>{selectedFile?.name}</em>.
      </Box>
      <Spinner />
    </Flex>
  );
};
