import React, {useCallback} from 'react';
import {Flex, Link, Text, UseToastOptions, useToast} from '@chakra-ui/react';
import {Link as ReactRouterLink} from 'react-router-dom';
import {SavedAccountsContext} from './useSavedAccountsStore';

const toastId = 'saved-accounts-toast';
const defaultToastOptions: UseToastOptions = {
  duration: 5_000,
  isClosable: true,
  position: 'bottom-left',
  status: 'success',
};

export const useSavedAccountsToast = () => {
  const toast = useToast();

  return useCallback(
    ({accountName, action, numAccounts}: SavedAccountsContext) => {
      // Use the account name if only one account was saved
      const message =
        numAccounts === 1 && accountName
          ? accountName
          : `${numAccounts} accounts`;

      const description =
        action === 'save' ? (
          <Flex wrap="wrap">
            <Text me={2}>{message} saved to My Accounts</Text>
            <Link
              as={ReactRouterLink}
              onClick={() => {
                // Close the toast so it doesn't cover the saved accounts status bar
                toast.close(toastId);
              }}
              to="/accounts/saved"
              textDecoration="underline"
            >
              View all saved accounts
            </Link>
          </Flex>
        ) : (
          /* tag */
          <Flex wrap="wrap">
            <Text me={2}>{message} tagged</Text>
          </Flex>
        );

      if (toast.isActive(toastId)) {
        toast.update(toastId, {
          description,
          ...defaultToastOptions,
        });
        return;
      }

      toast({
        id: toastId,
        description,
        ...defaultToastOptions,
      });
    },
    [toast]
  );
};
